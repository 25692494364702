import React, { useState } from 'react';
import Customers from './customers/Customers';
import Vendors from './vendors/Vendors';
import useModulePermissions from '../hooks/useModulePermissions';
import TabComponent from '../components/TabComponent';

const CustomersMain = () => {
  const userData = JSON.parse(localStorage.getItem("loggedUser"));
  const permissions = useModulePermissions(userData);
  const [activeTab, setActiveTab] = useState(permissions.showCustomerMenu !== undefined ? '1' : '2');
  const tabData = [
    { permission: 'showCustomerMenu', id: '1', tabTitle: 'Clients', title: 'Clients' },
    { permission: 'showVendorMenu', id: '2', tabTitle: 'Suppliers', title: 'Suppliers' },
  ]

  return (
    <>
      <h3 className="font-bold text-[24px] text-[#2D3846] line-clamp-[20px] mb-5">Clients Overview</h3>
      <TabComponent 
        tabData={tabData} 
        activeTab={activeTab} 
        setActiveTab={setActiveTab} 
        permissions={permissions} 
      />
      
      {(activeTab === '1' && permissions.showCustomerMenu !== undefined) && <Customers />}
      {(activeTab === '2' && permissions.showVendorMenu !== undefined) && <Vendors />}
    </>
  )
}

export default CustomersMain;