import {
  endPoints,
  getAuthorizationHeader,
  axiosInstance,
} from "../components/api";

export const getVendorList = async () => {
  const { data: response } = await axiosInstance.get(`${endPoints.vendors}`, {
    headers: { Authorization: getAuthorizationHeader() },
  });
  return response;
};

export const addVendor = async (reqBody) => {
  const { data: response } = await axiosInstance.post(
    `${endPoints.vendors}`,
    reqBody,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};

export const editVendor = async (vendObj) => {
  const { data: response } = await axiosInstance.put(
    `${endPoints.vendors}/${vendObj.id}`,
    vendObj.body,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};

export const deleteVendor = async (vendId) => {
  const { data: response } = await axiosInstance.delete(
    `${endPoints.vendors}/${vendId}`,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};
