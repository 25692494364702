import React from 'react';
import ApprovalList from './ApprovalList';

export default function Departments() {
  return (
    <div className='bg-white p-8'>
        <ApprovalList />
    </div>
  )
}
