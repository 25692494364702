import { endPoints, getAuthorizationHeader, axiosInstance}  from "../components/api";

export const getApprovalList = async () => {
  const { data: response } = await axiosInstance.get(`${endPoints.approvals}`, {
    headers: { Authorization: getAuthorizationHeader() },
  });
  return response;
};

export const addApproval = async (reqBody) => {
  const { data: response } = await axiosInstance.post(
    `${endPoints.approvals}`,
    reqBody,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};

export const editApproval = async (approvObj) => {
  const { data: response } = await axiosInstance.put(
    `${endPoints.approvals}/${approvObj.id}`,
    approvObj.body,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};

export const deleteApproval = async (approvId) => {
  const { data: response } = await axiosInstance.delete(
    `${endPoints.approvals}/${approvId}`,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};
