import React, {useState, useEffect} from "react";
import closeIcon from "../../assets/image/blue-cross.svg";
import { Controller, useForm } from "react-hook-form";
import { BtnLoader } from "../../components/utils/Loader";
import { getIndentList } from "../../queries/Indent";
import { getVendorList } from "../../queries/Vendors";
import { getProjectList } from "../../queries/Projects";
import { useQuery } from "@tanstack/react-query";
import { Modal, Table, Select, InputNumber } from 'antd';
import DatePicker from "react-datepicker";
import AssignMaterial from "./AssignMaterial";
import moment from "moment";
import _ from 'lodash';
import toast from "react-hot-toast";
import useSelection from "../../hooks/useSelection";
import {VEHICLE_NO} from "../../components/utils/Regex";

export default function AddGrn(props) {
  const { data: indentData } = useQuery({
    queryKey: ["indent"],
    queryFn: getIndentList,
  });
//   const { data: vendorData } = useQuery({
//     queryKey: ["vendor"],
//     queryFn: getVendorList,
// });
  
  const {adGrn, isLoading, closeModel, isView, isEdit, grn, editingGrn} = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectingMat, setSelectingMat] = useState(isEdit || isView ? grn.materials : []);
  // const [indNumber, setIndNumber] = useState(isEdit || isView ? grn.indent_number : '');
  // const [vendor, setVendor] = useState(isEdit || isView ? vendorData?.result?.filter(v => v._id === grn.vendor_id)[0]?.name : '');
  const [projCode, setProjCode] = useState(isEdit || isView ? grn.project_code : '');
  // const [poDate, setPoDate] = useState(isEdit || isView ? moment(grn.po_date).format('DD-MM-YYYY') : '');
  const [showFilteredProj, setShowFilteredProj] = useState([]);
  const [recievedDate, setRecievedDate] = useState(new Date());
  // const [currentPO, setCurrentPO] = useState(isEdit || isView ? poData?.result?.filter(p => p.po_number === grn.po_number)[0] : {});
  const [curIndent, setCurIndent] = useState(
    isEdit || isView
      ? indentData?.result?.filter((i) => i._id === grn.indent_id)[0]
      : ""
  );
  const { data: projectData } = useQuery({
    queryKey: ["project"],
    queryFn: getProjectList,
  });

  const userData = JSON.parse(localStorage.getItem("loggedUser"));

  const closingModel = () => {
    closeModel();
  };
  
  const {
    register,
    reset,
    formState: { errors },
    control,
    clearErrors,
    handleSubmit,
    setValue,
  } = useForm(
    isEdit || isView ? {
      defaultValues: {
        indent_number: grn.indent_id,
        vendor_id: grn.vendor_id,
        project_id: grn.project_id,
        project_code: grn.project_code,
        po_date: moment(grn.po_date).format('DD-MM-YYYY'),
        recieved_date: moment(recievedDate).format('DD-MM-YYYY'),
        vehicle_number: grn.vehicle_number,
      }
    } : {
      defaultValues: {
        indent_number: '',
        project_code: '',
        recieved_date: '',
        vehicle_number: '',
      }
    }
  );

  

  

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '15%'
    },
    {
      title: 'Brand',
      dataIndex: 'brand',
      key: 'brand',
      width: '10%'
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      key: 'unit',
      width: '10%'
    },
    {
      title: 'Model',
      dataIndex: 'model',
      key: 'model',
      width: '10%'
    },
    {
      title: 'QTY',
      dataIndex: isEdit || isView ? 'quantity' : 'material_id',
      key: 'qty',
      render: (text, row) => (
        <InputNumber
              defaultValue={isEdit || isView ? text : '1'}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              onChange={(e) => {changeQty(row, e)}}
            />
        ),
      width: '15%'
    },
    {
      title: 'Remarks',
      dataIndex: isEdit || isView ? 'remarks' : 'material_id',
      key: 'remarks',
      render: (text, row) => (
        <input type="text" defaultValue={isEdit || isView ? text : ""} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" onChange={(e) => changeRemark(text, row, e)} />
        ),
      width: '15%'
    }
  ]
  

  const changeQty = (row, e) => {
    return row['quantity'] = Number(e);
  }
  const changeRemark = (rcd, row, e) => {
    return row['remarks'] = e.target.value; 
  }




  const showModal = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const addIndentMaterial = (selectRows) => {
    setSelectingMat(selectRows);
    setIsModalOpen(false);
  }

  const onSubmit = (data, e) => {
    e.preventDefault();
    if(isEdit){
      if(selectingMat.length > 0){
        const payload = {
          id: grn._id,
          body: {
            indent_number: curIndent.indent_number,
            indent_id: curIndent._id,
            project_id: curIndent.project_id,
            project_code: projCode,
            recieved_date: moment(recievedDate).format('DD-MM-YYYY'),
            vehicle_number: data.vehicle_number,
            materials: selectingMat,
            org_id: userData.org_id,
          }
        }
        editingGrn(payload);
      }else{
        toast.error('Please Assign Materials!!!');
      }
      
    }else{
      if(selectingMat.length > 0){
        const payload = {
          indent_number: curIndent.indent_number,
          indent_id: curIndent._id,
          project_id: curIndent.project_id,
          project_code: projCode,
          recieved_date: moment(recievedDate).format('DD-MM-YYYY'),
          vehicle_number: data.vehicle_number,
          materials: selectingMat,
          org_id: userData.org_id,
        }
        adGrn(payload);
      }else{
        toast.error('Please Assign Materials!!!');
      }
    }
  };


  // const handlePoChange = (e) => {
    
  //   const currentPO = poData?.result?.filter((p) => p._id === e)[0];
  //   let matList = [];
  //   if(currentPO && Object.keys(currentPO).length > 0) {
  //     const currentVendor = vendorData?.result?.filter((v) => v._id === currentPO.vendor_id)[0];
  //     setIndNumber(currentPO?.indent_number);
  //     setVendor(currentVendor?.name);
  //     setProjCode(currentPO?.project_code);
  //     setPoDate(moment(currentPO?.po_date).format('DD-MM-YYYY'));
  //     setCurrentPO(currentPO);
  //     console.log(indNumber, vendor, projCode, poDate);
  //   }
  //   if(currentPO?.materials.length > 0){
  //     currentPO?.materials.map((u) => {
  //       const temObj = {};
  //       temObj.material_id = u.material_id;
  //       temObj.name = u?.name;
  //       temObj.boq_no = u?.boq_no;
  //       temObj.description = u?.description;
  //       temObj.unit = u.unit;
  //       temObj.model = u.model;
  //       temObj.brand = u.brand;
  //       temObj.cost = u.cost;
  //       matList.push(temObj);
  //     });
  //     setShowFilteredProj(matList);
  //   }
  // }

  const changeIndent = (e) => {
    const currentIndent = indentData?.result?.filter((i) => i._id === e);
    setCurIndent(currentIndent[0]);
    let matList = [];
    //setProjId(currentIndent[0]?.project_id);
    setProjCode(
      projectData?.result?.filter(
        (p) => p._id === currentIndent[0]?.project_id
      )[0]?.project_code
    );
    //const filteredProjMat = currentIndent[0]?.materials;
    if (currentIndent[0]?.materials.length > 0) {
      currentIndent[0]?.materials.map((u) => {
        const temObj = {};
        temObj.material_id = u.material_id;
        temObj.name = u?.name;
        temObj.boq_no = u?.boq_no;
        temObj.description = u?.description;
        temObj.unit = u.unit;
        temObj.model = u.model;
        temObj.brand = u.brand;
        temObj.cost = u.cost;
        matList.push(temObj);
      });
      setShowFilteredProj(matList);
    }
  };

  const { options: indentOption } = useSelection(
    indentData,
    isEdit ? grn.indent_id : undefined
  );

  useEffect(() => {
    if (selectingMat.length > 0 && !isEdit) {
      selectingMat.map((s) => {
        changeQty(s, 1)
      });
      
      console.log('isnot edit')
    }
  }, [selectingMat]);

  return (
    <div className="bg-[#EBEDF2] p-12 fixed top-0 left-0 w-full h-full z-20">
      <p className="mb-8 text-right">
        <button onClick={closingModel}>
          <img src={closeIcon} alt="" />
        </button>
      </p>

      <div className="flex">
        <div className="w-[20%] text-left">
          <h3 className="text-[24px] text-[#2D3846]">Add New GRN</h3>
          <p className="text-[14px] text-[#656F7D]">
            Enter GRN details and add to list
          </p>
        </div>
        <div className="w-[80%]">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="bg-white p-5 rounded-md max-h-[70vh] overflow-y-auto">
              <h4 className="text-[20px] text-left border-b-2 border-b-[#007AC8] mb-8">
                GRN Details
              </h4>
              

              <div className="grid grid-cols-3 gap-8 text-left mb-8">
                <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      Indent Number
                    </label>

                    <Controller
                      {...register("indent_number", {
                        required: 'Select Indent Number',
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          allowClear
                          style={{
                            width: "100%",
                          }}
                          defaultValue={
                            isEdit || isView
                              ? indentData?.result?.filter(
                                  (v) => v._id === grn.indent_id
                                )[0]?.indent_number
                              : ""
                          }
                          placeholder="Please select"
                          onChange={(e) => {
                            changeIndent(e);
                            setValue("indent_number", e);
                            clearErrors("indent_number");
                          }}
                          options={
                            isEdit || isView
                              ? indentOption
                              : indentData?.result?.map((v) => ({
                                  label: v.indent_number,
                                  value: v._id,
                                }))
                          }
                        />
                      )}
                    />
                  </div>
                  {errors?.indent_number && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      {errors?.indent_number?.message}
                    </p>
                  )}
                </div>
                

                <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      Project Code
                    </label>
                    <input
                      className="text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                      type="text"
                      disabled
                      value={projCode}
                      {...register("project_code")}
                    />
                  </div>
                </div>



                <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      Recieved Date
                    </label>
                    <DatePicker
                      className="text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                      selected={recievedDate}
                      onChange={(date) => {
                        setRecievedDate(date);
                      }}
                    />
                  </div>
                  {!recievedDate && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      Please Select valid Recieved Date
                    </p>
                  )}
                </div>
                
                <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      Vehicle number (TN01AB1234)
                    </label>
                    <input
                      className="text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                      type="text"
                      {...register("vehicle_number", {
                        required: "Vehicle number Mandatory",
                        pattern: {
                          value: VEHICLE_NO,
                          message: "Enter valid Vehicle Number",
                        },
                      })}
                    />
                  </div>
                  {errors?.vehicle_number && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      {errors?.vehicle_number?.message}
                    </p>
                  )}
                </div>
                
              </div>
              <div className="border-b-2 border-b-[#007AC8] mb-8 pb-2 flex justify-between	">
                <h4 className="text-[20px] text-left">Assign Materials</h4>
                {showFilteredProj?.length > 0 && (
                  <button
                    onClick={showModal}
                    className="bg-transparent h-[32px] w-[150px] border border-[#007AC8] rounded text-[#007AC8] text-[12px] font-bold uppercase"
                  >
                    Add Material
                  </button>
                )}
              </div>
              {selectingMat.length > 0 && (
                <Table
                  pagination={{ pageSize: 6 }}
                  rowKey={(record) => record.material_id}
                  className="w-full text-left"
                  dataSource={selectingMat}
                  columns={columns}
                />
              )}

              <Modal
                title="Assign Material"
                open={isModalOpen}
                cancelButtonProps={{ style: { display: "none" } }}
                onOk={handleOk}
                onCancel={handleCancel}
                width={"80%"}
              >
                <AssignMaterial
                  open={isModalOpen}
                  materialList={showFilteredProj}
                  addIndentMaterial={addIndentMaterial}
                />
              </Modal>
            </div>
            <p className="text-right mt-8 flex items-center justify-end">
              <button
                onClick={closingModel}
                className="bg-transparent h-[40px] w-[100px] border text-[#007AC8] border-[#007AC8] rounded text-[14px] font-bold mr-3"
              >
                Cancel
              </button>
              <button
                type="Submit"
                className="bg-[#007AC8] h-[40px] w-[100px] border border-[#007AC8] rounded text-white text-[14px] font-bold uppercase"
                disabled={isLoading}
              >
                {isLoading ? <BtnLoader /> : isEdit ? "Update" : "Create"}
              </button>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
}
