import React from "react";
import closeIcon from "../../assets/image/blue-cross.svg";
import { useForm } from "react-hook-form";
import { BtnLoader } from "../../components/utils/Loader";

export default function AddUser(props) {
    const userName = JSON.parse(localStorage.getItem("loggedUser"));
    const { organization } = props;
    const closingModel = () => {
        props.closeModel();
    };
    
    const {
        register,
        reset,
        formState: { errors },
        handleSubmit,
    } = useForm({
        defaultValues: {
            name: organization?.name,
            address: organization?.address,
            city: organization?.city,
            state: organization?.state,
            country: organization?.country,
            zip: organization?.zip,
            phone: organization?.phone,
            email: organization?.email,
            poTermsAndConditions: organization?.poTermsAndConditions,
            org_id: userName.org_id,
        },
    });

    const onSubmit = (data) => {
        const tempObj = {
            id: organization._id,
            body: data,
        };
        props.editingOrganization(tempObj);
        props.closeModel();
        //reset();
    };

    return (
        <div className="bg-[#EBEDF2] p-12 fixed top-0 left-0 w-full h-full z-20">
            <p className="mb-8 text-right">
                <button onClick={closingModel}>
                    <img src={closeIcon} alt="" />
                </button>
            </p>

            <div className="flex">
                <div className="w-[40%] text-left">
                    <h3 className="text-[24px] text-[#2D3846]">Edit User</h3>
                    <p className="text-[14px] text-[#656F7D]">
                        Edit user details and update to list
                    </p>
                </div>
                <div className="w-[60%]">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="bg-white p-5 rounded-md max-h-full overflow-y-auto">
                            <h4 className="text-[20px] text-left border-b-2 border-b-[#007AC8] mb-8">
                                User Details
                            </h4>

                            <div className="grid grid-cols-2 gap-8 text-left">
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Organization Name
                                        </label>
                                        <input
                                            className="text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                                            type="text"
                                            {...register("name", {
                                                required:
                                                    "Organization Name is required",
                                            })}
                                        />
                                    </div>
                                    {errors.name && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors.name?.message}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Address
                                        </label>
                                        <input
                                            className="text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                                            type="text"
                                            {...register("address", {
                                                required:
                                                    "Address is required",
                                            })}
                                        />
                                    </div>
                                    {errors.address && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors.address?.message}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            City
                                        </label>
                                        <input
                                            className="text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                                            type="text"
                                            {...register("city", {
                                                required:
                                                    "City is required",
                                            })}
                                        />
                                    </div>
                                    {errors.city && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors.city?.message}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            State
                                        </label>
                                        <input
                                            className="text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                                            type="text"
                                            {...register("state", {
                                                required:
                                                    "State is required",
                                            })}
                                        />
                                    </div>
                                    {errors.state && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors.state?.message}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Zip
                                        </label>
                                        <input
                                            className="text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                                            type="text"
                                            {...register("zip", {
                                                required:
                                                    "Zip is required",
                                            })}
                                        />
                                    </div>
                                    {errors.zip && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors.zip?.message}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Country
                                        </label>
                                        <input
                                            className="text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                                            type="text"
                                            {...register("country", {
                                                required:
                                                    "State is required",
                                            })}
                                            readOnly
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Phone
                                        </label>
                                        <input
                                            className="text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                                            type="text"
                                            {...register("phone", {
                                                required:
                                                    "Phone is required",
                                            })}
                                        />
                                    </div>
                                    {errors.phone && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors.phone?.message}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Email
                                        </label>
                                        <input
                                            className="text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                                            type="text"
                                            {...register("email", {
                                                required:
                                                    "Email Address is required",
                                                validate: {
                                                    matchPattern: (v) =>
                                                        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                                                            v
                                                        ) ||
                                                        "Email address must be a valid address",
                                                },
                                            })}
                                        />
                                    </div>
                                    {errors.email && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors.email?.message}
                                        </p>
                                    )}
                                </div>

                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Terms and Conditions
                                        </label>
                                        <input
                                            className="text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                                            type="text"
                                            {...register("poTermsAndConditions", {
                                                required:
                                                    "Terms and Conditions is required",
                                            })}
                                        />
                                    </div>
                                    {errors.poTermsAndConditions && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors.poTermsAndConditions?.message}
                                        </p>
                                    )}
                                </div>
                                
                                
                            </div>
                        </div>
                        <p className="text-right mt-8 flex items-center justify-end">
                            <button
                                onClick={closingModel}
                                className="bg-transparent h-[40px] w-[100px] border text-[#007AC8] border-[#007AC8] rounded text-[14px] font-bold mr-3"
                            >
                                Cancel
                            </button>
                            <button
                                type="Submit"
                                className="bg-[#007AC8] h-[40px] w-[100px] border border-[#007AC8] rounded text-white text-[14px] font-bold uppercase"
                                disabled={props.isLoading}
                            >
                                {props.isLoading ? <BtnLoader /> : "Edit"}
                            </button>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    );
}
