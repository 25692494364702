import React, { Fragment, useState } from "react";
import infoIcon from "../../assets/image/blue-info.svg";
import whitePlus from "../../assets/image/white-plus.svg";
import { useQuery } from "@tanstack/react-query";
import {
    getCategoryList,
    addCategory,
    editCategory,
    deleteCategory,
} from "../../queries/Categories";
import AddCategory from "./AddCategory";
import { Loader } from "../../components/utils/Loader";
import { useQueryClient } from "@tanstack/react-query";
import { useQueryMutation } from "../../hooks/useMutation";
import { Table } from "antd";
import { useFilteredList } from "../../hooks/useFilteredList";
import { CategoryColumns } from "../../utils/colConfig";
import SearchList from "../../components/SearchList";
import useModulePermissions from '../../hooks/useModulePermissions';

const filterByName = (list, searchValue) => {
    const trimmedSearchVal = searchValue.trim().toLowerCase();
    return list.filter((item) => {
        return item.name.toLowerCase().includes(trimmedSearchVal);
    });
};

export default function CategoryList() {
    const userData = JSON.parse(localStorage.getItem("loggedUser"));
    const permissions = useModulePermissions(userData);
    const queryClient = useQueryClient();
    const [showModel, setShowModel] = useState(false);
    const [isView, setIsView] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [categoryData, setCategoryData] = useState({});
    const { isLoading, data } = useQuery({
        queryKey: ["categories"],
        queryFn: getCategoryList,
    });
    const {
        filteredList: categoryList,
        searchVal,
        handleSubmit,
        handleChange,
    } = useFilteredList(data?.result, filterByName);

    const deleteCategoryList = useQueryMutation(deleteCategory, queryClient, 'categories');
    const editCategoryList = useQueryMutation(editCategory, queryClient, 'categories');
    const addCategoryList = useQueryMutation(addCategory, queryClient, 'categories');

    const closeModel = () => {
        setShowModel(false);
        setIsView(false);
        setIsEdit(false);
    };

    const deleCategory = (id) => {
        deleteCategoryList.mutate(id);
    };

    const edCategory = (obj) => {
        editCategoryList.mutate(obj);
    };

    const adCategory = (obj) => {
        addCategoryList.mutate(obj);
    };

    const settingIsEdit = (editVisible, category) => {
        setShowModel(true);
        setCategoryData(category);
        setIsEdit(editVisible);
    };

    const columns = CategoryColumns(deleCategory, edCategory, isLoading, settingIsEdit);

    if (isLoading || deleteCategoryList.isLoading) return <Loader />;

    return (
        <div>
            {data.result && data.result.length > 0 ? (
                <Fragment>
                    <div className="flex justify-between mb-5">
                        <SearchList
                            searchVal={searchVal}
                            handleChange={handleChange}
                            handleSubmit={handleSubmit}
                            placeHolder={"Category Name..."}
                        />
                        {permissions.showCategoryMenu !== undefined && permissions.showCategoryMenu.write && (
                            <button
                            onClick={() => setShowModel(true)}
                            className="primary btn flex items-center justify-center text-[#fff] text-[14px] font-bold"
                        >
                            <img src={whitePlus} alt="" />
                            <span className="ml-3">Add New Category</span>
                        </button>
                        )}
                        
                    </div>
                    <Table
                        pagination={{ pageSize: 6 }}
                        className="w-full text-left"
                        dataSource={categoryList}
                        columns={columns}
                        onRow={(record, rowIndex) => {
                            return {
                              onClick: () => {setCategoryData(record); setShowModel(true); setIsView(true)},
                            };
                        }}
                    />
                </Fragment>
            ) : (
                <div className="text-center">
                    <img className="mx-auto" src={infoIcon} alt="Info Icon" />
                    <h4 className="text-[20px] text-[#2D3846] font-bold">
                        No data available
                    </h4>
                    <p className="text-[14px] text-[#656F7D] mb-12">
                        You have not added/created any Category here
                    </p>
                    {permissions.showCategoryMenu !== undefined && permissions.showCategoryMenu.write && (
                        <p className="flex justify-center">
                        <button
                            onClick={() => setShowModel(true)}
                            className="primary btn flex items-center justify-center text-[#fff] text-[14px] font-bold"
                        >
                            <img src={whitePlus} alt="" />
                            <span className="ml-3">Add New Category</span>
                        </button>
                    </p>
                    )}
                    
                </div>
            )}

            {showModel && permissions.showCategoryMenu !== undefined && permissions.showCategoryMenu.write && (
                <AddCategory
                    closeModel={closeModel}
                    adCategory={adCategory}
                    isLoading={isLoading}
                    category={categoryData}
                    isView={isView}
                    isEdit={isEdit}
                    settingIsEdit={settingIsEdit}
                    edCategory={edCategory}
                />
            )}
        </div>
    );
}
