import React, {useState} from 'react';
import Projects from './projects/Projects';
import Schedules from './schedules/Schedules';
import useModulePermissions from '../hooks/useModulePermissions';
import TabComponent from '../components/TabComponent';

const ProjectsMain = () => {
  const userData = JSON.parse(localStorage.getItem("loggedUser"));
  const permissions = useModulePermissions(userData);
  const [activeTab, setActiveTab] = useState(permissions.showProjectMenu !== undefined ? '1' : '2');
  const tabData = [
    { permission: 'showProjectMenu', id: '1', tabTitle: 'Projects', title: 'Projects' },
    { permission: 'showScheduleMenu', id: '2', tabTitle: 'Schedules', title: 'Schedules' },
];

  return (
    <>
    <h3 className="font-bold text-[24px] text-[#2D3846] line-clamp-[20px] mb-5">Project Overview</h3>
    <TabComponent 
      tabData={tabData} 
      activeTab={activeTab} 
      setActiveTab={setActiveTab} 
      permissions={permissions} 
    />
    {activeTab === '1' && permissions.showProjectMenu !== undefined && <Projects />}
    {activeTab === '2' && permissions.showScheduleMenu !== undefined && <Schedules />}
    </>
  )
}

export default ProjectsMain;
