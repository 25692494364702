import React, { useEffect, useState } from "react";
import closeIcon from "../../assets/image/blue-cross.svg";
import { useForm } from "react-hook-form";
import { BtnLoader } from "../../components/utils/Loader";
import { useQuery } from "@tanstack/react-query";
import { TreeSelect } from "antd";
import { getRoleList } from "../../queries/Roles";

export default function AddRole(props) {
    const { isEdit, adRole, closeModel, isLoading, edRole, item } = props;
    const userName = JSON.parse(localStorage.getItem("loggedUser"));
    const [treeSelect, setTreeSelect] = useState([]);
    const [value, setValue] = useState(isEdit ? props?.item?.parentRole : null);

    const { data } = useQuery({
        queryKey: ["roles"],
        queryFn: getRoleList,
    });

    const closingModel = () => {
        closeModel();
    };
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm(
        isEdit
            ? {
                  defaultValues: {
                      name: item?.name,
                  },
              }
            : {
                  defaultValues: {
                      name: "",
                  },
              }
    );

    const onSubmit = (data) => {
        const addReqBody = {
            name: data.name,
            parentRole: value,
            org_id: userName.org_id,
        };
         let EditReqBody ={};
        if (isEdit) {
        EditReqBody = {
            id: item._id,
            body: {
                name: data.name,
                parentRole: value,
                org_id: userName.org_id,
            },
        };
          }
        isEdit ? edRole(EditReqBody) : adRole(addReqBody);
        closingModel();
    };

    const onChange = (value) => {
        console.log("onChange", value);
        setValue(value);
    };

    const onPopupScroll = (e) => {
        console.log("onPopupScroll", e);
    };

    const formatSelect = (data) => {
        let tree = {};
        let map = {};

        data.forEach((item) => {
            map[item._id] = item;
            item.children = [];
        });

        data.forEach((item) => {
            console.log(item);
            if (item.parentRole !== null && item.parentRole !== undefined) {
                const parent = map[item.parentRole];
                if (parent) {
                    let newItem = {
                        title: item.name,
                        value: item._id,
                        children: item.children ? item.children : [],
                    };
                    parent.children.push(newItem);
                }
            } else {
                let newItem = {
                    title: item.name,
                    value: item._id,
                    children: item.children ? item.children : [],
                };
                tree = newItem;
            }
        });

        return tree;
    };

    useEffect(() => {
        const formattedSelect = formatSelect(data?.result);
        setTreeSelect([formattedSelect]);
    }, [data]);

    return (
        <div className="bg-[#EBEDF2] p-12 fixed top-0 left-0 w-full h-full z-20">
            <p className="mb-8 text-right">
                <button onClick={closingModel}>
                    <img src={closeIcon} alt="" />
                </button>
            </p>

            <div className="flex">
                <div className="w-[40%] text-left">
                    <h3 className="text-[24px] text-[#2D3846]">
                        {isEdit ? "Edit Role" : "Add Role"}
                    </h3>
                    <p className="text-[14px] text-[#656F7D]">
                        {isEdit
                            ? "Update Role Name and add to list"
                            : "Enter Role Name and add to list"}
                    </p>
                </div>
                <div className="w-[60%]">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="bg-white p-5 rounded-md max-h-[70vh] overflow-y-auto">
                            <h4 className="text-[20px] text-left border-b-2 border-b-[#007AC8] mb-8">
                                Role List
                            </h4>

                            <div className="grid grid-cols-2 gap-8 text-left">
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Role Name
                                        </label>
                                        <input
                                            className="text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                                            type="text"
                                            {...register("name", {
                                                required:
                                                    "Please Add Role Name",
                                            })}
                                        />
                                    </div>
                                    {errors.name && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors.name?.message}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Assign To
                                        </label>
                                        <TreeSelect
                                            showSearch
                                            style={{
                                                width: "100%",
                                            }}
                                            value={value}
                                            dropdownStyle={{
                                                maxHeight: 400,
                                                overflow: "auto",
                                            }}
                                            placeholder="Please select"
                                            allowClear
                                            treeDefaultExpandAll
                                            onChange={onChange}
                                            treeData={treeSelect}
                                            onPopupScroll={onPopupScroll}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className="text-right mt-8 flex items-center justify-end">
                            <button
                                onClick={closingModel}
                                className="bg-transparent h-[40px] w-[100px] border text-[#007AC8] border-[#007AC8] rounded text-[14px] font-bold mr-3"
                            >
                                Cancel
                            </button>
                            <button
                                type="Submit"
                                className="bg-[#007AC8] h-[40px] w-[100px] border border-[#007AC8] rounded text-white text-[14px] font-bold uppercase"
                                disabled={isLoading}
                            >
                                {isLoading ? (
                                    <BtnLoader />
                                ) : isEdit ? (
                                    "Update"
                                ) : (
                                    "Create"
                                )}
                            </button>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    );
}
