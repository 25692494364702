import React, { useState } from "react";
import actionIcon from "../../assets/image/blue-action.svg";
import editIcon from "../../assets/image/edit-blue.svg";
import EditOrganization from './EditOrganization';
import { useClickAway } from "@uidotdev/usehooks";
import useModulePermissions from "../../hooks/useModulePermissions";

export default function OrganizationListTable(props) {
  const { organization } = props;
  const [showAction, setShowAction] = useState(false);
  const [showModel, setShowModel] = useState(false);

  const userData = JSON.parse(localStorage.getItem("loggedUser"));
    const permissions = useModulePermissions(userData);

  const ref = useClickAway(() => {
    setShowAction(false);
  });

  const closeModel = () => {
    setShowModel(false);
    setShowAction(false);
  }

  const editingOrganization = (obj) => {
    props.edOrganization(obj)
  }

  return (
    <>
    {permissions.showOrganizationMenu !== undefined && (permissions.showOrganizationMenu.update || permissions.showOrganizationMenu.delete) && (
      <span
      onClick={() => setShowAction(!showAction)}
      className="p-3 cursor-pointer flex justify-end"
    >
      <img src={actionIcon} alt="" />
    </span>
    )}
        

        {showAction && (
          <div className="absolute top-[10px] right-[30px] border border-solid border-[#D9D9D9] rounded z-10" ref={ref}>
            <ul className="w-[135px]">
              {permissions.showOrganizationMenu !== undefined && permissions.showOrganizationMenu.update && (
                <li className="text-[12px] cursor-pointer px-3 py-2 flex bg-white hover:bg-[#F5F5F5] rounded-t items-center border-b border-solid border-[#D9D9D9]" onClick={() => setShowModel(true)}>
                <span className="pr-2"><img src={editIcon} alt="" /></span>
                <span>Edit</span>
              </li>
              )}
            </ul>
          </div>
        )}
     
      {showModel && <EditOrganization closeModel={closeModel} organization={organization} editingOrganization={editingOrganization} isLoading={props.isLoading} />}
      </>
  );
}
