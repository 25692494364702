import React, { Fragment, useState } from "react";
import infoIcon from "../../assets/image/blue-info.svg";
import whitePlus from "../../assets/image/white-plus.svg";
import { useQuery } from "@tanstack/react-query";
import {
    getProjectList,
    addProject,
    editProject,
    deleteProject,
} from "../../queries/Projects";
import { assignMaterials } from "../../queries/Materials-to-Projects";
import AddProject from "./AddProject";
import { Loader } from "../../components/utils/Loader";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useQueryMutation } from "../../hooks/useMutation";
import toast from "react-hot-toast";
import { Table } from "antd";
import AssignMaterialToProject from "./AssignMaterialToProject";
import useModulePermissions from "../../hooks/useModulePermissions";
import { ProjectColumns } from "../../utils/colConfig";

export default function ProjectList() {
    const userData = JSON.parse(localStorage.getItem("loggedUser"));
    const permissions = useModulePermissions(userData);
    const queryClient = useQueryClient();
    const [showModel, setShowModel] = useState(false);
    const [isView, setIsView] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [projectData, setProjectData] = useState({});
    const [showAssignModel, setShowAssignModel] = useState(false);
    const { isLoading, data } = useQuery({
        queryKey: ["project"],
        queryFn: getProjectList,
    });

    const {
        mutate,
        isLoading: assignMatLoader,
    } = useMutation({
        mutationKey: ["assign-material"],
        mutationFn: assignMaterials,
    });

    const deleteProjectList = useQueryMutation(deleteProject, queryClient, "project");
    const addProjectList = useQueryMutation(addProject, queryClient, "project");
    const editProjectList = useQueryMutation(editProject, queryClient, "project");

    const closeModel = () => {
        setShowModel(false);
        setIsView(false);
        setIsEdit(false);
    };
    const closeAssignModel = () => {
        setShowAssignModel(false);
    };
    // const closeViewModel = () => {
    //     setShowViewModel(false);
    // };

    const deleProject = (id) => {
        deleteProjectList.mutate(id);
    };

    const edProject = (obj) => {
        editProjectList.mutate(obj);
    };

    const adProject = (obj) => {
        addProjectList.mutate(obj);
    };

    const assignMaterial = (obj) => {
        mutate(obj, {
            onSuccess: (data) => {
                console.log(data);
                if (data.status === "success") {
                    toast.success(data?.message);
                }
            },
        });
    };

    const showProgress = (val) => {
        return (
            <div className="w-full bg-[#E9ECF1] rounded-full">
                <div
                    className="bg-[#87DEB9] text-xs font-medium text-gray-600 justify-center leading-none rounded-full flex"
                    style={{ width: `${val}%` }}
                >
                    {" "}
                    {Math.round(val)}%
                </div>
            </div>
        );
    };

    const settingIsEdit = (editVisible, project) => {
        setShowModel(true);
        setProjectData(project);
        setIsEdit(editVisible);
    };

    const columns = ProjectColumns(deleProject, edProject, isLoading, showProgress, settingIsEdit);

    if (isLoading) return <Loader />;

    return (
        <div>
            {data.result && data.result.length > 0 ? (
                <Fragment>
                    {permissions.showProjectMenu !== undefined &&
                        permissions.showProjectMenu.write && (
                            <div className="flex justify-end mb-5">
                                <button
                                    onClick={() => setShowModel(true)}
                                    className="primary btn flex items-center justify-center text-[#fff] text-[14px] font-bold mr-3"
                                >
                                    <img src={whitePlus} alt="" />
                                    <span className="ml-3">
                                        Add New Project
                                    </span>
                                </button>
                                <button
                                    onClick={() => setShowAssignModel(true)}
                                    className="primary btn flex items-center justify-center text-[#fff] text-[14px] font-bold"
                                >
                                    <img src={whitePlus} alt="" />
                                    <span className="ml-3">Assign Material</span>
                                </button>
                            </div>
                        )}

                    <Table
                        pagination={{ pageSize: 6 }}
                        className="w-full text-left"
                        dataSource={data?.result}
                        onRow={(record, rowIndex) => {
                            return {
                              onClick: () => {setProjectData(record); setShowModel(true); setIsView(true)},
                            };
                        }}
                        columns={columns}
                    />
                </Fragment>
            ) : (
                <div className="text-center">
                    <img className="mx-auto" src={infoIcon} alt="Info Icon" />
                    <h4 className="text-[20px] text-[#2D3846] font-bold">
                        No data available
                    </h4>
                    <p className="text-[14px] text-[#656F7D] mb-12">
                        You have not added/created any Project here
                    </p>
                    {permissions.showProjectMenu !== undefined &&
                        permissions.showProjectMenu.write && (
                            <p className="flex justify-center">
                                <button
                                    onClick={() => setShowModel(true)}
                                    className="primary btn flex items-center justify-center text-[#fff] text-[14px] font-bold"
                                >
                                    <img src={whitePlus} alt="" />
                                    <span className="ml-3">
                                        Add New Project
                                    </span>
                                </button>
                            </p>
                        )}
                </div>
            )}

            {showModel &&
                permissions.showProjectMenu !== undefined &&
                permissions.showProjectMenu.write && (
                    <AddProject
                        closeModel={closeModel}
                        adProject={adProject}
                        isLoading={addProjectList.isLoading}
                        project={projectData}
                        isView={isView}
                        isEdit={isEdit}
                        settingIsEdit={settingIsEdit}
                        edProject={edProject}
                    />
                )}
            {showAssignModel &&
                permissions.showProjectMenu !== undefined &&
                permissions.showProjectMenu.write && (
                    <AssignMaterialToProject
                        assignMaterial={assignMaterial}
                        closeAssignModel={closeAssignModel}
                        isLoading={assignMatLoader}
                    />
                )}
            {/* {showViewModel && <ViewProject closeViewModel={closeViewModel} />} */}
        </div>
    );
}
