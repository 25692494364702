import React from 'react';
import RoleList from './RoleList';

export default function Role() {
  return (
    <div className='bg-white p-8'>
        <RoleList />
    </div>
  )
}
