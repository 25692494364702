import React from 'react';
import CategoryList from './CategoryList';

export default function Categories() {
  return (
    <div className='bg-white p-8'>
        <CategoryList />
    </div>
  )
}
