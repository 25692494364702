import React, { Fragment, useState } from "react";
import infoIcon from "../../assets/image/blue-info.svg";
import whitePlus from "../../assets/image/white-plus.svg";
import { useQuery } from "@tanstack/react-query";
import { deleteUser, getUserList, addUser, editUser } from "../../queries/User";
import AddUser from "./AddUser";
import { Loader } from "../../components/utils/Loader";
import { useQueryClient } from "@tanstack/react-query";
import { Table } from "antd";
import SearchList from "../../components/SearchList";
import { useFilteredList } from "../../hooks/useFilteredList";
import { UserColumns } from "../../utils/colConfig";
import {useQueryMutation} from '../../hooks/useMutation';
import useModulePermissions from "../../hooks/useModulePermissions";

const filterByName = (list, searchValue) => {
    return list.filter((item) => {
        const fullName = `${item.first_name}${item.last_name}`.toLowerCase();
        const reversedFullName =
            `${item.last_name}${item.first_name}`.toLowerCase();
        const trimmedSearchValue = searchValue
            .replace(/\s+/g, "")
            .toLowerCase();
        return (
            fullName.includes(trimmedSearchValue) ||
            reversedFullName.includes(trimmedSearchValue)
        );
    });
};
export default function UserList() {
    const userData = JSON.parse(localStorage.getItem("loggedUser"));
    const permissions = useModulePermissions(userData);
    const queryClient = useQueryClient();
    const [showModel, setShowModel] = useState(false);
    const [isView, setIsView] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [userListData, setuserListData] = useState({});

    const { isLoading, isError, data } = useQuery({
        queryKey: ["users"],
        queryFn: getUserList,
    });

    const {
        filteredList: usersList,
        searchVal,
        handleSubmit,
        handleChange,
    } = useFilteredList(data?.result, filterByName);

    const user = JSON.parse(localStorage.getItem("loggedUser"));

    const deleteUserList = useQueryMutation(deleteUser, queryClient, 'users');
    const addUserList = useQueryMutation(addUser, queryClient, 'users');
    const editUserList = useQueryMutation(editUser, queryClient, 'users');

    
    const closeModel = () => {
        setShowModel(false);
        setIsView(false);
        setIsEdit(false);
    };

    const deleUser = (id) => {
        deleteUserList.mutate(id);
    };

    const edUser = (obj) => {
        editUserList.mutate(obj);
    };

    const adUser = (obj) => {
        addUserList.mutate(obj);
    };

    const settingIsEdit = (editVisible, user) => {
        setShowModel(true);
        setuserListData(user);
        setIsEdit(editVisible);
    };

    const columns = UserColumns(deleUser, edUser, isLoading, settingIsEdit);

    if (isLoading) return <Loader />;

    if (isError)
        return (
            <p className="text-center my-20 font-bold">Something Went Wrong!</p>
        );

    return (
        <div>
            {data?.result?.length > 0 ? (
                <Fragment>
                    <div className="flex justify-between mb-5">
                        <SearchList
                            searchVal={searchVal}
                            handleChange={handleChange}
                            handleSubmit={handleSubmit}
                            placeHolder={"Search by Fullname..."}
                        />
                        {permissions.showUsersMenu !== undefined &&
                            permissions.showUsersMenu.write && (
                                <button
                            onClick={() => setShowModel(true)}
                            className="primary btn flex items-center justify-center text-[#fff] text-[14px] font-bold"
                        >
                            <img src={whitePlus} alt="" />
                            <span className="ml-3">Add New User</span>
                        </button>
                            )}
                        
                    </div>
                    <Table
                        pagination={{ pageSize: 6 }}
                        className="w-full text-left"
                        dataSource={usersList?.filter(
                            (u) => u.email !== user?.email
                        )}
                        onRow={(record, rowIndex) => {
                            return {
                              onClick: () => {setuserListData(record); setShowModel(true); setIsView(true)},
                            };
                        }}
                        columns={columns}
                    />
                </Fragment>
            ) : (
                <div className="text-center">
                    <img className="mx-auto" src={infoIcon} alt="Info Icon" />
                    <h4 className="text-[20px] text-[#2D3846] font-bold">
                        No data available
                    </h4>
                    <p className="text-[14px] text-[#656F7D] mb-12">
                        You have not added/created any users here
                    </p>
                    {permissions.showUsersMenu !== undefined &&
                            permissions.showUsersMenu.write && (
                                <p className="flex justify-center">
                        <button
                            onClick={() => setShowModel(true)}
                            className="primary btn flex items-center justify-center text-[#fff] text-[14px] font-bold"
                        >
                            <img src={whitePlus} alt="" />
                            <span className="ml-3">Add New User</span>
                        </button>
                    </p>
                            )}
                    
                </div>
            )}

            {showModel && (
                <AddUser
                    closeModel={closeModel}
                    adUser={adUser}
                    isLoading={isLoading}
                    user={userListData}
                    isView={isView}
                    isEdit={isEdit}
                    settingIsEdit={settingIsEdit}
                    edUser={edUser}
                />
            )}
        </div>
    );
}
