import React from "react";
import closeIcon from "../../assets/image/blue-cross.svg";
import { useForm } from "react-hook-form";
import { BtnLoader } from "../../components/utils/Loader";
import { getCategoryList } from '../../queries/Categories';
import { useQuery } from "@tanstack/react-query";
import {
    EMAIL_ONLY,
    PHONE_NO_ONLY,
    NUMBERS_ONLY,
} from "../../components/utils/Regex";

export default function AddVendor(props) {
  const userData = JSON.parse(localStorage.getItem("loggedUser"));
    const {
        vendor,
        isLoading,
        editingVendr,
        isEdit,
        isView,
        closeModel,
        adVendor,
    } = props;
    const closingModel = () => {
        closeModel();
    };
      const { data } = useQuery({
    queryKey: ["categories"],
    queryFn: getCategoryList,
  });
    const {
        register,
        reset,
        formState: { errors },
        handleSubmit,
    } = useForm(
        isEdit || isView
            ? {
                  defaultValues: {
                      name: vendor.name,
                      type: vendor.type,
                      contact_person: vendor.contact_person,
                      address: vendor.address,
                      gst: vendor.gst,
                      mobile: vendor.mobile,
                      phone: vendor.phone,
                      fax: vendor.fax,
                      email: vendor.email,
                      alternate_email: vendor.alternate_email,
                      status: vendor.status,
                  },
              }
            : {
                  defaultValues: {
                      name: "",
                      type: "Vendor",
                      contact_person: "",
                      address: "",
                      gst: "",
                      mobile: "",
                      phone: "",
                      fax: "",
                      email: "",
                      alternate_email: "",
                      status: true,
                  },
              }
    );

    const onSubmit = (data) => {
        if (isEdit) {
          const payload = {
            id: vendor._id,
            body: {
                ...data,
                org_id: userData.org_id,
            },
        };
        editingVendr(payload);
        closingModel();
        }else{
          const payload = {
            ...data,
            org_id: userData.org_id,
        };
        adVendor(payload);
        closingModel();
        }
        
        reset();
    };

    return (
        <div className="bg-[#EBEDF2] p-12 fixed top-0 left-0 w-full h-full z-20">
            <p className="mb-8 text-right">
                <button onClick={closingModel}>
                    <img src={closeIcon} alt="" />
                </button>
            </p>

            <div className="flex">
                <div className="w-[40%] text-left">
                    <h3 className="text-[24px] text-[#2D3846]">
                        {isEdit
                            ? "Edit Supplier"
                            : isView
                            ? "View Supplier"
                            : "Add New Supplier"}
                    </h3>
                    <p className="text-[14px] text-[#656F7D]">
                        Enter Supplier details and add to list
                        {isEdit ? " or update Supplier details" : isView ? " or view Supplier details" : ""}
                    </p>
                </div>
                <div className="w-[60%]">
                    <form onSubmit={handleSubmit(onSubmit)} className={isView ? 'view-page' : ''}>
                        <div className="bg-white p-5 rounded-md max-h-[70vh] overflow-y-auto">
                            <h4 className="text-[20px] text-left border-b-2 border-b-[#007AC8] mb-8">
                                Supplier Details
                            </h4>

                            <div className="grid grid-cols-2 gap-8 text-left">
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Name Of The Company <span className="text-[red]">*</span>
                                        </label>
                                        <input
                                            className={errors.name ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                                            type="text"
                                            {...register("name", {
                                                required:
                                                    "Supplier Company Name is required",
                                            })}
                                        />
                                    </div>
                                    {errors.name && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors.name?.message}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Supplier Type
                                        </label>
                                        <select
                                            className="border border-gray-300 text-[#2D3846] text-[16px] rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-3.5 font-bold dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            {...register("type", {
                                                required:
                                                    "Supplier Type is required",
                                            })}
                                        >
                                            <option value="Vendor">
                                                Supplier
                                            </option>
                                            <option value="Contractor">
                                                Contractor
                                            </option>
                                        </select>
                                    </div>
                                    {errors.type && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors.type?.message}
                                        </p>
                                    )}
                                </div>
                                 <div>
        {/* New Section for 3 Columns */}
        <div className="grid grid-cols-3 gap-4">
            {/* Textbox 1 */}
            <div>
                <label
                    className="text-[#2B384A] text-[12px] block mb-2"
                    htmlFor=""
                >
                    Contact Person Name
                </label>
                <input
                    className="text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                    type="text"
                    {...register("contact_person_a.name")}
                />
            </div>
            {/* Textbox 2 */}
            <div>
                <label
                    className="text-[#2B384A] text-[12px] block mb-2"
                    htmlFor=""
                >
                     Contact Person Phone
                </label>
                <input
                    className="text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                    type="text"
                    {...register("contact_person_a.phone")}
                />
            </div>
            {/* Textbox 3 */}
            <div>
                <label
                    className="text-[#2B384A] text-[12px] block mb-2"
                    htmlFor=""
                >
                     Contact Person 
                     Email
                </label>
                <input
                    className="text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                    type="text"
                    {...register("contact_person_a.email")}
                />
            </div>
        </div>
    </div>
     <div>
        {/* New Section for 3 Columns */}
        <div className="grid grid-cols-3 gap-4">
            {/* Textbox 1 */}
            <div>
                <label
                    className="text-[#2B384A] text-[12px] block mb-2"
                    htmlFor=""
                >
                    Alternate Contact Person Name
                </label>
                <input
                    className="text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                    type="text"
                    {...register("contact_person_b.name")}
                />
            </div>
            {/* Textbox 2 */}
            <div>
                <label
                    className="text-[#2B384A] text-[12px] block mb-2"
                    htmlFor=""
                >
                     Alternate Contact Person Phone
                </label>
                <input
                    className="text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                    type="text"
                    {...register("contact_person_b.phone")}
                />
            </div>
            {/* Textbox 3 */}
            <div>
                <label
                    className="text-[#2B384A] text-[12px] block mb-2"
                    htmlFor=""
                >
                     Alternate Contact Person Email
                </label>
                <input
                    className="text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                    type="text"
                    {...register("contact_person_b.email")}
                />
            </div>
        </div>
    </div>
      <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      Category <span className="text-[red]">*</span>
                    </label>
                    <select
                      className={errors.category_id ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                      {...register("category_id", {
                        required: "Category is required",
                      })}
                    >
                      <option value="">Select Category</option>
                      {data?.result.map((c) => (
                        <option key={c._id} value={c._id}>
                          {c.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {errors?.category_id && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      {errors?.category_id?.message}
                    </p>
                  )}
                </div>
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Type of Contractor <span className="text-[red]">*</span>
                                        </label>
                                        <input
                                            className={errors.contact_person ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                                            type="text"
                                            {...register("contact_person", {
                                                required:
                                                    "Contact Person Name is required",
                                            })}
                                        />
                                    </div>
                                    {errors.contact_person && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors.contact_person?.message}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                           Office Address <span className="text-[red]">*</span>
                                        </label>
                                        <input
                                            className={errors.address ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                                            type="text"
                                            {...register("address", {
                                                required: "Address is required",
                                            })}
                                        />
                                    </div>
                                    {errors.address && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors.address?.message}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            GST <span className="text-[red]">*</span>
                                        </label>
                                        <input
                                            className={errors.gst ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                                            type="text"
                                            {...register("gst", {
                                                required: "GST is required",
                                            })}
                                        />
                                    </div>
                                    {errors.gst && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors.gst?.message}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Mobile <span className="text-[red]">*</span>
                                        </label>
                                        <input
                                            className={errors.mobile ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                                            type="text"
                                            {...register("mobile", {
                                                required: "Mobile number is required",
                                                pattern: {
                                                    value: PHONE_NO_ONLY,
                                                    message:
                                                        "Please Enter valid Phone no.",
                                                },
                                            })}
                                        />
                                    </div>
                                    {errors.mobile && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors.mobile?.message}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Phone
                                        </label>
                                        <input
                                            className={errors.phone ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                                            type="text"
                                            {...register("phone", {
                                                pattern: {
                                                    value: NUMBERS_ONLY,
                                                    message:
                                                        "Please Enter valid Phone no.",
                                                },
                                            })}
                                        />
                                    </div>
                                    {errors.phone && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors.phone?.message}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <div>
                                     <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Fax
                                        </label>
                                        <input
                                            className={errors.fax ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                                            type="text"
                                            {...register("fax", {
                                                pattern: {
                                                    value: NUMBERS_ONLY,
                                                    message:
                                                        "Please Enter valid Fax no.",
                                                },
                                            })}
                                        />
                                    </div>
                                    {errors.fax && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors.fax?.message}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Email
                                        </label>
                                        <input
                                            className={errors.email ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                                            type="text"
                                            {...register("email", {
                                                pattern: {
                                                    value: EMAIL_ONLY,
                                                    message:
                                                        "Please Enter valid Email.",
                                                },
                                            })}
                                        />
                                    </div>
                                    {errors.email && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors.email?.message}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Alternate Email
                                        </label>
                                        <input
                                            className={errors.alternate_email ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                                            type="text"
                                            {...register("alternate_email", {
                                                pattern: {
                                                    value: EMAIL_ONLY,
                                                    message:
                                                        "Please Enter valid Email.",
                                                },
                                            })}
                                        />
                                    </div>
                                    {errors.alternate_email && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors.alternate_email?.message}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Status
                                        </label>
                                        <div className="flex">
                                            <p className="flex mr-3">
                                                <input
                                                    {...register("status", {
                                                        required:
                                                            "Status is required",
                                                    })}
                                                    type="radio"
                                                    defaultChecked
                                                    value={true}
                                                />
                                                <span className="text-[#2B384A] text-[12px] pl-2">
                                                    Active
                                                </span>
                                            </p>
                                            <p className="flex">
                                                <input
                                                    {...register("status", {
                                                        required:
                                                            "Status is required",
                                                    })}
                                                    type="radio"
                                                    value={false}
                                                />
                                                <span className="text-[#2B384A] text-[12px] pl-2">
                                                    Disable
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    {errors.status && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors.status?.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                        {!isView && (
                            <p className="text-right mt-8 flex items-center justify-end">
                                <button
                                    onClick={closingModel}
                                    className="bg-transparent h-[40px] w-[100px] border text-[#007AC8] border-[#007AC8] rounded text-[14px] font-bold mr-3"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="Submit"
                                    className="bg-[#007AC8] h-[40px] w-[100px] border border-[#007AC8] rounded text-white text-[14px] font-bold uppercase"
                                    disabled={isLoading}
                                >
                                    {isLoading ? (
                                        <BtnLoader />
                                    ) : isEdit ? (
                                        "Update"
                                    ) : (
                                        "Create"
                                    )}
                                </button>
                            </p>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
}
