import React from 'react';
import ProjectList from './ProjectList';

export default function Vendors() {
  return (
    <div className='bg-white p-8'>
        <ProjectList />
    </div>
  )
}
