import {
  endPoints,
  getAuthorizationHeader,
  axiosInstance,
} from "../components/api";

export const getRoleList = async () => {
  const { data: response } = await axiosInstance.get(`${endPoints.roles}`, {
    headers: { Authorization: getAuthorizationHeader() },
  });
  return response;
};

export const addRole = async (reqBody) => {
  const { data: response } = await axiosInstance.post(
    `${endPoints.roles}`,
    reqBody,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};

export const editRole = async (roleObj) => {
  const { data: response } = await axiosInstance.put(
    `${endPoints.roles}/${roleObj.id}`,
    roleObj.body,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};

export const deleteRole = async (roleId) => {
  const { data: response } = await axiosInstance.delete(
    `${endPoints.roles}/${roleId}`,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};
