import {
  endPoints,
  getAuthorizationHeader,
  axiosInstance,
} from "../components/api";

export const getUserProfileList = async () => {
  const { data: response } = await axiosInstance.get(`${endPoints.userprofiles}`, {
    headers: { Authorization: getAuthorizationHeader() },
  });
  return response;
};

export const addUserProfile = async (reqBody) => {
  const { data: response } = await axiosInstance.post(
    `${endPoints.userprofiles}`,
    reqBody,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};

export const editUserProfile = async (profileObj) => {
  const { data: response } = await axiosInstance.put(
    `${endPoints.userprofiles}/${profileObj.id}`,
    profileObj.body,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};

export const deleteUserProfile = async (profileId) => {
  const { data: response } = await axiosInstance.delete(
    `${endPoints.userprofiles}/${profileId}`,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};
