import {
  endPoints,
  getAuthorizationHeader,
  axiosInstance,
} from "../components/api";

export const getMaterialList = async () => {
  const { data: response } = await axiosInstance.get(`${endPoints.materials}`, {
    headers: { Authorization: getAuthorizationHeader() },
  });
  return response;
};

export const getMaterial = async (id) => {
  const { data: response } = await axiosInstance.get(
    `${endPoints.materials}/${id}`,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};

export const addMaterial = async (reqBody) => {
  const { data: response } = await axiosInstance.post(
    `${endPoints.materials}`,
    reqBody,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};

export const editMaterial = async (matObj) => {
  const { data: response } = await axiosInstance.put(
    `${endPoints.materials}/${matObj.id}`,
    matObj.body,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};

export const deleteMaterial = async (matId) => {
  const { data: response } = await axiosInstance.delete(
    `${endPoints.materials}/${matId}`,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};

export const importMaterials = async (reqBody) => {
  const { data: response } = await axiosInstance.post(
    `${endPoints.materials}/sub/upload/`,
    reqBody,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};
