import React, { Fragment, useState } from "react";
import infoIcon from "../../assets/image/blue-info.svg";
import whitePlus from "../../assets/image/white-plus.svg";
import { useQuery } from "@tanstack/react-query";
import { getMaterialList, importMaterials, addMaterial, editMaterial, deleteMaterial } from "../../queries/Materials";
import AddMaterial from "./AddMaterial";
import ImportMaterial from "./ImportMaterial";
import { Loader } from "../../components/utils/Loader";
import { useQueryMutation } from "../../hooks/useMutation";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { getCategoryList } from "../../queries/Categories";
import { Table } from "antd";
import useModulePermissions from "../../hooks/useModulePermissions";
import { MaterialColumns } from "../../utils/colConfig";
import { useFilteredList } from "../../hooks/useFilteredList";
import SearchList from "../../components/SearchList";

const filterByName = (list, searchValue) => {
    const trimmedSearchVal = searchValue.trim().toLowerCase();
    return list.filter((item) => {
        return item.name.toLowerCase().includes(trimmedSearchVal);
    });
};

export default function MaterialList() {
    const userData = JSON.parse(localStorage.getItem("loggedUser"));
    const permissions = useModulePermissions(userData);
    const queryClient = useQueryClient();
    const [showModel, setShowModel] = useState(false);
    const [showImportModel, setImportModel] = useState(false);
    const [isView, setIsView] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [materialData, setMaterialData] = useState({});
    const { isLoading, isError, data } = useQuery({
        queryKey: ["materials"],
        queryFn: getMaterialList,
    });

    const {
        mutate,
        isSuccess: importMatIsSuccess,
        isLoading: importMatLoad,
        success: importMatSuccess,
    } = useMutation({
        mutationKey: ["import-material"],
        mutationFn: importMaterials,
    });

    const { data: catData } = useQuery({
        queryKey: ["categories"],
        queryFn: getCategoryList,
    });

    const {
        filteredList: materialList,
        searchVal,
        handleSubmit,
        handleChange,
    } = useFilteredList(data?.result, filterByName);


    const deleteMaterialList = useQueryMutation(
        deleteMaterial,
        queryClient,
        "materials"
    );
    const editMaterialList = useQueryMutation(
        editMaterial,
        queryClient,
        "materials"
    );
    const addMaterialList = useQueryMutation(
        addMaterial,
        queryClient,
        "materials"
    );

    const closeModel = () => {
        setShowModel(false);
        setIsView(false);
        setIsEdit(false);
    };
    
    const deleMaterial = (id) => {
      deleteMaterialList.mutate(id);
    };

  const edMaterial = (obj) => {
      editMaterialList.mutate(obj);
  };

  const adMaterial = (obj) => {
      addMaterialList.mutate(obj);
  };

  const settingIsEdit = (editVisible, material) => {
    setShowModel(true);
    setMaterialData(material);
    setIsEdit(editVisible);
};
    

    const columns = MaterialColumns(
        deleMaterial,
        edMaterial,
        isLoading,
        catData,
        settingIsEdit
    );

    

    

    const closeImportMatModel = () => {
        setImportModel(false);
    };

    const importMaterial = (obj) => {
        mutate(obj, {
            onSuccess: (data) => {
                if (data.status === "success") {
                    toast.success(data?.message);
                }
            },
            onError: (err) => {
                if (err?.response?.status > 200) {
                    toast.error(err.response.data);
                }
            },
        });
    };

    if (isLoading) return <Loader />;

    if (isError)
        return (
            <p className="text-center my-20 font-bold">Something Went Wrong!</p>
        );

    return (
        <div>
            {data.result && data.result.length > 0 ? (
                <Fragment>
                    <div className="flex justify-between mb-5">
                        <SearchList
                            searchVal={searchVal}
                            handleChange={handleChange}
                            handleSubmit={handleSubmit}
                            placeHolder={"Material Name..."}
                        />
                        <div className="flex">
                        {permissions.showMaterialMenu !== undefined &&
                            permissions.showMaterialMenu.write && (
                                <>
                                    <button
                                        onClick={() => setShowModel(true)}
                                        className="primary mr-3 btn flex items-center justify-center text-[#fff] text-[14px] font-bold"
                                    >
                                        <img src={whitePlus} alt="" />
                                        <span className="ml-3">
                                            Add New Material
                                        </span>
                                    </button>
                                    <button
                                        onClick={() => setImportModel(true)}
                                        className="primary btn flex items-center justify-center text-[#fff] text-[14px] font-bold"
                                    >
                                        <img src={whitePlus} alt="" />
                                        <span className="ml-3">
                                            Import Materials
                                        </span>
                                    </button>
                                </>
                            )}
                            </div>
                    </div>
                    <Table
                        pagination={{ pageSize: 6 }}
                        className="w-full text-left"
                        dataSource={materialList}
                        columns={columns}
                        onRow={(record, rowIndex) => {
                            return {
                              onClick: () => {setMaterialData(record); setShowModel(true); setIsView(true)},
                            };
                        }}
                    />
                </Fragment>
            ) : (
                <div className="text-center">
                    <img className="mx-auto" src={infoIcon} alt="Info Icon" />
                    <h4 className="text-[20px] text-[#2D3846] font-bold">
                        No data available
                    </h4>
                    <p className="text-[14px] text-[#656F7D] mb-12">
                        You have not added/created any Material here
                    </p>
                    {permissions.showMaterialMenu !== undefined &&
                        permissions.showMaterialMenu.write && (
                            <p className="flex justify-center">
                                <button
                                    onClick={() => setShowModel(true)}
                                    className="primary btn flex items-center justify-center text-[#fff] text-[14px] font-bold"
                                >
                                    <img src={whitePlus} alt="" />
                                    <span className="ml-3">
                                        Add New Material
                                    </span>
                                </button>
                            </p>
                        )}
                </div>
            )}

            {showModel && permissions.showMaterialMenu !== undefined &&
                        permissions.showMaterialMenu.write && (
                <AddMaterial
                    closeModel={closeModel}
                    adMaterial={adMaterial}
                    isLoading={addMaterialList.isLoading}
                    material={materialData}
                    isView={isView}
                    isEdit={isEdit}
                    settingIsEdit={settingIsEdit}
                    edMaterial={edMaterial}
                />
            )}
            {showImportModel && permissions.showMaterialMenu !== undefined &&
                        permissions.showMaterialMenu.write && (
                <ImportMaterial
                    closeImportMatModel={closeImportMatModel}
                    importMaterial={importMaterial}
                    isLoading={importMatLoad}
                />
            )}
        </div>
    );
}
