import React, { useState, useEffect } from "react";
import closeIcon from "../../assets/image/blue-cross.svg";
import { useForm } from "react-hook-form";
import { BtnLoader, Loader } from "../../components/utils/Loader";
import { getProjectList } from "../../queries/Projects";
import { getIndentById } from "../../queries/Indent";
import { useQuery } from "@tanstack/react-query";
import DatePicker from "react-datepicker";
import { Modal, Table, InputNumber } from "antd";
import AssignMaterial from "./AssignMaterial";
import moment from "moment";
import toast from "react-hot-toast";
import { useParams, NavLink } from "react-router-dom";
import { Empty } from "antd";

export default function ApproveIndent(props) {
    const params = useParams();
    const [projectName, setProjectName] = useState("");

    const { data: indentData, refetch, isError, isLoading } = useQuery({
        queryKey: ["indent", params.id],
        queryFn: () => getIndentById(params.id),
        enabled: !!params.id,
        refetchOnWindowFocus: true,
    });
    const { data: projectData } = useQuery({
        queryKey: ["project"],
        queryFn: getProjectList,
    });

    

    useEffect(() => {
        refetch();    
    }, [params.id, refetch]);

    useEffect(() => {
        if (projectData && projectData?.result) {
            const project = projectData?.result.find(
                (project) => project._id === indentData?.result?.project_id
            );
            setProjectName(project?.name);
        }    
    }, [projectData, indentData]);

    if (isLoading) {
        return <Loader />;
    }
    if (!indentData || !indentData.result) {
        return <div className="py-44"><Empty /></div>;
    }

    if (isError) {
        return <div>Error loading data</div>;
    }

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            width: "15%",
        },
        {
            title: "BOQ No.",
            dataIndex: "boq_no",
            key: "boq_no",
            width: "10%",
        },
        // {
        //     title: "Description",
        //     dataIndex: "description",
        //     key: "description",
        //     width: "15%",
        // },
        {
            title: "Brand",
            dataIndex: "brand",
            key: "brand",
            width: "10%",
        },
        {
            title: "Unit",
            dataIndex: "unit",
            key: "unit",
            width: "10%",
        },
        // {
        //     title: "Model",
        //     dataIndex: "model",
        //     key: "model",
        //     width: "10%",
        // },
        {
            title: "QTY",
            dataIndex: "quantity",
            key: "qty",
            width: "15%",
        },
        {
            title: "Remarks",
            dataIndex: "remarks",
            key: "remarks",
            width: "15%",
        },
    ];

    return (
        <div className="bg-[#EBEDF2] p-12 fixed top-0 left-0 w-full h-full z-10">
            <p className="mb-8 flex justify-end">
                <NavLink to='/notification'>
                    <img src={closeIcon} alt="" />
                </NavLink>
            </p>
            <div className="flex">
                <div className="w-[20%] text-left">
                    <h3 className="text-[24px] text-[#2D3846]">
                        Indent Approval
                    </h3>
                    <p className="text-[14px] text-[#656F7D]">
                        To Approve or Reject the Indent
                    </p>
                </div>
                <div className="w-[80%]">
                    <div className="bg-white p-5 rounded-md max-h-[70vh] overflow-y-auto">
                        <h4 className="text-[20px] text-left border-b-2 border-b-[#007AC8] mb-8">
                            Indent Details
                        </h4>
                        <div className="grid grid-cols-4 gap-8 text-left mb-8">
                            <div>
                                <label
                                    className="text-[#2B384A] text-[12px] block mb-2"
                                    htmlFor=""
                                >
                                    Project
                                </label>
                                <p>{projectName}</p>
                            </div>
                            <div>
                                <label
                                    className="text-[#2B384A] text-[12px] block mb-2"
                                    htmlFor=""
                                >
                                    Required Date
                                </label>
                                <p>
                                    {moment(
                                        indentData?.result?.required_date
                                    ).format("DD-MM-YYYY")}
                                </p>
                            </div>
                            <div>
                                <label
                                    className="text-[#2B384A] text-[12px] block mb-2"
                                    htmlFor=""
                                >
                                    Description
                                </label>
                                <p>{indentData?.result?.description}</p>
                            </div>
                            <div>
                                <label
                                    className="text-[#2B384A] text-[12px] block mb-2"
                                    htmlFor=""
                                >
                                    Status
                                </label>
                                <p>{indentData?.result?.approval_status}</p>
                            </div>
                        </div>
                        <div className="border-b-2 border-b-[#007AC8] mb-8 pb-2 flex justify-between">
                            <h4 className="text-[20px] text-left">
                                Assign Materials
                            </h4>
                        </div>
                        <div>
                            {indentData?.result?.materials?.length > 0 && (
                                <Table
                                    pagination={{ pageSize: 6 }}
                                    rowKey={(record) => record.material_id}
                                    className="w-full text-left"
                                    dataSource={indentData?.result?.materials}
                                    columns={columns}
                                />
                            )}
                        </div>
                    </div>
                    <p className="text-right mt-8 flex items-center justify-end">
                        <button
                            className="bg-transparent h-[40px] w-[100px] border text-[#007AC8] border-[#007AC8] rounded text-[14px] font-bold mr-3"
                        >
                            Reject
                        </button>
                        <button
                            type="Submit"
                            className="bg-[#007AC8] h-[40px] w-[100px] border border-[#007AC8] rounded text-white text-[14px] font-bold uppercase"
                        >
                            Approve
                        </button>
                    </p>
                </div>
            </div>
        </div>
    );
}
