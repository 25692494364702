import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";

export const useQueryMutation = (mutationFn, queryClient, queryName) => {
    return useMutation(mutationFn, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(queryName);
            if (data.status === "success") {
                toast.success(data?.message);
            }
        },
        onError: (err) => {
            queryClient.invalidateQueries(queryName);
            if (err?.response?.status > 200) {
                toast.error(err?.response?.data?.result ? err?.response?.data?.result : err?.response?.data?.message);
            }
        },
    });
};