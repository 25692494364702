import React, { useState } from "react";
import actionIcon from "../../assets/image/blue-action.svg";
import editIcon from "../../assets/image/edit-blue.svg";
import delIcon from "../../assets/image/delete-blue.svg";
import { useClickAway } from "@uidotdev/usehooks";
import { Eye, Receipt } from 'lucide-react';

import useModulePermissions from "../../hooks/useModulePermissions";
import AddGrn from "./AddGrn";
import UpdateBillingInfoModal from "./UpdateBillingInfoModal";

export default function GrnListTable(props) {
  const userData = JSON.parse(localStorage.getItem("loggedUser"));
    const permissions = useModulePermissions(userData);
  const { grn, isLoading } = props;
  const [showAction, setShowAction] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isBilling, setIsBilling] = useState(false);

 

  const ref = useClickAway(() => {
    setShowAction(false);
  });

  const deletingGrn = (id) => {
    props.deleGrn(id);
  }

  const closeModel = () => {
    setShowModel(false);
    setShowAction(false);
    setIsView(false);
    setIsEdit(false);
    setIsBilling(false);
  }

  const editingGrn = (obj) => {
    props.edGrn(obj)
  }

  return (
    
    <>
    {permissions.showGrnMenu !== undefined && (permissions.showGrnMenu.update || permissions.showGrnMenu.delete) && (
      <span
      onClick={() => setShowAction(!showAction)}
      className="p-3 cursor-pointer flex justify-end"
    >
      <img src={actionIcon} alt="" />
    </span>
    )}
    
      {showAction && (
          <div className="absolute top-[10px] right-[30px] border border-solid border-[#D9D9D9] rounded z-10" ref={ref}>
            <ul className="w-[135px]">
              
            <li className="text-[12px] cursor-pointer px-3 py-2 flex bg-white hover:bg-[#F5F5F5] rounded-t items-center border-b border-solid border-[#D9D9D9]" onClick={() => {setShowModel(true); setIsView(true)}}>
                <span className="pr-2"><Eye size={18} color="#007AC8" /></span>
                <span>View</span>
              </li>
              {permissions.showGrnMenu !== undefined && permissions.showGrnMenu.update && (
                <li className="text-[12px] cursor-pointer px-3 py-2 flex bg-white hover:bg-[#F5F5F5] rounded-t items-center border-b border-solid border-[#D9D9D9]" onClick={() => {setShowModel(true); setIsEdit(true)}}>
                <span className="pr-3"><img src={editIcon} alt="" /></span>
                <span>Edit</span>
              </li>
              )}
              {permissions.showGrnMenu !== undefined && permissions.showGrnMenu.delete && (
                <li
                onClick={() => deletingGrn(grn._id)}
                className="text-[12px] px-3 py-2 cursor-pointer rounded-b bg-white hover:bg-[#F5F5F5] flex items-center border-b border-solid border-[#D9D9D9]"
              >
                <span className="pr-3"><img src={delIcon} alt="" /></span>
                <span>Delete</span>
              </li>
              )}

              <li onClick={() => setIsBilling(true)}
                className="text-[12px] px-3 py-2 cursor-pointer rounded-b bg-white hover:bg-[#F5F5F5] flex items-center">
                  <span className="pr-2"><Receipt size={18} color="#007AC8" /></span>
                <span>Update Billing</span>
              </li>
              
            </ul>
          </div>
        )}
      {showModel && <AddGrn isView={isView} isEdit={isEdit} closeModel={closeModel} grn={grn} editingGrn={editingGrn} isLoading={isLoading} />}


      {isBilling && <UpdateBillingInfoModal closeModel={closeModel} grn={grn} editingGrn={editingGrn} />}


      </>
  );
}
