import {
    endPoints,
    getAuthorizationHeader,
    axiosInstance,
  } from "../components/api";

export const getGrnList = async () => {
  const { data: response } = await axiosInstance.get(`${endPoints.grn}`, {
    headers: { Authorization: getAuthorizationHeader() },
  });
  return response;
};

export const addingGrn = async (reqBody) => {
  const { data: response } = await axiosInstance.post(
    `${endPoints.grn}`,
    reqBody,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};

export const editGrn = async (ind) => {
  const { data: response } = await axiosInstance.put(
    `${endPoints.grn}/${ind.id}`,
    ind.body,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};

export const deleteGrn = async (indId) => {
  const { data: response } = await axiosInstance.delete(
    `${endPoints.grn}/${indId}`,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};

export const uploadFilesGrn = async (grnObj) => {
  const { data: response } = await axiosInstance.post(
    `${endPoints.grn}/sub/upload/${grnObj?.id}`,
    grnObj?.body,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};
