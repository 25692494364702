import React from 'react';

const TabComponent = ({ tabData, activeTab, setActiveTab, permissions }) => (
  <div className='tabs bg-white border-b text-left'>
    {
      tabData.map((tab, i) => (
        permissions[tab.permission] !== undefined && (
          <button 
            className={`py-3 px-8 border-r text-[#656F7D] text-[14px] disabled:border-b-[3px] disabled:border-b-[#007AC8] disabled:bg-[#ebedf282]`} 
            disabled={activeTab === `${tab.id}`} 
            key={i} 
            onClick={() => setActiveTab(tab.id)}>
              {tab.tabTitle}
          </button>
      )))
    }
  </div>
);

export default TabComponent;