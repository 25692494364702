import {
  endPoints,
  getAuthorizationHeader,
  axiosInstance,
} from "../components/api";

export const getDepartmentList = async () => {
  const { data: response } = await axiosInstance.get(
    `${endPoints.departments}`,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};

export const addDepartment = async (reqBody) => {
  const { data: response } = await axiosInstance.post(
    `${endPoints.departments}`,
    reqBody,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};

export const editDepartment = async (depObj) => {
  const { data: response } = await axiosInstance.put(
    `${endPoints.departments}/${depObj.id}`,
    depObj.body,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};

export const deleteDepartment = async (departId) => {
  const { data: response } = await axiosInstance.delete(
    `${endPoints.departments}/${departId}`,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};
