import {
  endPoints,
  getAuthorizationHeader,
  axiosInstance,
} from "../components/api";

export const getProjectList = async () => {
  const { data: response } = await axiosInstance.get(`${endPoints.projects}`, {
    headers: { Authorization: getAuthorizationHeader() },
  });
  return response;
};

export const addProject = async (reqBody) => {
  const { data: response } = await axiosInstance.post(
    `${endPoints.projects}`,
    reqBody,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};

export const editProject = async (proj) => {
  const { data: response } = await axiosInstance.put(
    `${endPoints.projects}/${proj.id}`,
    proj.body,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};

export const deleteProject = async (projId) => {
  const { data: response } = await axiosInstance.delete(
    `${endPoints.projects}/${projId}`,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};
