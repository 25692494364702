import React from 'react';
import IndentList from './IndentList';

export default function Indent() {
  return (
    <div className='bg-white p-8'>
        <IndentList />
    </div>
  )
}
