import {
  endPoints,
  baseURL,
  getAuthorizationHeader,
  axiosInstance,
} from "../components/api";

export const assignMaterials = async (reqBody) => {
  const { data: response } = await axiosInstance.post(
    `${baseURL}${endPoints.projectMaterialAssign}`,
    reqBody,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};
