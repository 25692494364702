import React from 'react';
import VendorList from './VendorList';

export default function Vendors() {
  return (
    <div className='bg-white p-8'>
        <VendorList />
    </div>
  )
}
