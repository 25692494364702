import React, { Fragment, useState } from "react";
import infoIcon from "../../assets/image/blue-info.svg";
import whitePlus from "../../assets/image/white-plus.svg";
import { useQuery } from "@tanstack/react-query";
import {
    getApprovalList,
    addApproval,
    editApproval,
    deleteApproval,
} from "../../queries/Approvals";
import AddApproval from "./AddApproval";
import { Loader } from "../../components/utils/Loader";
import { useQueryClient } from "@tanstack/react-query";
import SearchList from "../../components/SearchList";
import { useFilteredList } from "../../hooks/useFilteredList";
import { Table } from "antd";
import {useQueryMutation} from '../../hooks/useMutation';
import { ApprovalColumns } from "../../utils/colConfig";
import useModulePermissions from '../../hooks/useModulePermissions';

const filterByName = (list, searchValue) => {
    const trimmedSearchVal = searchValue.trim().toLowerCase();
    return list.filter((item) => {
        return item.requestType.toLowerCase().includes(trimmedSearchVal);
    });
};

export default function ApprovalList() {
    const userData = JSON.parse(localStorage.getItem("loggedUser"));
    const permissions = useModulePermissions(userData);
    const queryClient = useQueryClient();
    const [showModel, setShowModel] = useState(false);
    const [isView, setIsView] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [approvalData, setApprovalData] = useState({});
    const { isLoading, data } = useQuery({
        queryKey: ["approval"],
        queryFn: getApprovalList,
    });

    const {
        filteredList: approvalList,
        searchVal,
        handleSubmit,
        handleChange,
    } = useFilteredList(data?.result, filterByName);

    
    const deleteApprovalList = useQueryMutation(deleteApproval, queryClient, 'approval');
    const addApprovalList = useQueryMutation(addApproval, queryClient, 'approval');
    const editApprovalList = useQueryMutation(editApproval, queryClient, 'approval');

    const closeModel = () => {
        setShowModel(false);
        setIsView(false);
        setIsEdit(false);
    };

    const deleApproval = (id) => {
        deleteApprovalList.mutate(id);
    };

    const edApproval = (obj) => {
        editApprovalList.mutate(obj);
    };

    const adApproval = (obj) => {
        addApprovalList.mutate(obj);
    };

    const settingIsEdit = (editVisible, approvers) => {
        setShowModel(true);
        setApprovalData(approvers);
        setIsEdit(editVisible);
    };

    const columns = ApprovalColumns(deleApproval, edApproval, isLoading, settingIsEdit);

    if (isLoading) return <Loader />;

    return (
        <div>
            {data.result && data.result.length > 0 ? (
                <Fragment>
                    <div className="flex justify-between mb-5">
                        <SearchList
                            searchVal={searchVal}
                            handleChange={handleChange}
                            handleSubmit={handleSubmit}
                            placeHolder={"Search Request Type..."}
                        />
                        {permissions.showApprovalsMenu !== undefined && permissions.showApprovalsMenu.write && (
                            <button
                            onClick={() => setShowModel(true)}
                            className="primary btn flex items-center justify-center text-[#fff] text-[14px] font-bold"
                        >
                            <img src={whitePlus} alt="" />
                            <span className="ml-3">Add New Approval</span>
                            </button>
                        )}
                        
                    </div>
                    <Table
                        pagination={{ pageSize: 6 }}
                        className="w-full text-left"
                        dataSource={approvalList}
                        columns={columns}
                        onRow={(record, rowIndex) => {
                            return {
                              onClick: () => {setApprovalData(record); setShowModel(true); setIsView(true)},
                            };
                        }}
                    />
                </Fragment>
            ) : (
                <div className="text-center">
                    <img className="mx-auto" src={infoIcon} alt="Info Icon" />
                    <h4 className="text-[20px] text-[#2D3846] font-bold">
                        No data available
                    </h4>
                    <p className="text-[14px] text-[#656F7D] mb-12">
                        You have not added/created any Approval here
                    </p>
                    {permissions.showApprovalsMenu !== undefined && permissions.showApprovalsMenu.write && (
                        <p className="flex justify-center">
                        <button
                            onClick={() => setShowModel(true)}
                            className="primary btn flex items-center justify-center text-[#fff] text-[14px] font-bold"
                        >
                            <img src={whitePlus} alt="" />
                            <span className="ml-3">Add New Approval</span>
                        </button>
                    </p>
                    )}
                    
                </div>
            )}

            {showModel && permissions.showApprovalsMenu !== undefined && permissions.showApprovalsMenu.write && (
                <AddApproval
                    closeModel={closeModel}
                    adApproval={adApproval}
                    isLoading={addApprovalList.isLoading}
                    approval={approvalData}
                    isView={isView}
                    isEdit={isEdit}
                    settingIsEdit={settingIsEdit}
                    edApproval={edApproval}
                />
            )}
        </div>
    );
}
