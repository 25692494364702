import {
  endPoints,
  getAuthorizationHeader,
  axiosInstance,
} from "../components/api";

export const getCustomerList = async () => {
  const { data: response } = await axiosInstance.get(`${endPoints.customers}`, {
    headers: { Authorization: getAuthorizationHeader() },
  });
  return response;
};

export const addCustomer = async (reqBody) => {
  const { data: response } = await axiosInstance.post(
    `${endPoints.customers}`,
    reqBody,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};

export const editCustomer = async (cusObj) => {
  const { data: response } = await axiosInstance.put(
    `${endPoints.customers}/${cusObj.id}`,
    cusObj.body,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};

export const deleteCustomer = async (cusId) => {
  const { data: response } = await axiosInstance.delete(
    `${endPoints.customers}/${cusId}`,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};
