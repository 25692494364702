import React, { Fragment, useState } from "react";
import infoIcon from "../../assets/image/blue-info.svg";
import whitePlus from "../../assets/image/white-plus.svg";
import { useQuery } from "@tanstack/react-query";
import {
    getDepartmentList,
    addDepartment,
    editDepartment,
    deleteDepartment,
} from "../../queries/Departments";
import AddDepartment from "./AddDepartment";
import { Loader } from "../../components/utils/Loader";
import { useQueryClient } from "@tanstack/react-query";
import SearchList from "../../components/SearchList";
import { useFilteredList } from "../../hooks/useFilteredList";
import { Table } from "antd";
import {useQueryMutation} from '../../hooks/useMutation';
import useModulePermissions from '../../hooks/useModulePermissions';
import { DepartmentColumns } from "../../utils/colConfig";

const filterByName = (list, searchValue) => {
    const trimmedSearchVal = searchValue.trim().toLowerCase();
    return list.filter((item) => {
        return item.name.toLowerCase().includes(trimmedSearchVal);
    });
};

export default function DepartmentList() {
    const userData = JSON.parse(localStorage.getItem("loggedUser"));
    const permissions = useModulePermissions(userData);
    const queryClient = useQueryClient();
    const [showModel, setShowModel] = useState(false);
    const [isView, setIsView] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [departmentData, setDepartmentData] = useState({});
    const { isLoading, data } = useQuery({
        queryKey: ["departments"],
        queryFn: getDepartmentList,
    });

    const deleteDepartmentList = useQueryMutation(deleteDepartment, queryClient, 'departments');
    const addDepartmentList = useQueryMutation(addDepartment, queryClient, 'departments');
    const editDepartmentList = useQueryMutation(editDepartment, queryClient, 'departments');

    const {
        filteredList: departmentList,
        searchVal,
        handleSubmit,
        handleChange,
    } = useFilteredList(data?.result, filterByName);

    

    const closeModel = () => {
        setShowModel(false);
        setIsView(false);
        setIsEdit(false);
    };


    const deleDepartment = (id) => {
        deleteDepartmentList.mutate(id);
    };

    const edDepartment = (obj) => {
        editDepartmentList.mutate(obj);
    };

    const adDepartment = (obj) => {
        addDepartmentList.mutate(obj);
    };

    const settingIsEdit = (editVisible, project) => {
        setShowModel(true);
        setDepartmentData(project);
        setIsEdit(editVisible);
    };

    const columns = DepartmentColumns(deleDepartment, edDepartment, isLoading, settingIsEdit);

    if (isLoading) return <Loader />;

    return (
        <div>
            {data.result && data.result.length > 0 ? (
                <Fragment>
                    <div className="flex justify-between mb-5">
                        <SearchList
                            searchVal={searchVal}
                            handleChange={handleChange}
                            handleSubmit={handleSubmit}
                            placeHolder={"Search Department..."}
                        />
                        {permissions.showDepartmentMenu !== undefined && permissions.showDepartmentMenu.write && (
                            <button
                            onClick={() => setShowModel(true)}
                            className="primary btn flex items-center justify-center text-[#fff] text-[14px] font-bold"
                        >
                            <img src={whitePlus} alt="" />
                            <span className="ml-3">Add New Department</span>
                        </button>
                        )}
                        
                    </div>
                    <Table
                        pagination={{ pageSize: 6 }}
                        className="w-full text-left"
                        dataSource={departmentList}
                        columns={columns}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: () => {setDepartmentData(record); setShowModel(true); setIsView(true)},
                            };
                        }}
                    />
                </Fragment>
            ) : (
                <div className="text-center">
                    <img className="mx-auto" src={infoIcon} alt="Info Icon" />
                    <h4 className="text-[20px] text-[#2D3846] font-bold">
                        No data available
                    </h4>
                    <p className="text-[14px] text-[#656F7D] mb-12">
                        You have not added/created any Department here
                    </p>
                    {permissions.showDepartmentMenu !== undefined && permissions.showDepartmentMenu.write && (
                        <p className="flex justify-center">
                        <button
                            onClick={() => setShowModel(true)}
                            className="primary btn flex items-center justify-center text-[#fff] text-[14px] font-bold"
                        >
                            <img src={whitePlus} alt="" />
                            <span className="ml-3">Add New Department</span>
                        </button>
                    </p>
                    )}
                    
                </div>
            )}

            {showModel && permissions.showDepartmentMenu !== undefined && permissions.showDepartmentMenu.write && (
                <AddDepartment
                    closeModel={closeModel}
                    adDepartment={adDepartment}
                    isLoading={addDepartmentList.isLoading}
                    department={departmentData}
                    isView={isView}
                    isEdit={isEdit}
                    settingIsEdit={settingIsEdit}
                    edDepartment={edDepartment}
                />
            )}
        </div>
    );
}
