import React, {useEffect, useState} from "react";
import closeIcon from "../../assets/image/blue-cross.svg";
import { useForm } from "react-hook-form";
import { BtnLoader } from "../../components/utils/Loader";
import { useQuery } from "@tanstack/react-query";
import { getUserList } from "../../queries/User";
import { Select } from 'antd';
import { Controller } from 'react-hook-form';

export default function AddApproval(props) {
  const {isLoading, isEdit, isView, closeModel, approval, edApproval, adApproval} = props;
  const userName = JSON.parse(localStorage.getItem('loggedUser'));
  const [userOptions, setUserOptions] = useState([]);
  const [selectedApprovers, setSelectedApprovers] = useState(isEdit || isView ? approval?.approvers : []);
  const { data: userData } = useQuery({
    queryKey: ["users"],
    queryFn: getUserList,
});
  const closingModel = () => {
    closeModel();
  };
  const {
    register,
    reset,
    control,
    clearErrors,
    formState: { errors },
    handleSubmit,
  } = useForm(
    {
      defaultValues: {
        requestType: isEdit || isView ? approval?.requestType : "",
        approvers: isEdit || isView ? approval?.approvers[0] : [],
        approvalType: isEdit || isView ? approval?.approvalType : "",
        details: isEdit || isView ? approval?.details : "",
      },
    }
  );

  const onSubmit = (data) => {
    if(isEdit){
      const reqBody = {
        id: approval._id,
        body: {
          requestType: data.requestType,
          org_id: userName.org_id,
          approvers: selectedApprovers,
          approvalType: data.approvalType,
          details: data.details,
        }
      };
      edApproval(reqBody);
      closingModel();
    }else{
      const reqBody = {
        requestType: data.requestType,
        org_id: userName.org_id,
        approvers: selectedApprovers,
        approvalType: data.approvalType,
        details: data.details,
      };
      adApproval(reqBody);
      closingModel();
    }
    
    
    
    //console.log(reqBody);
  };

  const handleChange = (value) => {
    const temArr = [];
    value.map((v) => {
      temArr.push(v);
    });
    setSelectedApprovers(temArr);
    clearErrors("approvers");
  };

  useEffect(() => {
    let usrList = [];
    
    userData?.result
      ?.filter((d) => d.status === true)
      .map((u) => {
          const temObj = {};
          temObj.value = u._id;
          temObj.label = `${u.first_name} ${u.last_name}`;
          usrList.push(temObj);
      });
    
    setUserOptions(usrList);
}, [userData]);


  return (
    <div className="bg-[#EBEDF2] p-12 fixed top-0 left-0 w-full h-full z-20">
      <p className="mb-8 text-right">
        <button onClick={closingModel}>
          <img src={closeIcon} alt="" />
        </button>
      </p>

      <div className="flex">
        <div className="w-[40%] text-left">
          <h3 className="text-[24px] text-[#2D3846]">
              {isEdit ? "Edit Approval" : isView ?  "View Approval" : "Add New Approval"}
          </h3>
          <p className="text-[14px] text-[#656F7D]">
              Enter Approval details and add to list
              {isEdit ? " or update approval details" : isView ? " or view approval details" : ""}
          </p>
        </div>
        <div className="w-[60%]">
          <form onSubmit={handleSubmit(onSubmit)} className={isView ? 'view-page' : ''}>
            <div className="bg-white p-5 rounded-md max-h-full overflow-y-auto">
              <h4 className="text-[20px] text-left border-b-2 border-b-[#007AC8] mb-8">
              Approval Details
              </h4>

              <div className="grid grid-cols-2 gap-8 text-left">
                <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      Request Type <span className="text-[red]">*</span>
                    </label>
                    <select
                        className={errors.requestType ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                        {...register("requestType", {
                            required: "Select Request type",
                        })}
                    >
                        <option value="">
                            Select Request Type
                        </option>
                        <option value="grn">GRN</option>
                        <option value="purchase_order">Purchase Order</option>
                        <option value="indent">Indent</option>
                    </select>
                  </div>
                  {errors.requestType && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      {errors.requestType?.message}
                    </p>
                  )}
                </div>

                <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      Approvers {isView ? '' : '(Add multiple approvers)'} <span className="text-[red]">*</span>
                    </label>
                    
                    <Controller
                      {...register("approvers", {
                        required: selectedApprovers.length > 0 ? false : "Select Approvers",
                    })}
                      control={control}
                      render={({ field }) => (
                        <Select
                        mode="multiple"
                        allowClear
                        style={{
                          width: '100%',
                        }}
                        disabled={isView}
                        placeholder="Please select"
                        defaultValue={selectedApprovers}
                        onChange={handleChange}
                        options={userOptions}
                        className={errors.approvers ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded text-[#2D3846]' : 'text-[16px] border-[#C0C3CC] font-semibold w-full rounded text-[#2D3846]'}
                      />
                      )}
                  />

                  </div>
                  {errors.approvers && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      {errors.approvers?.message}
                    </p>
                  )}
                </div>

                <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      Approval Type <span className="text-[red]">*</span>
                    </label>
                    <select
                        className={errors.approvalType ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                        {...register("approvalType", {
                            required: "Select Approval type",
                        })}
                    >
                        <option value="">
                            Select Approval Type
                        </option>
                        <option value="sequential">Sequential</option>
                        <option value="parallel">Parallel</option>
                    </select>
                  </div>
                  {errors.approvalType && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      {errors.approvalType?.message}
                    </p>
                  )}
                </div>

                <div>
                  <div>
                      <label
                          className="text-[#2B384A] text-[12px] block mb-2"
                          htmlFor=""
                      >
                          Details <span className="text-[red]">*</span>
                      </label>
                      <input
                          className={errors.details ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                          type="text"
                          {...register("details", {
                              required:
                                  "Please Add Details",
                          })}
                      />
                  </div>
                  {errors.details && (
                      <p
                          className="text-xs text-[red] mt-1"
                          role="alert"
                      >
                          {errors.details?.message}
                      </p>
                  )}
              </div>

              </div>
            </div>
            {!isView && (
            <p className="text-right mt-8 flex items-center justify-end">
              <button
                onClick={closingModel}
                className="bg-transparent h-[40px] w-[100px] border text-[#007AC8] border-[#007AC8] rounded text-[14px] font-bold mr-3"
              >
                Cancel
              </button>
              <button
                type="Submit"
                className="bg-[#007AC8] h-[40px] w-[100px] border border-[#007AC8] rounded text-white text-[14px] font-bold uppercase"
                disabled={isLoading}
              >
                {isLoading ? (
                                  <BtnLoader />
                              ) : isEdit ? (
                                  "Update"
                              ) : (
                                  "Create"
                              )}
              </button>
            </p>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}
