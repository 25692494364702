import {
  endPoints,
  getAuthorizationHeader,
  axiosInstance,
} from "../components/api";

export const getCategoryList = async () => {
  const { data: response } = await axiosInstance.get(
    `${endPoints.categories}`,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};

export const addCategory = async (reqBody) => {
  const { data: response } = await axiosInstance.post(
    `${endPoints.categories}`,
    reqBody,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};

export const editCategory = async (cateObj) => {
  const { data: response } = await axiosInstance.put(
    `${endPoints.categories}/${cateObj.id}`,
    cateObj.body,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};

export const deleteCategory = async (catId) => {
  const { data: response } = await axiosInstance.delete(
    `${endPoints.categories}/${catId}`,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};
