import React, { Fragment, useEffect, useState } from "react";
import infoIcon from "../../assets/image/blue-info.svg";
import whitePlus from "../../assets/image/white-plus.svg";
import { useQuery } from "@tanstack/react-query";
import {
    getUserProfileList,
    addUserProfile,
    editUserProfile,
    deleteUserProfile,
} from "../../queries/UserProfiles";
import AddProfile from "./AddProfile";
import { Loader } from "../../components/utils/Loader";
import { useQueryClient } from "@tanstack/react-query";
import SearchList from "../../components/SearchList";
import { useFilteredList } from "../../hooks/useFilteredList";
import { Table } from "antd";
import {useQueryMutation} from '../../hooks/useMutation';
import { ProfileColumns } from "../../utils/colConfig";
import { getOrganizationList } from "../../queries/Organization";
import useModulePermissions from "../../hooks/useModulePermissions";

const filterByName = (list, searchValue) => {
    const trimmedSearchVal = searchValue.trim().toLowerCase();
    return list.filter((item) => {
        return item.name.toLowerCase().includes(trimmedSearchVal);
    });
};

export default function ProfileList() {
    const userData = JSON.parse(localStorage.getItem("loggedUser"));
    const permissions = useModulePermissions(userData);
    const queryClient = useQueryClient();
    const [showModel, setShowModel] = useState(false);
    const [isView, setIsView] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [profileData, setProfileData] = useState({});
    
    const { isLoading, data } = useQuery({
        queryKey: ["profiles"],
        queryFn: getUserProfileList,
    });
    const { data: orgData } = useQuery({
        queryKey: ["organization"],
        queryFn: getOrganizationList,
    });

    const {
        filteredList: profileList,
        searchVal,
        handleSubmit,
        handleChange,
    } = useFilteredList(data?.result, filterByName);

    
    const deleteUserProfileList = useQueryMutation(deleteUserProfile, queryClient, 'profiles');
    const addUserProfileList = useQueryMutation(addUserProfile, queryClient, 'profiles');
    const editUserProfileList = useQueryMutation(editUserProfile, queryClient, 'profiles');

    const closeModel = () => {
        setShowModel(false);
        setIsView(false);
        setIsEdit(false);
    };

    const deleProfile = (id) => {
        deleteUserProfileList.mutate(id);
    };

    const edProfile = (obj) => {
        editUserProfileList.mutate(obj);
    };

    const adProfile = (obj) => {
        addUserProfileList.mutate(obj)
    };

    const settingIsEdit = (editVisible, profile) => {
        setShowModel(true);
        setProfileData(profile);
        setIsEdit(editVisible);
    };

    

    const columns = ProfileColumns(deleProfile, edProfile, orgData, isLoading, settingIsEdit);

    if (isLoading) return <Loader />;

    return (
        <div>
            {data.result && data.result.length > 0 ? (
                <Fragment>
                    <div className="flex justify-between mb-5">
                        <SearchList
                            searchVal={searchVal}
                            handleChange={handleChange}
                            handleSubmit={handleSubmit}
                            placeHolder={"Search Profile Name..."}
                        />
                        {permissions.showUserProfilesMenu !== undefined && permissions.showUserProfilesMenu.write && (
                            <button
                            onClick={() => setShowModel(true)}
                            className="primary btn flex items-center justify-center text-[#fff] text-[14px] font-bold"
                        >
                            <img src={whitePlus} alt="" />
                            <span className="ml-3">Add New Profile</span>
                        </button>
                        )}
                        
                    </div>
                    <Table
                        pagination={{ pageSize: 6 }}
                        className="w-full text-left"
                        dataSource={profileList}
                        onRow={(record, rowIndex) => {
                            return {
                              onClick: () => {setProfileData(record); setShowModel(true); setIsView(true)},
                            };
                        }}
                        columns={columns}
                    />
                </Fragment>
            ) : (
                <div className="text-center">
                    <img className="mx-auto" src={infoIcon} alt="Info Icon" />
                    <h4 className="text-[20px] text-[#2D3846] font-bold">
                        No data available
                    </h4>
                    <p className="text-[14px] text-[#656F7D] mb-12">
                        You have not added/created any Profile here
                    </p>
                    {permissions.showUserProfilesMenu !== undefined && permissions.showUserProfilesMenu.write && (
                        <p className="flex justify-center">
                        <button
                            onClick={() => setShowModel(true)}
                            className="primary btn flex items-center justify-center text-[#fff] text-[14px] font-bold"
                        >
                            <img src={whitePlus} alt="" />
                            <span className="ml-3">Add New Profile</span>
                        </button>
                    </p>
                    )}
                    
                </div>
            )}

            {showModel && permissions.showUserProfilesMenu !== undefined && permissions.showUserProfilesMenu.write && (
                <AddProfile
                    closeModel={closeModel}
                    adProfile={adProfile}
                    isLoading={isLoading}
                    profile={profileData}
                    isView={isView}
                    isEdit={isEdit}
                    settingIsEdit={settingIsEdit}
                    edProfile={edProfile}
                />
            )}
        </div>
    );
}
