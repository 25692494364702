import React, { useState } from "react";
import actionIcon from "../../assets/image/blue-action.svg";
import editIcon from "../../assets/image/edit-blue.svg";
import delIcon from "../../assets/image/delete-blue.svg";
import { useClickAway } from "@uidotdev/usehooks";
import { getProjectList } from '../../queries/Projects';
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import useModulePermissions from '../../hooks/useModulePermissions';
import AddIndent from "./AddIndent";

export default function IndentListTable(props) {
  const userData = JSON.parse(localStorage.getItem("loggedUser"));
  const permissions = useModulePermissions(userData);
  const { indent, isLoading } = props;
  const [showAction, setShowAction] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);

  const { data:ProjList } = useQuery({
    queryKey: ["project"],
    queryFn: getProjectList,
  });

  const ref = useClickAway(() => {
    setShowAction(false);
  });

  const deletingIndent = (catId) => {
    props.deleIndent(catId);
  }

  const closeModel = () => {
    setShowModel(false);
    setShowAction(false);
    setIsView(false);
    setIsEdit(false);
  }

  const editingIndnt = (obj) => {
    props.edIndent(obj)
  }

  return (
    <>
    {permissions.showIndentMenu !== undefined && (permissions.showIndentMenu.update || permissions.showIndentMenu.delete) && (
      <span
      onClick={() => setShowAction(!showAction)}
      className="p-3 cursor-pointer flex justify-end"
    >
      <img src={actionIcon} alt="" />
    </span>
    )}
      
        {showAction && (
          <div className="absolute top-[10px] right-[30px] border border-solid border-[#D9D9D9] rounded z-10" ref={ref}>
            <ul className="w-[135px]">
            <li className="text-[12px] cursor-pointer px-3 py-2 flex bg-white hover:bg-[#F5F5F5] rounded-t items-center border-b border-solid border-[#D9D9D9]" onClick={() =>{setShowModel(true);setIsView(true)}}>
                  <span className="pr-2"><img src={editIcon} alt="" /></span>
                  <span>View</span>
                </li>
              {permissions.showIndentMenu !== undefined && permissions.showIndentMenu.update && (
                <li className="text-[12px] cursor-pointer px-3 py-2 flex bg-white hover:bg-[#F5F5F5] rounded-t items-center border-b border-solid border-[#D9D9D9]" onClick={() =>{setShowModel(true); setIsEdit(true)}}>
                  <span className="pr-2"><img src={editIcon} alt="" /></span>
                  <span>Edit</span>
                </li>
              )}
              {permissions.showIndentMenu !== undefined && permissions.showIndentMenu.delete && (
                <li
                onClick={() => deletingIndent(indent._id)}
                className="text-[12px] px-3 py-2 cursor-pointer rounded-b bg-white hover:bg-[#F5F5F5] flex items-center"
              >
                <span className="pr-2"><img src={delIcon} alt="" /></span>
                <span>Delete</span>
              </li>
              )}
              
            </ul>
          </div>
        )}
      {showModel && <AddIndent isEdit={isEdit} isView={isView} closeModel={closeModel} indent={indent} editingIndnt={editingIndnt} isLoading={isLoading} />}
      </>
  );
}
