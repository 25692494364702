import {
  endPoints,
  getAuthorizationHeader,
  axiosInstance,
} from "../components/api";

export const getUserList = async () => {
  const { data: response } = await axiosInstance.get(`${endPoints.users}`, {
    headers: { Authorization: getAuthorizationHeader() },
  });
  return response;
};

export const addUser = async (reqBody) => {
  const { data: response } = await axiosInstance.post(
    `${endPoints.users}`,
    reqBody,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};

export const editUser = async (userObj) => {
  const { data: response } = await axiosInstance.put(
    `${endPoints.users}/${userObj.id}`,
    userObj.body,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};

export const deleteUser = async (userId) => {
  const { data: response } = await axiosInstance.delete(
    `${endPoints.users}/${userId}`,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};
