import React from 'react';
import MaterialList from './MaterialList';

export default function Materials() {
  return (
    <div className='bg-white p-8'>
        <MaterialList />
    </div>
  )
}
