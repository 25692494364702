import { useState, useEffect } from 'react';

export const useFilteredList = (initialData, filterFunction) => {
  const [filteredList, setFilteredList] = useState(initialData);
  const [searchVal, setSearchVal] = useState('');

  useEffect(() => {
    setFilteredList(initialData);
  }, [initialData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let filteredData = filterFunction(initialData, searchVal);
    setFilteredList(filteredData);
  }

  const handleChange = (e) => {
    setSearchVal(e.target.value);
    if(e.target.value === '' && filteredList !== initialData){
      setFilteredList(initialData)
    }
  }

  return { filteredList, searchVal, handleSubmit, handleChange };
}