import React, { useState, useEffect } from "react";
import { Switch, Cascader, Checkbox } from "antd";

export default function ModulePermission(props) {
  const arrOptions = [
    { label: "Read", value: "read", defaultChecked: true },
    { label: "Write", value: "write", defaultChecked: true },
    { label: "Update", value: "update", defaultChecked: true },
    { label: "Delete", value: "delete", defaultChecked: true },
]
    const { module, moduleList, modueName, isEdit, isView } = props;
    const { SHOW_CHILD } = Cascader;
    const [disableCascade, setDisableCascade] = useState(false);
    const [disablePublicAccess, setDisablePublicAccess] = useState(false);
    const [options, setOptions] = useState(arrOptions);
    const [isPublicAccess, setIsPublicAccess] = useState(module.public_access);
    const [checkedValues, setCheckedValues] = useState([["read"], ["write"], ["update"], ["delete"]].filter(permission => module[permission] === true ? [permission] : null));
    const [isSwichChecked, setIsSwichChecked] = useState(false);


    
    

    const onSwitchChange = (checked) => {
        setIsSwichChecked(checked);
        setDisableCascade(!checked);
        setDisablePublicAccess(!checked);
        setIsPublicAccess(checked);
        setCheckedValues(checked ? [["read"], ["write"], ["update"], ["delete"]] : []);
        if (module.moduleName === modueName) {
            module.public_access = checked;
            module.delete = checked;
            module.read = checked;
            module.update = checked;
            module.write = checked;
        }

        if (checked) {
            setOptions(arrOptions);
        }
    };
    const onCascaderChange = (value) => {
        const flatValue = value.flat();
        setCheckedValues(value);
        console.log(flatValue);

        if(flatValue.length <= 0){
            setCheckedValues([]);
            setIsSwichChecked(false);
            const tmpOptions = arrOptions.map((option) => {
                return {
                    ...option,
                    disabled: true,
                };
            })
            if (module.moduleName === modueName) {
                module.public_access = false;
                module.delete = false;
                module.read = false;
                module.update = false;
                module.write = false;
            }
            setOptions(tmpOptions);
        }
        if((!flatValue.includes('read'))){
            setCheckedValues([]);
            setIsSwichChecked(false);
            const tmpOptions = arrOptions.map((option) => {
                return {
                    ...option,
                    disabled: true,
                };
            })
            if (module.moduleName === modueName) {
                module.public_access = false;
                module.delete = false;
                module.read = false;
                module.update = false;
                module.write = false;
            }
            setOptions(tmpOptions);
        }
        if(!flatValue.includes('write') && flatValue.includes('read') && flatValue.includes('update') && flatValue.includes('delete')){
            setCheckedValues([["read"]]);
            const tmpOptions = arrOptions.map((option) => {
                if(option.value === 'update' || option.value === 'delete' || option.value === 'write'){
                    return {
                        ...option,
                        disabled: true,
                    };
                }
                return option;
            })
            if (module.moduleName === modueName) {
                module.delete = false;
                module.read = true;
                module.update = false;
                module.write = false;
            }
            setOptions(tmpOptions);
        }
        if(!flatValue.includes('update') && !flatValue.includes('write') && !flatValue.includes('delete')){
            setCheckedValues([["read"]]);
            const tmpOptions = arrOptions.map((option) => {
                if(option.value === 'update' || option.value === 'delete' || option.value === 'write'){
                    return {
                        ...option,
                        disabled: true,
                    };
                }
                return option;
            })
            if (module.moduleName === modueName) {
                module.delete = false;
                module.read = true;
                module.update = false;
                module.write = false;
            }
            setOptions(tmpOptions);
        }
        if(!flatValue.includes('update') && flatValue.includes('read') && flatValue.includes('write') && flatValue.includes('delete')){
            setCheckedValues([["read"], ["write"]]);
            const tmpOptions = arrOptions.map((option) => {
                if(option.value === 'delete' || option.value === 'update'){
                    return {
                        ...option,
                        disabled: true,
                    };
                }
                return option;
            })
            if (module.moduleName === modueName) {
                module.delete = false;
                module.read = true;
                module.update = false;
                module.write = true;
            }
            setOptions(tmpOptions);
        }
        if(!flatValue.includes('delete') && flatValue.includes('read') && flatValue.includes('write') && flatValue.includes('update')){
            setCheckedValues([["read"], ["write"], ["update"]]);
            const tmpOptions = arrOptions.map((option) => {
                if(option.value === 'delete'){
                    return {
                        ...option,
                        disabled: true,
                    };
                }
                return option;
            })
            if (module.moduleName === modueName) {
                module.delete = false;
                module.read = true;
                module.update = true;
                module.write = true;
            }
            setOptions(tmpOptions);
        }
        if(!flatValue.includes('delete') && !flatValue.includes('write') && !flatValue.includes('update')){
            setCheckedValues([["read"]]);
            const tmpOptions = arrOptions.map((option) => {
                if(option.value === 'update' || option.value === 'delete' || option.value === 'write'){
                    return {
                        ...option,
                        disabled: true,
                    };
                }
                return option;
            })
            setOptions(tmpOptions);
            if (module.moduleName === modueName) {
                module.delete = false;
                module.read = true;
                module.update = true;
                module.write = true;
            }
        }
       if(!flatValue.includes('delete') && !flatValue.includes('read') && flatValue.includes('write') && flatValue.includes('update')){
        setCheckedValues([]);
        setIsSwichChecked(false);
        const tmpOptions = arrOptions.map((option) => {
            return {
                ...option,
                disabled: true,
            };
        })
        if (module.moduleName === modueName) {
            
            module.delete = false;
            module.read = false;
            module.update = true;
            module.write = true;
        }
        setOptions(tmpOptions);
       }
    };

    const onPublicChange = (e) => {
        if (e.target.checked !== true && module.moduleName === modueName) {
            module.public_access = false;
        }
        setIsPublicAccess(e.target.checked);
    };

  
    useEffect(() => {
        if(!module.read && !module.write && !module.update && !module.delete) {
            setDisableCascade(true);
            setDisablePublicAccess(true);
        }else{
            setIsSwichChecked(true);
        }
    }, []);
    
    return (
        <div className="flex items-center mb-2">
            <label
                className="text-[#2B384A] text-[12px] block mb-2 w-[30%] font-bold"
                htmlFor={module.moduleName}
            >
                {module.moduleName}
            </label>

            <div className="permission">
            <Switch
                    id={module.moduleName}
                    size="small"
                    checked={isSwichChecked}
                    onChange={onSwitchChange}
                    disabled={isView}
                />
                <Cascader
                    options={options}
                    disabled={isView ?? disableCascade}
                    onChange={(field) => {
                        onCascaderChange(field);
                        // Do something when a checkbox is checked or unchecked
                        console.log('Checkbox selection changed:', field);
                    }}
                    multiple
                    showCheckedStrategy={SHOW_CHILD}
                    maxTagCount="responsive"
                    value={checkedValues}
                    className="profile-cascader"
                />
                <Checkbox
                    className="ml-5"
                    disabled={isView ?? disablePublicAccess}
                    checked={isPublicAccess}
                    onChange={onPublicChange}
                >
                    Public Access
                </Checkbox>
            </div>
        </div>

    );
}
