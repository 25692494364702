import React, { useState, useEffect } from "react";
import closeIcon from "../../assets/image/blue-cross.svg";
import { Controller, useForm } from "react-hook-form";
import { BtnLoader } from "../../components/utils/Loader";
import { getIndentList } from "../../queries/Indent";
import { getProjectList } from "../../queries/Projects";
import { getVendorList } from "../../queries/Vendors";
import { useQuery } from "@tanstack/react-query";
import DatePicker from "react-datepicker";
import { Modal, Table, Select, InputNumber } from "antd";
import AssignMaterial from "./AssignMaterial";
import moment from "moment";
import {
  fright_terms,
  payment_terms,
  payment_mode,
} from "../../components/constVar";
import { IndianRupee } from "lucide-react";
import toast from "react-hot-toast";
import useSelection from "../../hooks/useSelection";

export default function AddPurchase(props) {
  const { data: indentData } = useQuery({
    queryKey: ["indent"],
    queryFn: getIndentList,
  });
  const { data: projectData } = useQuery({
    queryKey: ["project"],
    queryFn: getProjectList,
  });
  const { data: vendorData } = useQuery({
    queryKey: ["vendor"],
    queryFn: getVendorList,
  });
  const {
    purchase,
    isLoading,
    isView,
    isEdit,
    closeModel,
    edPurchase,
    adPurchase,
  } = props;
  const [reqDate, setReqDate] = useState(
    isEdit || isView ? new Date(purchase?.required_date) : ""
  );
  const [quoteDate, setQuoteDate] = useState(
    isEdit || isView ? new Date(purchase?.quot_date) : ""
  );
  const [showFilteredProj, setShowFilteredProj] = useState(
    isEdit || isView
      ? purchase.indent_number
      : []
  );
  const [selectingMat, setSelectingMat] = useState(
    isEdit || isView ? purchase.materials : []
  );
  const [projCode, setProjCode] = useState(
    isEdit || isView ? purchase?.project_code : ""
  );
  const [taxType, setTaxType] = useState(
    isEdit || isView ? purchase.tax_type : "GST"
  );
  const [priority, setPriority] = useState(
    isEdit || isView ? purchase.priority : null
  );
  const [selectedVendor, setSelectedVendor] = useState(
    isEdit || isView ? purchase.vendor_id : ""
  );
  const [curIndent, setCurIndent] = useState(
    isEdit || isView
      ? indentData?.result?.filter((i) => i._id === purchase.indent_id)[0]?.name
      : ""
  );
  const [currVendor, setCurrVendor] = useState(
    isEdit || isView ? vendorData?.result?.filter((v) => v._id === purchase.vendor_id)[0] : ""
  );
  const [allowSubmit, setAllowSubmit] = useState(true);
  const [projId, setProjId] = useState(
    isEdit || isView ? purchase.project_id : ""
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const userData = JSON.parse(localStorage.getItem("loggedUser"));
  const closingModel = () => {
    closeModel();
  };

  const {
    register,
    setValue,
    clearErrors,
    formState: { errors },
    control,
    handleSubmit,
  } = useForm(
    isEdit || isView
      ? {
          defaultValues: {
            indent_number: purchase.indent_id,
            project_code: purchase?.project_code,
            vendor_id: purchase?.vendor_id,
            freight_terms: purchase?.freight_terms,
            payment_terms: purchase?.payment_terms,
            payment_mode: purchase?.payment_mode,
            quot_ref: purchase?.quot_ref,
            quot_date: purchase?.quot_date,
            required_date: purchase?.required_date,
            tax_type: purchase?.tax_type,
            status: purchase?.status,
          },
        }
      : {
          defaultValues: {
            indent_number: "",
            project_code: "",
            vendor_id: "",
            freight_terms: "",
            payment_terms: "",
            payment_mode: "",
            quot_ref: "",
            quot_date: "",
            required_date: "",
            tax_type: "",
            status: "",
          },
        }
  );

  const { options: vendorOption } = useSelection(
    vendorData,
    isEdit ? purchase.vendor_id : undefined
  );
  const { options: indentOption } = useSelection(
    indentData,
    isEdit ? purchase.indent_id : undefined
  );

  console.log(indentOption);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "15%",
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      width: "10%",
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
      width: "10%",
    },

    {
      title: "Rate",
      dataIndex: "cost",
      key: "cost",
      render: (text, record) => {
        return (
          <p className="flex items-center">
            <span>
              <IndianRupee size={13} />
            </span>{" "}
            <InputNumber
              defaultValue={text}
              disabled={isView}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              onChange={(e) => onRateChange(text, record, e)}
            />
          </p>
        );
      },
      width: "10%",
    },
    {
      title: "QTY",
      dataIndex: isEdit || isView ? "quantity" : "material_id",
      key: "qty",
      render: (text, row) => (
        <InputNumber
          min={1}
          max={100000}
          defaultValue={isEdit || isView ? text : "1"}
          disabled={isView}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          onChange={(e) => {
            changeQty(row, e);
          }}
        />
        // <input type="number" defaultValue={isEdit || isView ? text : ''} min={1} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" onChange={(e) => {changeQty(text, row, e)}} />
      ),
      width: "15%",
    },
    {
      title: "Amount",
      dataIndex: "material_id",
      key: "amount",
      render: (_text, record) => {
        return (
          <p className="flex items-center">
            <span>
              <IndianRupee size={13} />
            </span>{" "}
            {isNaN(record.cost * record.quantity)
              ? record.cost * 1
              : record.cost * record.quantity}
          </p>
        );
      },
      shouldCellUpdate: (record, prevRecord) => record === prevRecord,
      width: "10%",
    },
    ...(taxType === "GST"
      ? [
          {
            title: "GST %",
            dataIndex: isEdit || isView ? "tax_percentage" : "material_id",
            key: "gst%",
            render: (text, row) => (
              <InputNumber
                type="number"
                defaultValue={isEdit || isView ? text : "12"}
                disabled={isView}
                min={1}
                max={100}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                onChange={(e) => {
                  changeGst(row, e);
                }}
              />
            ),
            width: "10%",
          },
          {
            title: "SGST",
            dataIndex: "material_id",
            key: "sgst",
            render: (_text, record) => {
              return (
                <p className="flex items-center">
                  <span>
                    <IndianRupee size={13} />
                  </span>
                  {(record.cost *
                    (record.quantity ? record.quantity : 1) *
                    (record.tax_percentage ? record.tax_percentage : 12)) /
                    100 /
                    2}
                </p>
              );
            },
            shouldCellUpdate: (record, prevRecord) =>
              record === prevRecord || record !== prevRecord,
            width: "10%",
          },
          {
            title: "CGST",
            dataIndex: "material_id",
            key: "cgst",
            render: (_text, record) => {
              return (
                <p className="flex items-center">
                  <span>
                    <IndianRupee size={13} />
                  </span>{" "}
                  {(record.cost *
                    (record.quantity ? record.quantity : 1) *
                    (record.tax_percentage ? record.tax_percentage : 12)) /
                    100 /
                    2}
                </p>
              );
            },
            shouldCellUpdate: (record, prevRecord) =>
              record === prevRecord || record !== prevRecord,
            width: "10%",
          },
        ]
      : [
          {
            title: "IGST %",
            dataIndex: isEdit || isView ? "tax_percentage" : "material_id",
            key: "igst%",
            render: (text, row) => (
              <InputNumber
                type="number"
                defaultValue={isEdit || isView ? text : "12"}
                disabled={isEdit || isView}
                min={1}
                max={100}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                onChange={(e) => {
                  changeIgst(row, e);
                }}
              />
            ),
            width: "10%",
          },
          {
            title: "IGST",
            dataIndex: "material_id",
            key: "igst",
            render: (_text, record) => {
              return (
                <p className="flex items-center">
                  <span>
                    <IndianRupee size={13} />
                  </span>{" "}
                  {(record.cost *
                    (record.quantity ? record.quantity : 1) *
                    (record.tax_percentage ? record.tax_percentage : 12)) /
                    100}
                </p>
              );
            },
            shouldCellUpdate: (record, prevRecord) => record === prevRecord,
            width: "10%",
          },
        ]),
    {
      title: "Total",
      dataIndex: "material_id",
      key: "total",
      render: (_text, record) => {
        return (
          <p className="font-bold flex items-center">
            <span>
              <IndianRupee size={13} />
            </span>
            {(
              record.cost * (record.quantity ? record.quantity : 1) +
              (record.cost *
                (record.quantity ? record.quantity : 1) *
                (record.tax_percentage ? record.tax_percentage : 12)) /
                100
            ).toFixed(2)}
          </p>
        );
      },
      shouldCellUpdate: (record, prevRecord) => record === prevRecord,
      width: "10%",
    },
  ];

  const changeQty = (row, e) => {
    const qtyMat = selectingMat.map((s) => {
      if (row.material_id === s.material_id) {
        const boqQty = curIndent?.materials.filter(
          (m) => m.material_id === s.material_id
        )[0]?.quantity;
        if (boqQty < e) {
          toast.error("Quantity should not be greater than BOQ Quantity!!!");
          setAllowSubmit(false);
        } else {
          setAllowSubmit(true);
        }
        s["quantity"] = Number(e);
        s["price"] = Number(e * s.cost);
      }
    });
    return qtyMat;
  };
  const onRateChange = (text, row, e) => {
    const qtyMat = selectingMat.map((s) => {
      if (row.material_id === s.material_id) {
        s["cost"] = e;
        s["price"] = e * (s.quantity ? s.quantity : 1);
      }
    });
    console.log(qtyMat);
    return qtyMat;
  };
  const changeGst = (row, e) => {
    const gstMat = selectingMat.map((s) => {
      if (row.material_id === s.material_id) {
        s["tax_percentage"] = e;
        s["tax_details"] = [
          {
            type: "CGST",
            percentage: Math.abs(e / 2),
            amount: Math.abs((s.price * e) / 100 / 2),
          },
          {
            type: "SGST",
            percentage: Math.abs(e / 2),
            amount: Math.abs((s.price * e) / 100 / 2),
          },
        ];
        s["total_tax"] = e;
        s["total"] = Math.abs(s.price + (s.price * e) / 100);
      }
    });
    return gstMat;
  };
  const changeIgst = (row, e) => {
    const igstMat = selectingMat.map((s) => {
      if (row.material_id === s.material_id) {
        s["tax_percentage"] = e;
        s["tax_details"] = [
          {
            type: "IGST",
            percentage: e,
            amount: Math.round((s.price * e) / 100),
          },
        ];
        s["total_tax"] = e;
        s["total"] = Math.round(s.price + (s.price * e) / 100);
      }
    });

    return igstMat;
  };

  const handleVendorChange = (value) => {
    setSelectedVendor(value);
  };

  const showModal = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onSubmit = (data, e) => {
    e.preventDefault();
    if (allowSubmit) {
      const payload = {
        indent_number: curIndent?.indent_number,
        tax_type: taxType,
        quot_ref: data.quot_ref,
        org_id: userData.org_id,
        project_id: projId,
        priority: priority !== null && priority ? "High" : "Medium",
        vendor_id: selectedVendor,
        fob_point: data.fob_point,
        freight_terms: data.freight_terms,
        payment_mode: data.payment_mode,
        payment_terms: data.payment_terms,
        project_code: projCode,
        quot_date: moment(quoteDate).format("MM-DD-YYYY"),
        required_date: moment(reqDate).format("MM-DD-YYYY"),
        materials: selectingMat,
      };

      if (selectingMat.length > 0) {
        if (isEdit) {
          const edPayload = {
            id: purchase._id,
            body: payload,
          };
          edPurchase(edPayload);
        } else {
          adPurchase(payload);
          //console.log(payload);
        }
        closingModel();
      } else {
        toast.error("Please Assign Materials!!!");
      }
    } else {
      toast.error(
        "Quantity should not be greater than BOQ Quantity, reduce the Quantity!!!"
      );
    }
  };


  const addIndentMaterial = (selectRows) => {
    setSelectingMat(selectRows);
    setIsModalOpen(false);
  };

  const changePriority = (e) => {
    setPriority(e.target.checked);
  };

  const changeIndent = (e) => {
    const currentIndent = indentData?.result?.filter((i) => i._id === e);
    setCurIndent(currentIndent[0]);
    let matList = [];
    setProjId(currentIndent[0]?.project_id);
    setProjCode(
      projectData?.result?.filter(
        (p) => p._id === currentIndent[0]?.project_id
      )[0]?.project_code
    );
    //const filteredProjMat = currentIndent[0]?.materials;
    if (currentIndent[0]?.materials.length > 0) {
      currentIndent[0]?.materials.map((u) => {
        const temObj = {};
        temObj.material_id = u.material_id;
        temObj.name = u?.name;
        temObj.boq_no = u?.boq_no;
        temObj.description = u?.description;
        temObj.unit = u.unit;
        temObj.model = u.model;
        temObj.brand = u.brand;
        temObj.cost = u.cost;
        matList.push(temObj);
      });
      setShowFilteredProj(matList);
    }
  };

  useEffect(() => {
    if (selectingMat.length > 0 && !isEdit) {
      selectingMat.map((s) => {
        onRateChange(s, s.cost);
        changeIgst(s, 12);
        changeGst(s, 12);
        changeQty(s, 1);
      });
     
      console.log('isnot edit')
    }
  }, [selectingMat]);

  return (
    <div className="bg-[#EBEDF2] p-12 fixed top-0 left-0 w-full h-full z-20">
      <p className="mb-8 text-right">
        <button onClick={closingModel}>
          <img src={closeIcon} alt="" />
        </button>
      </p>

      <div className="flex">
        <div className="w-[20%] text-left">
          <h3 className="text-[24px] text-[#2D3846]">Add New Purchase Order</h3>
          <p className="text-[14px] text-[#656F7D]">
            Enter Purchase details and add to list
          </p>
        </div>
        <div className="w-[80%]">
          <form onSubmit={handleSubmit(onSubmit)} className={isView ? 'view-page' : ''}>
            <div className="bg-white p-5 rounded-md max-h-[70vh] overflow-y-auto">
              <h4 className="text-[20px] text-left border-b-2 border-b-[#007AC8] mb-8">
                Purchase Order Details
              </h4>
              <div className="flex justify-end">
                <div class="flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700 w-48">
                  <input
                    {...register("priority")}
                    onChange={changePriority}
                    defaultChecked={
                      (isEdit || isView) && purchase.priority === "High"
                    }
                    id="bordered-checkbox-2"
                    disabled={isView}
                    type="checkbox"
                    value=""
                    name="bordered-checkbox"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for="bordered-checkbox-2"
                    class="w-full font-bold py-4 ms-2 text-sm text-gray-900 dark:text-gray-300"
                  >
                    High Priority PO
                  </label>
                </div>
              </div>

              <div className="grid grid-cols-3 gap-8 text-left mb-8">
                <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      Indent <span className="text-[red]">*</span>
                    </label>

                    <Controller
                      {...register("indent_number", {
                        required: "Select Indent Number",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          allowClear
                          className={errors.indent_number ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                          style={{
                            width: "100%",
                          }}
                          defaultValue={
                            isEdit || isView
                              ? purchase.indent_number
                              : ""
                          }
                          placeholder="Please select"
                          onChange={(e) => {
                            changeIndent(e);
                            setValue("indent_number", e);
                            clearErrors("indent_number");
                          }}
                          options={
                            indentData?.result?.map((v) => ({
                              label: v.indent_number,
                              value: v._id,
                            })) || []
                          }
                        />
                      )}
                    />
                  </div>
                  {errors?.indent_number && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      {errors?.indent_number?.message}
                    </p>
                  )}
                </div>

                <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      Project Code
                    </label>
                    <input
                      className="text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                      type="text"
                      disabled
                      value={projCode}
                      {...register("project_code")}
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      Vendor <span className="text-[red]">*</span>
                    </label>

                    <Controller
                      {...register("vendor_id", {
                        required: "Select Vendor Name",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          allowClear
                          style={{
                            width: "100%",
                          }}
                          className={errors.vendor_id ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                          defaultValue={
                            isEdit || isView
                              ? currVendor
                              : []
                          }
                          placeholder="Please select"
                          onChange={(e) => {
                            handleVendorChange(e);
                            setValue("vendor_id", e);
                            clearErrors("vendor_id");
                          }}
                          options={vendorOption}
                        />
                      )}
                    />
                  </div>
                  {errors?.vendor_id && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      {errors?.vendor_id?.message}
                    </p>
                  )}
                </div>

                <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      Freight Terms <span className="text-[red]">*</span>
                    </label>
                    <select
                      className={errors.freight_terms ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                      {...register("freight_terms", {
                        required: "Freight Terms is required",
                      })}
                    >
                      <option value="">Select Freight Terms</option>
                      {fright_terms.map((f, i) => (
                        <option key={i} value={f}>
                          {f}
                        </option>
                      ))}
                    </select>
                  </div>
                  {errors?.freight_terms && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      {errors?.freight_terms?.message}
                    </p>
                  )}
                </div>
                <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      Payment Terms <span className="text-[red]">*</span>
                    </label>
                    <select
                      className={errors.payment_terms ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                      {...register("payment_terms", {
                        required: "Payment Terms is required",
                      })}
                    >
                      <option value="">Select Payment Terms</option>
                      {payment_terms.map((p, i) => (
                        <option key={i} value={p}>
                          {p}
                        </option>
                      ))}
                    </select>
                  </div>
                  {errors?.payment_terms && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      {errors?.payment_terms?.message}
                    </p>
                  )}
                </div>
                <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      Payment Mode <span className="text-[red]">*</span>
                    </label>
                    <select
                      className={errors.payment_mode ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                      {...register("payment_mode", {
                        required: "Payment Mode is required",
                      })}
                    >
                      <option value="">Select Payment Mode</option>
                      {payment_mode.map((p, i) => (
                        <option key={i} value={p}>
                          {p}
                        </option>
                      ))}
                    </select>
                  </div>
                  {errors?.payment_mode && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      {errors?.payment_mode?.message}
                    </p>
                  )}
                </div>

                <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      Ref. Quote <span className="text-[red]">*</span>
                    </label>
                    <input
                      className={errors.quot_ref ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                      type="text"
                      {...register("quot_ref", {
                        required: "Quote No. is required",
                      })}
                    />
                  </div>
                  {errors?.quot_ref && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      {errors?.quot_ref?.message}
                    </p>
                  )}
                </div>

                <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      Quote Date <span className="text-[red]">*</span>
                    </label>
                    <DatePicker
                      className={!quoteDate ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                      selected={quoteDate}
                      onChange={(date) => {
                        setQuoteDate(date);
                      }}
                    />
                  </div>
                  {!quoteDate && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      Please Select valid Quote Date
                    </p>
                  )}
                </div>

                <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      Required Date <span className="text-[red]">*</span>
                    </label>
                    <DatePicker
                      className={!reqDate ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                      selected={reqDate}
                      onChange={(date) => {
                        setReqDate(date);
                      }}
                    />
                  </div>
                  {!reqDate && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      Please Select valid Required Date
                    </p>
                  )}
                </div>

                <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      Tax Type
                    </label>
                    <div className="flex">
                      <p className="flex mr-3">
                        <input
                          {...register("tax_type", {
                            required: "Tax Type is required",
                          })}
                          type="radio"
                          value={"GST"}
                          onChange={() => setTaxType("GST")}
                          defaultChecked={
                            (isEdit || isView) && purchase.tax_type === "GST"
                              ? true
                              : true
                          }
                        />
                        <span className="text-[#2B384A] text-[12px] pl-2">
                          GST
                        </span>
                      </p>
                      <p className="flex">
                        <input
                          {...register("tax_type", {
                            required: "Tax Type is required",
                          })}
                          type="radio"
                          value={"IGST"}
                          onChange={() => setTaxType("IGST")}
                          defaultChecked={
                            (isEdit || isView) && purchase.tax_type === "IGST"
                              ? true
                              : false
                          }
                        />
                        <span className="text-[#2B384A] text-[12px] pl-2">
                          IGST
                        </span>
                      </p>
                    </div>
                  </div>
                  {errors.tax_type && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      {errors.tax_type?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="border-b-2 border-b-[#007AC8] mb-8 pb-2 flex justify-between	">
                <h4 className="text-[20px] text-left">Assign Materials</h4>
                {(showFilteredProj?.length > 0 && !isView) && (
                  <button
                    onClick={showModal}
                    className="bg-transparent h-[32px] w-[150px] border border-[#007AC8] rounded text-[#007AC8] text-[12px] font-bold uppercase"
                  >
                    Add Material
                  </button>
                )}
              </div>
              {selectingMat.length > 0 && (
                <Table
                  pagination={{ pageSize: 6 }}
                  rowKey={(record) => record.material_id}
                  className="w-full text-left"
                  dataSource={selectingMat}
                  columns={columns}
                />
              )}

              <Modal
                title="Assign Material"
                open={isModalOpen}
                cancelButtonProps={{ style: { display: "none" } }}
                onOk={handleOk}
                onCancel={handleCancel}
                width={"80%"}
              >
                <AssignMaterial
                  open={isModalOpen}
                  addIndentMaterial={addIndentMaterial}
                  materialList={showFilteredProj}
                />
              </Modal>
            </div>
            {!isView && (
              <p className="text-right mt-8 flex items-center justify-end">
              <button
                onClick={closingModel}
                className="bg-transparent h-[40px] w-[100px] border text-[#007AC8] border-[#007AC8] rounded text-[14px] font-bold mr-3"
              >
                Cancel
              </button>
              <button
                type="Submit"
                className="bg-[#007AC8] h-[40px] w-[100px] border border-[#007AC8] rounded text-white text-[14px] font-bold uppercase"
                disabled={isLoading}
              >
                {isLoading ? <BtnLoader /> : isEdit ? "Update" : "Create"}
              </button>
            </p>
            )}
            
          </form>
        </div>
      </div>
    </div>
  );
}
