import React, {useEffect, useState} from "react";
import userIcon from '../assets/image/user-profile.png';
import { io } from 'socket.io-client';
import {
  baseURL
} from "../components/api";
import { useQuery } from "@tanstack/react-query";
import { getNotificationList } from "../queries/Notifications";
import moment from 'moment';
import { Empty } from "antd";
import { NavLink } from "react-router-dom";

const socket = io(baseURL);

export default function Header() {
    const userData = JSON.parse(localStorage.getItem("loggedUser"));
    const { isLoading, data } = useQuery({
        queryKey: ["notification"],
        queryFn: getNotificationList,
    });
    const [notificationList, setNotificationList] = useState([]);
    const [showNotification, setShowNotification] = useState(false);

   
    useEffect(() => {
        socket.on('connect', () => {
            let userId  = userData._id;
            if (!userId) return;
            socket.emit('join',socket.id,userId ,true);
        });
    
        socket.on('notifications', (data) => {
            if(data?.result?.length > 0){
                const filterNotification = data?.result?.filter((item) => item.read === null);
                setNotificationList(filterNotification);
            }
        });

        if(data?.result?.length > 0){
            const filterNotification = data?.result?.filter((item) => item.read === null);
            setNotificationList(filterNotification);
        }else{
            setNotificationList([]);
        }
        
        // Clean up the socket connection when the component unmounts
        // return () => { deliveredmessage readmessage
        //   socket.off('notification');
        // };
      }, [data]);
    return (
        <header className="flex justify-end relative">
            <ul className="flex">

                {/* <li className="bg-white w-[40px] h-[40px] rounded-full flex justify-center items-center mr-3">
                    <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M1.95815 14.433C1.50702 13.6072 0.975535 12.8637 0.671553 12.036C-0.55212 8.70768 -0.0535515 5.62623 2.29698 2.94364C4.25157 0.717023 6.79379 -0.209445 9.73099 0.0393558C13.3284 0.34721 16.4457 3.06078 17.3402 6.58271C17.643 7.75346 17.7008 8.97412 17.5099 10.1682C17.3191 11.3624 16.8837 12.5042 16.2311 13.5222C15.5785 14.5403 14.7227 15.4126 13.7173 16.0846C12.7119 16.7565 11.5786 17.2137 10.3883 17.4273C9.81111 17.5255 9.22642 17.5732 8.64091 17.5696C6.12387 17.5851 3.60682 17.5851 1.08977 17.5696C0.821608 17.5696 0.503105 17.499 0.298837 17.3421C-0.110668 17.0285 -0.0884014 16.4902 0.298837 16.0972C0.8613 15.5124 1.44119 14.9471 1.95815 14.433ZM3.08017 15.7089L3.13923 15.8135H3.39093C5.12382 15.8135 6.85768 15.8513 8.58864 15.797C9.40765 15.7767 10.2208 15.6531 11.0089 15.4292C14.5327 14.3894 16.648 10.4115 15.5976 6.89154C14.0855 1.82259 8.03198 0.0577488 4.11701 3.59033C2.04722 5.45779 1.38697 7.83736 2.03076 10.5509C2.31538 11.7494 2.92818 12.7776 3.77333 13.6779C4.25738 14.1871 4.23318 14.6043 3.73461 15.1029C3.52937 15.314 3.3009 15.5076 3.08017 15.7089Z"
                            fill="#007AC8"
                        />
                        <path
                            d="M6.19548 8.77638C6.19739 8.95085 6.14745 9.12196 6.052 9.26802C5.95654 9.41407 5.81986 9.52849 5.65929 9.59677C5.49872 9.66504 5.32149 9.6841 5.15007 9.65152C4.97866 9.61894 4.82078 9.5362 4.69645 9.41377C4.57212 9.29135 4.48695 9.13477 4.45173 8.96387C4.41651 8.79298 4.43283 8.61548 4.49862 8.45387C4.56441 8.29227 4.6767 8.15384 4.82127 8.05614C4.96583 7.95844 5.13615 7.90587 5.31064 7.90509C5.54171 7.90918 5.76238 8.00188 5.92705 8.16403C6.09172 8.32618 6.18782 8.5454 6.19548 8.77638Z"
                            fill="#007AC8"
                        />
                        <path
                            d="M8.8336 9.66124C8.65963 9.66143 8.48951 9.61006 8.34474 9.5136C8.19996 9.41715 8.08702 9.27995 8.02018 9.11934C7.95334 8.95873 7.93561 8.78191 7.96922 8.61122C8.00283 8.44054 8.08628 8.28364 8.20902 8.16036C8.33176 8.03708 8.48828 7.95294 8.65882 7.91858C8.82936 7.88422 9.00625 7.90118 9.16716 7.96731C9.32806 8.03344 9.46576 8.14578 9.56284 8.29013C9.65993 8.43449 9.71205 8.60438 9.71263 8.77834C9.70773 9.0103 9.61366 9.23145 9.44997 9.39586C9.28627 9.56028 9.06553 9.65532 8.8336 9.66124Z"
                            fill="#007AC8"
                        />
                        <path
                            d="M13.2306 8.79189C13.2289 8.96639 13.1754 9.13646 13.077 9.28056C12.9786 9.42466 12.8396 9.53632 12.6777 9.60141C12.5158 9.6665 12.3382 9.68209 12.1674 9.64621C11.9966 9.61033 11.8403 9.5246 11.7183 9.39985C11.5963 9.27511 11.514 9.11696 11.4819 8.94544C11.4497 8.77391 11.4692 8.59671 11.5379 8.43626C11.6065 8.27582 11.7212 8.13934 11.8674 8.0441C12.0136 7.94886 12.1848 7.89915 12.3593 7.90125C12.5911 7.90967 12.8107 8.00679 12.9729 8.17255C13.135 8.33831 13.2273 8.56003 13.2306 8.79189Z"
                            fill="#007AC8"
                        />
                    </svg>
                </li> */}
                <li onClick={() => setShowNotification(!showNotification)} className="bg-white w-[40px] h-[40px] rounded-full flex justify-center items-center mr-3 relative cursor-pointer">
                <svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.29673 3.02036C8.46031 1.18376 8.88444 0.576462 9.98248 0.571564C11.0805 0.566667 11.4958 1.13871 11.7094 3.04289C12.9603 3.35402 14.1063 3.99111 15.0309 4.88928C16.4414 6.2606 17.2035 7.93754 17.2427 9.90343C17.2818 11.8693 17.2427 13.8548 17.2701 15.8295C17.287 16.0936 17.3903 16.3448 17.5639 16.5446C17.993 17.0343 18.4357 17.529 18.9421 17.9335C19.5122 18.388 19.5925 18.9316 19.4593 19.6075H0.536994C0.424349 18.9512 0.451772 18.3723 1.05418 17.9511C1.26477 17.8042 1.40582 17.5593 1.61348 17.4046C2.54892 16.7189 2.85551 15.8373 2.76833 14.6688C2.66058 13.2406 2.78205 11.7959 2.73405 10.3589C2.62336 6.9923 4.92033 3.89409 8.08907 3.09676C8.15176 3.07815 8.21249 3.05072 8.29673 3.02036ZM3.59504 17.7699L3.6979 17.8522H16.3875C16.2972 17.7436 16.1989 17.6418 16.0937 17.5476C15.6137 17.1949 15.506 16.7287 15.5177 16.1439C15.555 14.2502 15.555 12.3565 15.5177 10.4627C15.5152 9.79472 15.4307 9.12954 15.266 8.48215C14.5294 5.7826 11.774 4.17227 9.36538 4.54742C6.28774 5.03033 4.46779 7.2411 4.458 10.3648C4.458 12.1122 4.44722 13.8597 4.458 15.6072C4.47563 16.4417 4.58436 17.2978 3.59504 17.7699Z" fill="#007AC8"/>
<path d="M7.77954 20.8035H12.1962C12.2028 21.373 11.9853 21.9223 11.5906 22.333C11.1959 22.7436 10.6557 22.9827 10.0863 22.9986C8.81979 23.0407 7.88239 22.1699 7.77954 20.8035Z" fill="#007AC8"/>
</svg>
{notificationList.length > 0 && (<span class="flex absolute top-0 end-0 size-3 w-[10px] h-[10px]">
    <span class="animate-ping absolute inline-flex w-full h-full size-full rounded-full bg-red-400 opacity-75 dark:bg-red-600"></span>
    <span class="relative inline-flex rounded-full w-full h-full size-3 bg-red-500"></span>
</span>)}

                </li>
                <li>
                    <img src={userIcon} alt="" />
                </li>
            </ul>
            
            {showNotification && (
                <div class="w-full max-w-xs top-[45px] right-[50px] p-4 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 absolute">
                    <div class="flex items-center justify-between mb-2">
                        <h5 class="text-xl font-bold leading-none text-gray-900 dark:text-white">Notification List</h5>
                        {notificationList.length > 1 && (<NavLink to="/notification" class="text-sm font-medium text-blue-600 hover:underline dark:text-blue-500">
                            View all
                        </NavLink>)}
                    </div>
                    <div class="flow-root">

                        <ul role="list" class="divide-y divide-gray-200 dark:divide-gray-700">
                            {notificationList.length > 0 && notificationList.map(notify => (
                                <NavLink to={`/${notify.type}/${notify.request_id}`} key={notify._id} class="py-3 sm:py-4 cursor-pointer">
                                <div class="flex items-center">
                                    <div class="flex-1 min-w-0">
                                        <p class="text-sm font-medium text-gray-900 truncate dark:text-white">
                                            {notify.message}
                                        </p>
                                        <p class="text-sm text-gray-500 truncate dark:text-gray-400">
                                            Type: {notify.type}
                                        </p>
                                    </div>
                                    <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                        {moment(notify.createdAt).format('MMM-DD')}
                                    </div>
                                </div>
                            </NavLink>
                            ))}
                            {notificationList.length === 0 && (
                                <Empty description={'No Notification List'} />
                            )}
                            
                        </ul>
                    </div>
                </div>
            )}

        </header>
    );
}
