import React, { Fragment, useState } from "react";
import infoIcon from "../../assets/image/blue-info.svg";
import whitePlus from "../../assets/image/white-plus.svg";
import { useQuery } from "@tanstack/react-query";
import {
    getPurchaseList,
    addPurchase,
    editPurchase,
    deletePurchase,
} from "../../queries/PurchaseOrder";
import AddPurchase from "./AddPurchase";
import ImportPurchase from "./ImportPurchase";
import { Loader } from "../../components/utils/Loader";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useQueryMutation } from "../../hooks/useMutation";
import { Table } from "antd";
import { useFilteredList } from "../../hooks/useFilteredList";
import SearchList from "../../components/SearchList";
import useModulePermissions from "../../hooks/useModulePermissions";
import { PurchaseColumns } from "../../utils/colConfig";

const filterByName = (list, searchValue) => {
    const trimmedSearchVal = searchValue.trim().toLowerCase();
    return list.filter((item) => {
        return (
            item.po_number.toLowerCase().includes(trimmedSearchVal) ||
            item.indent_number.toLowerCase().includes(trimmedSearchVal)
        );
    });
};

export default function PurchaseList() {
    const userData = JSON.parse(localStorage.getItem("loggedUser"));
    const permissions = useModulePermissions(userData);
    const queryClient = useQueryClient();
    const [showModel, setShowModel] = useState(false);
    const [showImportModel, setImportModel] = useState(false);
    const [isView, setIsView] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [purchaseData, setPurchaseData] = useState({});
    const { isLoading, isError, data } = useQuery({
        queryKey: ["purchase"],
        queryFn: getPurchaseList,
    });
    const {
        filteredList: POList,
        searchVal,
        handleSubmit,
        handleChange,
    } = useFilteredList(data?.result, filterByName);

    const deletePurchaseList = useQueryMutation(deletePurchase, queryClient, "purchase");
    const addPurchaseList = useQueryMutation(addPurchase, queryClient, "purchase");
    const editPurchaseList = useQueryMutation(editPurchase, queryClient, "purchase");


    

    const closeModel = () => {
        setShowModel(false);
        setIsView(false);
        setIsEdit(false);
    };

    const delePurchase = (id) => {
        deletePurchaseList.mutate(id);
    };

    const edPurchase = (obj) => {
        editPurchaseList.mutate(obj);
    };

    const adPurchase = (obj) => {
        addPurchaseList.mutate(obj);
    };

    const closeImportPurchaseModel = () => {
        setImportModel(false);
    };

    const settingIsEdit = (editVisible, purchase) => {
        setShowModel(true);
        setPurchaseData(purchase);
        setIsEdit(editVisible);
    };

    const columns = PurchaseColumns(delePurchase, edPurchase, isLoading, settingIsEdit)

    if (isLoading) return <Loader />;

    if (isError)
        return (
            <p className="text-center my-20 font-bold">Something Went Wrong!</p>
        );

    return (
        <div>
            {data.result && data.result.length > 0 ? (
                <Fragment>
                    <div className="flex justify-between mb-5">
                        <SearchList
                            searchVal={searchVal}
                            handleChange={handleChange}
                            handleSubmit={handleSubmit}
                            placeHolder={"PO No. or Indent No...."}
                        />
                        {permissions.showPurchaseMenu !== undefined && permissions.showPurchaseMenu.write && (
                            <button
                            onClick={() => setShowModel(true)}
                            className="primary btn flex items-center justify-center text-[#fff] text-[14px] font-bold mr-3"
                        >
                            <img src={whitePlus} alt="" />
                            <span className="ml-3">Add New Purchase</span>
                        </button>
                        )}
                        
                    </div>

                    <Table
                        pagination={{ pageSize: 6 }}
                        className="w-full text-left"
                        dataSource={POList}
                        columns={columns}
                        onRow={(record, rowIndex) => {
                            return {
                              onClick: () => {setPurchaseData(record); setShowModel(true); setIsView(true)},
                            };
                        }}
                    />
                </Fragment>
            ) : (
                <div className="text-center">
                    <img className="mx-auto" src={infoIcon} alt="Info Icon" />
                    <h4 className="text-[20px] text-[#2D3846] font-bold">
                        No data available
                    </h4>
                    <p className="text-[14px] text-[#656F7D] mb-12">
                        You have not added/created any Purchase here
                    </p>
                    {permissions.showPurchaseMenu !== undefined && permissions.showPurchaseMenu.write && (
                        <p className="flex justify-center">
                        <button
                            onClick={() => setShowModel(true)}
                            className="primary btn flex items-center justify-center text-[#fff] text-[14px] font-bold"
                        >
                            <img src={whitePlus} alt="" />
                            <span className="ml-3">Add New Purchase</span>
                        </button>
                    </p>
                    )}
                    
                </div>
            )}

            {showModel && permissions.showPurchaseMenu !== undefined && permissions.showPurchaseMenu.write && (
                <AddPurchase
                    closeModel={closeModel}
                    adPurchase={adPurchase}
                    isLoading={addPurchaseList.isLoading}
                    purchase={purchaseData}
                    isView={isView}
                    isEdit={isEdit}
                    settingIsEdit={settingIsEdit}
                    edPurchase={edPurchase}
                />
            )}
            {showImportModel && permissions.showPurchaseMenu !== undefined && permissions.showPurchaseMenu.write && (
                <ImportPurchase
                    closeImportPurchaseModel={closeImportPurchaseModel}
                    isLoading={isLoading}
                />
            )}
        </div>
    );
}
