import React, {useState} from 'react';
import Indent from './indent/Indent';
import useModulePermissions from '../hooks/useModulePermissions';
import TabComponent from '../components/TabComponent';

const IndentMain = () => {
  const userData = JSON.parse(localStorage.getItem("loggedUser"));
  const permissions = useModulePermissions(userData);
  const [activeTab, setActiveTab] = useState(permissions.showIndentMenu !== undefined ? '1' : '');
  const tabData = [
    { permission: 'showIndentMenu', id: '1', tabTitle: 'Indents', title: 'Indents' },
  ];
  
  return (
    <>
    <h3 className="font-bold text-[24px] text-[#2D3846] line-clamp-[20px] mb-5">Indent Overview</h3>
    <TabComponent 
        tabData={tabData} 
        activeTab={activeTab} 
        setActiveTab={setActiveTab} 
        permissions={permissions} 
      />
    {activeTab === '1' && permissions.showIndentMenu && <Indent />}
    </>
    
  )
}

export default IndentMain;
