import React, { useState } from "react";
import actionIcon from "../../assets/image/blue-action.svg";
import editIcon from "../../assets/image/edit-blue.svg";
import delIcon from "../../assets/image/delete-blue.svg";
import { useClickAway } from "@uidotdev/usehooks";
import useModulePermissions from "../../hooks/useModulePermissions";

export default function MaterialListTable(props) {
  const { material, settingIsEdit } = props;
  const userData = JSON.parse(localStorage.getItem("loggedUser"));
    const permissions = useModulePermissions(userData);
  const [showAction, setShowAction] = useState(false);

  const ref = useClickAway(() => {
    setShowAction(false);
  });

  const deletingMaterial = (catId) => {
    props.deleMaterial(catId);
  }

  const showEdit = (e) => {
    e.stopPropagation();
    settingIsEdit(true, material);
}

  return (
    <>
    {permissions.showMaterialMenu !== undefined && (permissions.showMaterialMenu.update || permissions.showMaterialMenu.delete) && (
      <span
      onClick={(e) => {e.stopPropagation(); setShowAction(!showAction)}}
      className="p-3 cursor-pointer flex justify-end"
    >
      <img src={actionIcon} alt="" />
    </span>
    )}
    
      {showAction && (
          <div className="absolute top-[10px] right-[30px] border border-solid border-[#D9D9D9] rounded z-10" ref={ref}>
            <ul className="w-[135px]">
            
              {permissions.showMaterialMenu !== undefined && permissions.showMaterialMenu.update && (
                <li className="text-[12px] cursor-pointer px-3 py-2 flex bg-white hover:bg-[#F5F5F5] rounded-t items-center border-b border-solid border-[#D9D9D9]" onClick={(e) => showEdit(e)}>
                <span className="pr-2"><img src={editIcon} alt="" /></span>
                <span>Edit</span>
              </li>
              )}
              {permissions.showMaterialMenu !== undefined && permissions.showMaterialMenu.delete && (
                <li
                onClick={(e) => {e.stopPropagation(); deletingMaterial(material._id)}}
                className="text-[12px] px-3 py-2 cursor-pointer rounded-b bg-white hover:bg-[#F5F5F5] flex items-center"
              >
                <span className="pr-2"><img src={delIcon} alt="" /></span>
                <span>Delete</span>
              </li>
              )}
              
            </ul>
          </div>
        )}
      </>
  );
}
