import { useState, useEffect } from 'react';

const useSelection = (data, item) => {
  const [selectedItem, setSelectedItem] = useState('');
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const selectItem = data?.result?.filter(c => c._id === item?.id);
    setSelectedItem(selectItem?.length > 0 ? selectItem[0]?.name : '');
    let itemList = [];
    data?.result?.forEach((u) => {
        const tempObj = {};
        tempObj.value = u._id;
        tempObj.label = `${u.name}`;
        itemList.push(tempObj);
    });
    setOptions(itemList);
  }, [data, item]);

  return { selectedItem, options };
};

export default useSelection;