import moment from "moment";
import IndentListTable from "../container/indent/IndentListTable";
import CategoryListTable from "../container/category/CategoryListTable";
import CustomerListTable from "../container/customers/CustomerListTable";
import VendorListTable from "../container/vendors/VendorListTable";
import ApprovalListTable from "../container/approvals/ApprovalListTable";
import OrganizationListTable from "../container/organization/OrganizationListTable";
import UserListTable from "../container/users/UserListTable";
import ProfileListTable from "../container/profiles/ProfileListTable";
import MaterialListTable from "../container/material/MaterialListTable";
import PurchaseListTable from "../container/purchase/PurchaseListTable";
import ProjectListTable from "../container/projects/ProjectListTable";
import GrnListTable from "../container/grn/GrnListTable";
import ScheduleListTable from "../container/schedules/ScheduleListTable";
import { IndianRupee } from "lucide-react";
import StatusRenderer from "../container/schedules/useStatusRender";
import DepartmentListTable from "../container/department/DepartmentListTable";


export const ProfileColumns = (deleProfile, edProfile, orgData, isLoading, settingIsEdit) => [
    {
        title: "Profile Name",
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (text) => (<p className="font-bold">{text}</p>)
    },
    {
        title: 'Organization',
        dataIndex: 'org_id',
        key: 'org_id',
        sorter: (a, b) => a.org_id.localeCompare(b.org_id),
        render: (text) => orgData?.result?.map(org => {
            if (org._id === text) {
                return <p key={org._id}>{org.name}</p>
            }
        })
    },
    {
        title: "",
        dataIndex: "",
        key: "action",
        render: (record) => (
            <ProfileListTable
                key={record._id}
                profile={record}
                deleProfile={deleProfile}
                edProfile={edProfile}
                isLoading={isLoading}
                settingIsEdit={settingIsEdit}
            />
        ),
    },
]
export const UserColumns = (deleUser, edUser, isLoading, settingIsEdit) => [
    {
        title: "Name",
        dataIndex: "",
        key: "name",
        sorter: (a, b) => `${a.first_name} ${a.last_name}`.localeCompare(`${b.first_name} ${b.last_name}`),
        render: (record) => (
            <>
                <p className="text-[14px] text-[#2D3846] font-bold">
                    {record.first_name} {record.last_name}
                </p>
                <span className="text-[10px] text-[#656F7D] block">
                    {record.user_type}
                </span>
            </>
        ),
    },
    {
        title: "Email",
        dataIndex: "email",
        key: "email",
        sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
        title: "Phone",
        dataIndex: "mobile",
        key: "mobile",
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        sorter: (a, b) => Number(a.status) - Number(b.status),
        render: (text) => (
            <p className="capitalize">{text ? "Active" : "Disable"}</p>
        ),
    },
    {
        title: "",
        dataIndex: "",
        key: "action",
        render: (record) => (
            <UserListTable
                key={record._id}
                user={record}
                deleUser={deleUser}
                edUser={edUser}
                isLoading={isLoading}
                settingIsEdit={settingIsEdit}
            />
        ),
    },
]
export const OrganizationColumns = (edOrganization, isLoading) => [
    {
        title: "Organization Name",
        dataIndex: "name",
        key: "name",
        render: (text) => (<p className="capitalize">{text}</p>)
    },
    {
        title: "Address",
        dataIndex: "address",
        key: "address",
    },
    {
        title: "City",
        dataIndex: "city",
        key: "city",
    },
    {
        title: "State",
        dataIndex: "state",
        key: "state",
    },
    {
        title: "Email",
        dataIndex: "email",
        key: "email",
    },
    {
        title: "Phone",
        dataIndex: "phone",
        key: "phone",
    },
    {
        title: "",
        dataIndex: "",
        key: "action",
        render: (record) => (
            <OrganizationListTable key={record._id} organization={record} edOrganization={edOrganization} isLoading={isLoading} />
        ),
    },
]
export const ApprovalColumns = (deleApproval, edApproval, isLoading, settingIsEdit) => [
    {
        title: "Approval Type",
        dataIndex: "approvalType",
        key: "approvalType",
        sorter: (a, b) => a.approvalType.localeCompare(b.approvalType),
        render: (text) => (<p className="capitalize">{text}</p>)
    },
    {
        title: "Request Type",
        dataIndex: "requestType",
        key: "requestType",
        sorter: (a, b) => a.requestType.localeCompare(b.requestType),
        render: (text) => (<p className="capitalize">{text}</p>)
    },
    {
        title: "Details",
        dataIndex: "details",
        key: "details",
        sorter: (a, b) => a.details.localeCompare(b.details),
    },
    {
        title: "",
        dataIndex: "",
        key: "action",
        render: (record) => (
            <ApprovalListTable key={record._id} settingIsEdit={settingIsEdit} approval={record} deleApproval={deleApproval} edApproval={edApproval} isLoading={isLoading} />
        ),
    },
]
export const VendorColumns = (deleVendor, edVendor, isLoading, settingIsEdit) => [
    {
        title: "Vendor Name",
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (text) => (
            <p>
                <strong>{text}</strong>
            </p>
        ),
    },
    {
        title: "Contact Person",
        dataIndex: "contact_person",
        key: "contact_person",
        sorter: (a, b) => a.contact_person.localeCompare(b.contact_person),
    },
    {
        title: "Address",
        dataIndex: "address",
        key: "address",
        sorter: (a, b) => a.address.localeCompare(b.address),
    },
    {
        title: "Mobile",
        dataIndex: "mobile",
        key: "mobile",
        sorter: (a, b) => a.mobile.localeCompare(b.mobile),
    },
    {
        title: "GST",
        dataIndex: "gst",
        key: "gst",
        sorter: (a, b) => a.gst.localeCompare(b.gst),
    },
    {
        title: "Email",
        dataIndex: "email",
        key: "email",
        sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        sorter: (a, b) => Number(a.status) - Number(b.status),
        render: (text) => (
            <p className="capitalize">{text ? "Active" : "Disable"}</p>
        ),
    },
    {
        title: "",
        dataIndex: "",
        key: "action",
        render: (record) => (
            <VendorListTable key={record._id} settingIsEdit={settingIsEdit} vendor={record} deleVendor={deleVendor} edVendor={edVendor} isLoading={isLoading} />
        ),
    },
]
export const CustomerColumns = (deleCustomer, edCustomer, isLoading, settingIsEdit) => [
    {
        title: "Client Name",
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (text) => (
            <p>
                <strong>{text}</strong>
            </p>
        ),
    },
    {
        title: "Mobile",
        dataIndex: "mobile",
        key: "mobile",
        sorter: (a, b) => a.mobile.localeCompare(b.mobile)
    },
    {
        title: "Email",
        dataIndex: "email",
        key: "email",
        sorter: (a, b) => a.email.localeCompare(b.email)
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        sorter: (a, b) => Number(a.status) - Number(b.status),
        render: (text) => (
            <p className="capitalize">{text ? "Active" : "Disable"}</p>
        ),
    },
    {
        title: "",
        dataIndex: "",
        key: "action",
        render: (record) => (
            <CustomerListTable key={record._id} settingIsEdit={settingIsEdit} customer={record} deleCustomer={deleCustomer} edCustomer={edCustomer} isLoading={isLoading} />
        ),
    },
]

export const CategoryColumns = (deleCategory, edCategory, isLoading, settingIsEdit) => [
    {
        title: "Category Name",
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (text) => (
            <p>
                <strong>{text}</strong>
            </p>
        ),
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        sorter: (a, b) => Number(a.status) - Number(b.status),
        render: (text) => (
            <p className="capitalize">{text ? "Active" : "Disable"}</p>
        ),
    },
    {
        title: "",
        dataIndex: "",
        key: "action",
        render: (record) => (
            <CategoryListTable
                key={record._id}
                category={record}
                deleCategory={deleCategory}
                edCategory={edCategory}
                isLoading={isLoading}
                settingIsEdit={settingIsEdit}
            />
        ),
    },
];

export const IndentColumns = (deleIndent, edIndent, isLoading, projData) => [
    {
        title: "Indent Number",
        dataIndex: "indent_number",
        key: "indent_number",
        sorter: (a, b) => a.project_code.localeCompare(b.project_code),
        render: (text) => (
            <p>
                <strong>{text}</strong>
            </p>
        ),
    },
    {
        title: "Project Code",
        dataIndex: "project_id",
        key: "project_id",
        sorter: (a, b) => a.project_code.localeCompare(b.project_code),
        render: (text) =>
            projData?.result?.map((proj) => {
                if (proj._id === text) {
                    return <p key={proj._id}>{proj.project_code}</p>;
                }
            }),
    },
    {
        title: "Date",
        dataIndex: "date",
        key: "date",
        sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
        render: (text) => <p>{moment(text).format("MM/DD/YYYY")}</p>,
    },
    {
        title: "Req. Date",
        dataIndex: "required_date",
        key: "required_date",
        sorter: (a, b) =>
            moment(a.required_date).unix() - moment(b.required_date).unix(),
        render: (text) => <p>{moment(text).format("MM/DD/YYYY")}</p>,
    },
    {
        title: "Approval Status",
        dataIndex: "approval_status",
        key: "approval_status",
        sorter: (a, b) => a.project_code.localeCompare(b.project_code),
    },
    {
        title: "Description",
        dataIndex: "description",
        key: "description",
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        sorter: (a, b) => Number(a.status) - Number(b.status),
        render: (text) => (
            <p className="capitalize">{text ? "Active" : "Disable"}</p>
        ),
    },
    {
        title: "",
        dataIndex: "",
        key: "action",
        render: (record) => (
            <IndentListTable
                key={record._id}
                indent={record}
                deleIndent={deleIndent}
                edIndent={edIndent}
                isLoading={isLoading}
            />
        ),
    },
];

export const MaterialColumns = (deleMaterial, edMaterial, isLoading, catData, settingIsEdit) => [
    {
        title: "Name",
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (text) => (
            <p>
                <strong>{text}</strong>
            </p>
        ),
    },
    {
        title: "Category Name",
        dataIndex: "category_id",
        key: "category_id",
        render: (text) =>
            catData?.result?.map((cat) => {
                if (cat._id === text) {
                    return <p key={cat._id}>{cat.name}</p>;
                }
            }),
        sorter: (a, b) => a.category_id.localeCompare(b.category_id),
    },
    {
        title: "Unit",
        dataIndex: "unit",
        key: "unit",
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        sorter: (a, b) => Number(a.status) - Number(b.status),
        render: (text) => (
            <p className="capitalize">{text ? "Active" : "Disable"}</p>
        ),
    },
    {
        title: "",
        dataIndex: "",
        key: "action",
        render: (record) => (
            <MaterialListTable
                key={record._id}
                material={record}
                deleMaterial={deleMaterial}
                edMaterial={edMaterial}
                isLoading={isLoading}
                settingIsEdit={settingIsEdit}
            />
        ),
    },
];


export const PurchaseColumns = (delePurchase, edPurchase, isLoading, settingIsEdit) => [
    {
        title: "PO Number",
        dataIndex: "po_number",
        render: (text) => <p className="font-bold">{text}</p>,
        key: "po_number",
        sorter: (a, b) => a.po_number.localeCompare(b.po_number),
    },
    {
        title: "Indent Number",
        dataIndex: "indent_number",
        key: "indent_number",
        sorter: (a, b) => a.indent_number.localeCompare(b.indent_number),
    },
    {
        title: "Project Code",
        dataIndex: "project_code",
        key: "project_code",
        sorter: (a, b) => a.project_code.localeCompare(b.project_code),
    },
    {
        title: "Payment Mode",
        dataIndex: "payment_mode",
        key: "payment_mode",
        sorter: (a, b) => a.payment_mode.localeCompare(b.payment_mode),
    },
    {
        title: "Payment Terms",
        dataIndex: "payment_terms",
        key: "payment_terms",
        sorter: (a, b) => a.payment_terms.localeCompare(b.payment_terms),
    },
    {
        title: "Priority",
        dataIndex: "priority",
        render: (text) =>
            text === "High" ? (
                <span className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
                    {text}
                </span>
            ) : (
                <span className="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">
                    {text}
                </span>
            ),
        key: "priority",
    },
    {
        title: "Total Amount",
        dataIndex: "total_cost",
        render: (text) => (
            <p className="flex items-center">
                <span>
                    <IndianRupee size={13} />
                </span>{" "}
                {text.toFixed(2)}
            </p>
        ),
        key: "total_cost",
        sorter: (a, b) => a.total_cost - b.total_cost,
    },
    {
        title: "Total Tax",
        dataIndex: "total_tax",
        render: (text) => (
            <p className="flex items-center">
                <span>
                    <IndianRupee size={13} />
                </span>{" "}
                {text.toFixed(2)}
            </p>
        ),
        key: "total_tax",
        sorter: (a, b) => a.total_tax - b.total_tax,
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        sorter: (a, b) => Number(a.status) - Number(b.status),
        render: (text) => (
            <p className="capitalize">{text ? "Active" : "Disable"}</p>
        ),
    },
    {
        title: "",
        dataIndex: "",
        key: "action",
        render: (record) => (
            <PurchaseListTable
                key={record._id}
                purchase={record}
                delePurchase={delePurchase}
                edPurchase={edPurchase}
                isLoading={isLoading}
                settingIsEdit={settingIsEdit}
            />
        ),
    },
];

export const ProjectColumns = (deleProject, edProject, isLoading, showProgress, settingIsEdit) => [
    {
        title: "Project Code",
        dataIndex: "project_code",
        render: (text) => <p className="font-bold">{text}</p>,
        key: "project_code",
        sorter: (a, b) => a.project_code.localeCompare(b.project_code),
    },
    {
        title: "Project Name",
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
        title: "Location",
        key: "address",
        render: (text, record) => (
            <p>{`${record.address}, ${record.area}, ${record.city}`}</p>
        ),
        sorter: (a, b) => {
            const locationA = `${a.address}, ${a.area}, ${a.city}`;
            const locationB = `${b.address}, ${b.area}, ${b.city}`;
            return locationA.localeCompare(locationB);
        },
    },
    {
        title: "Project Type",
        dataIndex: "type",
        key: "type",
        sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
        title: "Status",
        dataIndex: "progress",
        render: (text, record) => showProgress(text),
        key: "progress",
    },
    {
        title: "",
        dataIndex: "",
        key: "action",
        render: (record) => (
            <ProjectListTable
                key={record._id}
                project={record}
                deleProject={deleProject}
                edProject={edProject}
                isLoading={isLoading}
                settingIsEdit={settingIsEdit}
            />
        ),
    },
]

export const ScheduleColumns = (deleSchedule, edSchedule, isLoading, catData, projData, changeSchedule, settingIsEdit) => [
    {
        title: "Name",
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (text) => (
            <p>
                <strong>{text}</strong>
            </p>
        ),
    },
    {
        title: "Project Code",
        dataIndex: "project_id",
        key: "project_id",
        sorter: (a, b) => a.project_code.localeCompare(b.project_code),
        render: (text) =>
            projData?.result?.map((proj) => {
                if (proj._id === text) {
                    return <p key={proj._id}>{proj.project_code}</p>;
                }
            }),
    },
    {
        title: "Category",
        dataIndex: "category",
        key: "category",
        render: (text) =>
            catData?.result?.map((cat) => {
                if (cat._id === text) {
                    return <p key={cat._id}>{cat.name}</p>;
                }
            }),
        sorter: (a, b) => a.category_id.localeCompare(b.category_id),
    },
    {
        title: "Material Req. Date",
        dataIndex: "material_required_date",
        key: "material_required_date",
        sorter: (a, b) =>
            moment(a.material_required_date).unix() -
            moment(b.material_required_date).unix(),
        render: (text) => <p>{moment(text).format("MM/DD/YYYY")}</p>,
    },
    {
        title: "Start Date",
        dataIndex: "start_date",
        key: "start_date",
        sorter: (a, b) =>
            moment(a.start_date).unix() - moment(b.start_date).unix(),
        render: (text) => <p>{moment(text).format("MM/DD/YYYY")}</p>,
    },
    {
        title: "End Date",
        dataIndex: "end_date",
        key: "end_date",
        sorter: (a, b) => moment(a.end_date).unix() - moment(b.end_date).unix(),
        render: (text) => <p>{moment(text).format("MM/DD/YYYY")}</p>,
    },
    {
        title: "Duration",
        dataIndex: "days",
        key: "days",
    },
    {
        title: "Status",
        dataIndex: "progress",
        key: "progress",
        render: (text, record) => <StatusRenderer schedule={record} changeSchedule={changeSchedule} text={text} />,
    },
    {
        title: "",
        dataIndex: "",
        key: "action",
        render: (record) => (
            <ScheduleListTable
                key={record._id}
                schedule={record}
                deleSchedule={deleSchedule}
                edSchedule={edSchedule}
                isLoading={isLoading}
                settingIsEdit={settingIsEdit}
            />
        ),
    },
]


export const GrnColumns = (deleGrn, edGrn, isLoading) => [
    {
        title: "GRN Number",
        dataIndex: "grn_number",
        render: (text) => <p className="font-bold">{text}</p>,
        key: "grn_number",
        sorter: (a, b) => a.grn_number.localeCompare(b.grn_number),
    },
    // {
    //     title: "PO Number",
    //     dataIndex: "po_number",
    //     key: "po_number",
    //     sorter: (a, b) => a.po_number.localeCompare(b.po_number),
    // },
    {
        title: "Indent Number",
        dataIndex: "indent_number",
        key: "indent_number",
        sorter: (a, b) => a.indent_number.localeCompare(b.indent_number),
    },
    {
        title: "Project Code",
        dataIndex: "project_code",
        key: "project_code",
        sorter: (a, b) => a.project_code.localeCompare(b.project_code),
    },
    // {
    //     title: "PO Date",
    //     dataIndex: "po_date",
    //     render: (text) => <p>{moment(text).format('DD-MM-YYYY')}</p>,
    //     key: "po_date",
    //     sorter: (a, b) => a.po_date.localeCompare(b.po_date),
    // },
    {
        title: "Recieved Date",
        dataIndex: "received_date",
        render: (text) => <p>{moment(text).format('DD-MM-YYYY')}</p>,
        key: "received_date",
        sorter: (a, b) => a.received_date.localeCompare(b.received_date),
    },
    {
        title: "Vehicle No.",
        dataIndex: "vehicle_number",
        key: "vehicle_number",
        sorter: (a, b) => a.vehicle_number.localeCompare(b.vehicle_number),
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        sorter: (a, b) => Number(a.status) - Number(b.status),
        render: (text) => (
            <p className="capitalize">{text ? "Active" : "Disable"}</p>
        ),
    },
    {
        title: "",
        dataIndex: "",
        key: "action",
        render: (record) => (
            <GrnListTable
                key={record._id}
                grn={record}
                deleGrn={deleGrn}
                edGrn={edGrn}
                isLoading={isLoading}
            />
        ),
    },
];

export const DepartmentColumns = (deleDepartment, edDepartment, isLoading, settingIsEdit) => [
    {
        title: "Name",
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (text) => (
            <p>
                <strong>{text}</strong>
            </p>
        ),
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        sorter: (a, b) => Number(a.status) - Number(b.status),
        render: (text) => (
            <p className="capitalize">{text ? "Active" : "Disable"}</p>
        ),
    },
    {
        title: "",
        dataIndex: "",
        key: "action",
        render: (record) => (
            <DepartmentListTable
                key={record._id}
                department={record}
                deleDepartment={deleDepartment}
                edDepartment={edDepartment}
                isLoading={isLoading}
                settingIsEdit={settingIsEdit}
            />
        ),
    },
]