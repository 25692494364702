import React, {useState} from 'react';
import Users from './users/Users';
import Roles from './roles/Role';
import Departments from './department/Departments';
import Approval from './approvals/Approval';
import Profile from './profiles/Profile';
import useModulePermissions from '../hooks/useModulePermissions';
import TabComponent from '../components/TabComponent';

const SettingsMain = () => {
  const userData = JSON.parse(localStorage.getItem("loggedUser"));
  const permissions = useModulePermissions(userData);

  const getInitialActiveTab = (permissions) => {
    if (permissions.showUsersMenu !== undefined) return '1';
    if (permissions.showDepartmentMenu !== undefined) return '2';
    if (permissions.showApprovalsMenu !== undefined) return '3';
    if (permissions.showUserProfilesMenu !== undefined) return '4';
    return '5';
  };
  
  const [activeTab, setActiveTab] = useState(getInitialActiveTab(permissions));
  const tabData = [
    { permission: 'showUsersMenu', id: '1', tabTitle: 'Users', title: 'Users' },
    { permission: 'showDepartmentMenu', id: '2', tabTitle: 'Departments', title: 'Departments' },
    { permission: 'showApprovalsMenu', id: '3', tabTitle: 'Approval', title: 'Approval' },
    { permission: 'showUserProfilesMenu', id: '4', tabTitle: 'Profile', title: 'Profile' },
    { permission: 'showRolesMenu', id: '5', tabTitle: 'Roles', title: 'Roles' },
  ];
  

  return (
    <>
    <h3 className="font-bold text-[24px] text-[#2D3846] line-clamp-[20px] mb-5">Settings Overview</h3>
    <TabComponent 
        tabData={tabData} 
        activeTab={activeTab} 
        setActiveTab={setActiveTab} 
        permissions={permissions} 
      />
    {console.log(activeTab)}
    {activeTab === '1' && permissions.showUsersMenu !== undefined && <Users />}
    {activeTab === '2' && permissions.showDepartmentMenu !== undefined && <Departments />}
    {activeTab === '3' && permissions.showApprovalsMenu !== undefined && <Approval />}
    {activeTab === '4' && permissions.showUserProfilesMenu !== undefined && <Profile />}
    {activeTab === '5' && permissions.showRolesMenu !== undefined && <Roles />}
    </>
  )
}

export default SettingsMain;
