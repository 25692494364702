import React from "react";
import SideNav from "../components/SideNav";
import Header from "../components/Header";
import { Outlet } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import { useQuery } from "@tanstack/react-query";
import { getOrganizationList } from "../queries/Organization";

const Main = () => {
    const userName = JSON.parse(localStorage.getItem('loggedUser'));

    const { data: organizationData } = useQuery({
        queryKey: ["organization"],
        queryFn: getOrganizationList,
    });
    
    return(
        <div className="flex h-screen">
            <Helmet>
            <title>
                {organizationData?.result?.length > 0 ? organizationData?.result.filter(o => o._id === userName.org_id)[0].name : `Dashboard`}
            </title>
            </Helmet>
            <SideNav />
            <main className="p-5 w-full">
                <Header />
                <div className="text-left">
                    <p className="text-[#656F7D] text-[18px]">Hi {userName?.first_name}</p>
                </div>
                <Outlet />
            
            
            </main>
        </div>
    )
}

export default Main;