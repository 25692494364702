import React from 'react';
import CustomerList from './CustomerList';

export default function Customers() {
  return (
    <div className='bg-white p-8'>
        <CustomerList />
    </div>
  )
}
