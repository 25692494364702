import React, {useState} from 'react';
import Purchase from '../container/purchase/Purchase';
import Grn from '../container/grn/Grn';
import useModulePermissions from '../hooks/useModulePermissions';
import TabComponent from '../components/TabComponent';

const PurchaseMain = () => {
  const userData = JSON.parse(localStorage.getItem("loggedUser"));
  const permissions = useModulePermissions(userData);
  const [activeTab, setActiveTab] = useState(permissions.showPurchaseMenu !== undefined ? '1' : '2');
  const tabData = [
    { permission: 'showPurchaseMenu', id: '1', tabTitle: 'Purchase Order', title: 'Purchase Order' },
    { permission: 'showGrnMenu', id: '2', tabTitle: 'GRN', title: 'Grn' },
  ];
  
  return (
    <>
    <h3 className="font-bold text-[24px] text-[#2D3846] line-clamp-[20px] mb-5">Purchase Order</h3>
    <TabComponent
      tabData={tabData}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      permissions={permissions}
    />
    {activeTab === '1' && permissions.showPurchaseMenu !== undefined && <Purchase />}
    {activeTab === '2' && permissions.showGrnMenu !== undefined && <Grn />}
    </> 
)}

export default PurchaseMain;