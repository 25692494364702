import React, {useState} from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import {
    uploadFilesGrn
} from "../../queries/GRN";
import attachIcon from '../../assets/image/attachment-white.svg';
import deleteIcon from '../../assets/image/delete-white.svg';
import toast from "react-hot-toast";
import { Link } from 'react-router-dom';

export default function UpdateBillingInfoModal({grn, closeModel, editingGrn}) {

    const [billDate, setBillDate] = useState(new Date());
    const [uploadedFiles, setUploadedFiles] = useState(grn?.attachments || []);
    const [currentFile, setCurrentFile] = useState([]);


    const {
        register,
        reset,
        formState: { errors },
        control,
        clearErrors,
        handleSubmit,
        setValue,
      } = useForm({
        defaultValues: {
            bill_number: grn?.bill_number,
            bill_date: moment(grn?.bill_date).format('YYYY-MM-DD'),
            po_number: grn?.po_number,
            po_date: moment(grn?.po_date).format('YYYY-MM-DD'),
            indent_number: grn?.indent_number,
            indent_id: grn?._id,
            project_id: grn?.project_id,
            project_code: grn?.project_code,
            recieved_date: moment(grn?.recieved_date).format('DD-MM-YYYY'),
            vehicle_number: grn?.vehicle_number,
            materials: grn?.materials
        }
});



  const handleFileUpload = (e) => {
    const files = Array.from(e.target.files);
    if (files.length + uploadedFiles.length > 6) {
      toast.error('You can upload a maximum of 6 files.');
      setCurrentFile([]);
      return;
    }
    setCurrentFile([...currentFile, ...files]);
  };

    //const uploadFiles = useQueryMutation(uploadFilesGrn, queryClient, 'grn');

    const deleteFile = (file) => {
        if(file?._id) {
            const newFiles = uploadedFiles.filter((f) => f !== file);
            setUploadedFiles(newFiles);
        }else{
            const newFiles = currentFile.filter((f) => f !== file);
            setCurrentFile(newFiles);
        }
    }

  const uploadFiles = (e) => {
    e.preventDefault();
    //setUploadedFiles(grn?.attachments || [])
    const formData = new FormData();
    currentFile.forEach((file) => {
      formData.append('files', file);
    });
    const payload = {
        id: grn._id,
        body: formData
    }
    uploadFilesGrn(payload).then((res) => {
        setUploadedFiles([...grn?.attachments, ...res?.data]);
        setCurrentFile([]);
    });
    console.log(uploadedFiles);
    
  }

      const onSubmit = (data, e) => {
        e.preventDefault();
        const payload = {
            id: grn._id,
            body: {
                bill_number: data.bill_number,
                bill_date: moment(billDate).format('YYYY-MM-DD'),
                po_number: data.po_number,
                po_date: grn?.po_date,
                indent_number: grn?.indent_number,
                indent_id: grn?._id,
                project_id: grn?.project_id,
                project_code: grn?.project_code,
                recieved_date: moment(grn?.recieved_date).format('DD-MM-YYYY'),
                vehicle_number: grn?.vehicle_number,
                materials: grn?.materials,
                attachments: uploadedFiles.length > 0 ? uploadedFiles : grn?.attachments
            }
            
        }
        console.log(payload);
        editingGrn(payload);
        closeModel();
    }
    

    return (
        
        <div
            id="billing-modal"
            tabindex="-1"
            aria-hidden="true"
            className="flex overflow-y-auto overflow-x-hidden bg-opacity-60 bg-black fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-full"
        >
            
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow">
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                        <h3 className="text-xl font-semibold text-gray-900">
                            Update Billing Info
                        </h3>
                        <button
                            type="button"
                            className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                            data-modal-hide="authentication-modal"
                            onClick={closeModel}
                        >
                            <svg
                                className="w-3 h-3"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 14 14"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="p-4 md:p-5">
                            <div className="grid grid-cols-2 gap-4">
                            <div>
                                <label
                                    for="bill-number"
                                    className="block mb-2 text-sm font-medium text-gray-900"
                                >
                                    Bill Number
                                </label>
                                <input
                                    type="text"
                                    id="bill_number"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                    placeholder="Bill Number"
                                    {...register("bill_number", {
                                        required: 'Enter Valid Bill Number',
                                    })}
                                />
                                {errors?.bill_number && (
                                    <p className="text-xs text-[red] mt-1" role="alert">
                                    {errors?.bill_number?.message}
                                    </p>
                                )}
                            </div>
                            <div>
                                <label
                                    for="bill-date"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Bill Date
                                </label>
                                <DatePicker
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                selected={billDate}
                                onChange={(date) => {
                                    setBillDate(date);
                                }}
                                />
                                {!billDate && (
                                    <p className="text-xs text-[red] mt-1" role="alert">
                                    Enter Valid Bill Date
                                    </p>
                                )}
                            </div>
                            <div>
                                <label
                                    for="po-number"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    PO Number
                                </label>
                                <input
                                    type="text"
                                    id="po_number"
                                    {...register("po_number", {
                                        required: 'Enter Valid PO Number',
                                    })}
                                    placeholder="PO Number"
                                    value={grn?.po_number}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                />
                                {errors?.po_number && (
                                    <p className="text-xs text-[red] mt-1" role="alert">
                                    {errors?.po_number?.message}
                                    </p>
                                )}
                            </div>
                            <div>
                                <label
                                    for="po-date"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    PO Date
                                </label>
                                <input
                                    type="text"
                                    name="po-date"
                                    id="po-date"
                                    disabled
                                    value={moment.utc(grn?.po_date).format('DD-MM-YYYY')}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                />
                            </div>
                            </div>
                            <div className="mt-3">
                                <label htmlFor="fileUpload">Upload Files (Max 6):</label>
                                <input
                                type="file"
                                id="fileUpload"
                                multiple
                                onChange={handleFileUpload}
                                />
                                <div className="mt-5">
                                {currentFile?.length > 0 && (
                                <>
                                <p className="font-bold">Current File's</p>
                                <ul>
                                    {currentFile?.map((file, index) => (
                                    <div key={index} className="mt-1 items-center bg-[#374151] w-full rounded-md px-4 py-2 flex justify-between">
                                    <p className="flex items-center text-[13px] text-gray-300">
                                        <span className="pr-2"><img src={attachIcon} alt="" /></span>
                                        {file?.file_name || file?.name}
                                    </p>
                                    <span onClick={() => deleteFile(file)} className="cursor-pointer"><img src={deleteIcon} alt="" /></span>
                                </div>
                                    ))}
                                </ul>
                                </>
                                )}
                                {currentFile.length > 0 && (<p className="text-right pt-3"><button className="font-bold text-[#007AC8]" onClick={(e) => uploadFiles(e)}>Upload</button></p>)}
                                </div>
                                <div className="mt-5">
                                
                                {uploadedFiles.length > 0 && (
                                <>
                                <p className="font-bold">Existing File's</p>
                                <ul>
                                    {uploadedFiles?.map((file, index) => (
                                    <div key={index} className="mt-1 items-center bg-[#374151] w-full rounded-md px-4 py-2 flex justify-between">
                                    <Link to={file?.url} target="_blank" className="flex items-center text-[13px] text-gray-300">
                                        <span className="pr-2"><img src={attachIcon} alt="" /></span>
                                        {file?.file_name || file?.name}
                                    </Link>
                                    <span onClick={() => deleteFile(file)} className="cursor-pointer"><img src={deleteIcon} alt="" /></span>
                                </div>
                                    ))}
                                </ul>
                                </>
                                )}
                                </div>
                            </div>
                        </div>
                        <div class="flex items-center md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                            <button
                                type="Submit"
                                className="bg-[#007AC8] h-[40px] w-[100px] border border-[#007AC8] rounded text-white text-[14px] font-bold uppercase"
                            >Update</button>
                        </div>
                    </form>
                </div>
            </div>
            
        </div>
    );
}
