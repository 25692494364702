import React from "react";
import closeIcon from "../../assets/image/blue-cross.svg";
import { useForm } from "react-hook-form";
import { BtnLoader } from "../../components/utils/Loader";
import { useQuery } from "@tanstack/react-query";
import {
    EMAIL_ONLY,
    PHONE_NO_ONLY,
    NUMBERS_ONLY,
} from "../../components/utils/Regex";
export default function AddCustomer(props) {
    const userData = JSON.parse(localStorage.getItem("loggedUser"));
    const {
        isEdit,
        isLoading,
        customer,
        editingCustomr,
        adCustomer,
        closeModel,
        isView,
    } = props;
    
    const closingModel = () => {
        closeModel();
    };
    const {
        register,
        reset,
        formState: { errors },
        handleSubmit,
    } = useForm(
        isEdit || isView
            ? {
                  defaultValues: {
                      name: customer.name,
                      address: customer.address,
                      gst: customer.gst,
                      mobile: customer.mobile,
                      fax: customer.fax,
                      email: customer.email,
                      alternate_email: customer.alternate_email,
                      alt_person_name: customer.alt_person_name,
                      alt_mobile: customer.alt_mobile,
                      website:customer.website,
                      purchase_person : customer.purchase_person,
                      accounts_person : customer.accounts_person,
                      finance_person : customer.finance_person,
                      status: customer.status,
                  },
              }
            : {
                  defaultValues: {
                      name: "",
                      address: "",
                      gst: "",
                      mobile: "",
                      fax: "",
                      email: "",
                      alternate_email: "",
                      alt_person_name: "",
                      alt_mobile: "",
                      website:"",
                      purchase_person : "",
                      accounts_person : "",
                      finance_person : "",
                      status: true,
                  },
              }
    );

    const onSubmit = (data) => {
        if (isEdit) {
            const payload = {
                id: customer._id,
                body: {
                    ...data,
                    org_id: userData.org_id,
                },
            };
            editingCustomr(payload);
            closingModel();
        } else {
            const payload = {
                ...data,
                org_id: userData.org_id,
            };
            adCustomer(payload);
            closingModel();
        }
        reset();
    };

    return (
        <div className="bg-[#EBEDF2] p-12 fixed top-0 left-0 w-full h-full z-20">
            <p className="mb-8 text-right">
                <button onClick={closingModel}>
                    <img src={closeIcon} alt="" />
                </button>
            </p>

            <div className="flex">
                <div className="w-[40%] text-left">
                    <h3 className="text-[24px] text-[#2D3846]">
                        {isEdit ? "Edit Client" : isView ?  "View Client" : "Add New Client"}
                    </h3>
                    <p className="text-[14px] text-[#656F7D]">
                        Enter Client details and add to list
                        {isEdit ? " or update Client details" : isView ? " or view Client details" : ""}
                    </p>
                </div>
                <div className="w-[60%]">
                    <form onSubmit={handleSubmit(onSubmit)} className={isView ? 'view-page' : ''}>
                        <div className="bg-white p-5 rounded-md max-h-[70vh] overflow-y-auto">
                            <h4 className="text-[20px] text-left border-b-2 border-b-[#007AC8] mb-8">
                                Client Details
                            </h4>

                            <div className="grid grid-cols-2 gap-8 text-left">
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Client Name <span className="text-[red]">*</span>
                                        </label>
                                        <input
                                            className={errors.name ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                                            type="text"
                                            {...register("name", {
                                                required:
                                                    "Client Name is required",
                                            })}
                                        />
                                    </div>
                                    {errors.name && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors.name?.message}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Contact Person Name
                                        </label>
                                        <input
                                            className="text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                                            type="text"
                                            {...register("alt_person_name")}
                                        />
                                    </div>
                                </div>
                                 <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Address 
                                        </label>
                                        <input
                                            className='text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'
                                            type="text"
                                            {...register("address")}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Website
                                        </label>
                                        <input
                                            className='text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'
                                            type="text"
                                            {...register("website")}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            GST
                                        </label>
                                        <input
                                            className="text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                                            type="text"
                                            {...register("gst")}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Fax
                                        </label>
                                        <input
                                            className={errors.fax ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                                            type="text"
                                            {...register("fax", {
                                                required: false,
                                                pattern: {
                                                    value: NUMBERS_ONLY,
                                                    message:
                                                        "Enter Valid Fax No.",
                                                },
                                            })}
                                        />
                                    </div>
                                    {errors?.fax && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors?.fax?.message}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Mobile  <span className="text-[red]">*</span>
                                        </label>
                                        <input
                                            className={errors.mobile ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                                            type="text"
                                            {...register("mobile",{required: "Mobile is required",
                                                pattern: {
                                                    value: PHONE_NO_ONLY,
                                                    message:
                                                        "Please Enter valid Phone no.",
                                                },
                                            })}
                                        />
                                    </div>
                                    {errors?.mobile && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors?.mobile?.message}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                           Alternate Mobile
                                        </label>
                                        <input
                                            className={errors.alt_mobile ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                                            type="text"
                                            {...register("alt_mobile", {required: false,
                                                pattern: {
                                                    value: PHONE_NO_ONLY,
                                                    message:
                                                        "Please Enter valid Phone no.",
                                                },
                                            })}
                                        />
                                    </div>
                                    {errors?.alt_mobile && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors?.alt_mobile?.message}
                                        </p>
                                    )}
                                </div>
                       
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Email
                                        </label>
                                        <input
                                            className={errors.email ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                                            type="text"
                                            {...register("email", {
                                                required: false,
                                                pattern: {
                                                    value: EMAIL_ONLY,
                                                    message:
                                                        "Invalid email address",
                                                },
                                            })}
                                        />
                                    </div>
                                    {errors?.email && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors?.email?.message}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Alternate Email
                                        </label>
                                        <input
                                            className={errors.alternate_email ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                                            type="text"
                                            {...register("alternate_email", {
                                                required: false,
                                                pattern: {
                                                    value: EMAIL_ONLY,
                                                    message:
                                                        "Invalid email address",
                                                },
                                            })}
                                        />
                                    </div>
                                    {errors?.alternate_email && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors?.alternate_email?.message}
                                        </p>
                                    )}
                                </div>
                                    
                                <div>
                                    <div>
                                        <label
                                            className="text-[#2B384A] text-[12px] block mb-2"
                                            htmlFor=""
                                        >
                                            Status
                                        </label>
                                        <div className="flex">
                                            <p className="flex mr-3">
                                                <input
                                                    {...register("status", {
                                                        required:
                                                            "Status is required",
                                                    })}
                                                    type="radio"
                                                    defaultChecked
                                                    value={true}
                                                />
                                                <span className="text-[#2B384A] text-[12px] pl-2">
                                                    Active
                                                </span>
                                            </p>
                                            <p className="flex">
                                                <input
                                                    {...register("status", {
                                                        required:
                                                            "Status is required",
                                                    })}
                                                    type="radio"
                                                    value={false}
                                                />
                                                <span className="text-[#2B384A] text-[12px] pl-2">
                                                    Disable
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    {errors.status && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors.status?.message}
                                        </p>
                                    )}
                                </div>

                            </div>
                            <h4 className="text-[20px] text-left border-b-2 border-b-[#007AC8] mb-8 mt-5">
                                Purchase Person Details
                            </h4>
                            <div className="grid grid-cols-2 gap-8 text-left mb-5">
                                <div>
                                    <div>
                                    <label
                                        className="text-[#2B384A] text-[12px] block mb-2"
                                        htmlFor=""
                                    >
                                        Purchase Person Name <span className="text-[red]">*</span>
                                    </label>
                                    <input
                                        className={errors?.purchase_person?.name ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                                        type="text"
                                        {...register("purchase_person.name", {
                                            required: "Purchase Person name is required",
                                        })}
                                    />
                                    </div>
                                    {errors?.purchase_person?.name && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors?.purchase_person?.name?.message}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <div>
                                    <label
                                        className="text-[#2B384A] text-[12px] block mb-2"
                                        htmlFor=""
                                    >
                                        Purchase Person Phone <span className="text-[red]">*</span>
                                    </label>
                                    <input
                                        className={errors?.purchase_person?.phone ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                                        type="text"
                                        {...register("purchase_person.phone", {
                                            required: "Purchase Person phone is required",
                                                pattern: {
                                                    value: PHONE_NO_ONLY,
                                                    message:
                                                        "Please Enter valid Phone no.",
                                                },
                                        })}
                                    />
                                    </div>
                                    {errors?.purchase_person?.phone && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors?.purchase_person?.phone?.message}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <div>
                                    <label
                                        className="text-[#2B384A] text-[12px] block mb-2"
                                        htmlFor=""
                                    >
                                        Purchase Person Email <span className="text-[red]">*</span>
                                    </label>
                                    <input
                                        className={errors?.purchase_person?.email ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                                        type="text"
                                        {...register("purchase_person.email", {
                                            required: "Purchase Person email is required",
                                            pattern: {
                                                value: EMAIL_ONLY,
                                                message:
                                                    "Invalid email address",
                                            },
                                        })}
                                    />
                                    </div>
                                    {errors?.purchase_person?.email && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors?.purchase_person?.email?.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <h4 className="text-[20px] text-left border-b-2 border-b-[#007AC8] mb-8">
                                Account Person Details
                            </h4>
                            <div className="grid grid-cols-2 gap-8 text-left mb-5">
                                <div>
                                    <div>
                                    <label
                                        className="text-[#2B384A] text-[12px] block mb-2"
                                        htmlFor=""
                                    >
                                        Account Person Name <span className="text-[red]">*</span>
                                    </label>
                                    <input
                                        className={errors?.accounts_person?.name ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                                        type="text"
                                        {...register("accounts_person.name", {
                                            required: "Accounts Person name is required",
                                        })}
                                    />
                                    </div>
                                    {errors?.accounts_person?.name && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors?.accounts_person?.name?.message}
                                        </p>
                                    )}
                                    
                                </div>
                                <div>
                                    <div>
                                    <label
                                        className="text-[#2B384A] text-[12px] block mb-2"
                                        htmlFor=""
                                    >
                                        Account Person Phone <span className="text-[red]">*</span>
                                    </label>
                                    <input
                                        className={errors?.accounts_person?.phone ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                                        type="text"
                                        {...register("accounts_person.phone", {
                                            required: "Accounts Person phone is required",
                                                pattern: {
                                                    value: PHONE_NO_ONLY,
                                                    message:
                                                        "Please Enter valid Phone no.",
                                                },
                                        })}
                                    />
                                    </div>
                                    {errors?.accounts_person?.phone && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors?.accounts_person?.phone?.message}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <div>
                                    <label
                                        className="text-[#2B384A] text-[12px] block mb-2"
                                        htmlFor=""
                                    >
                                        Account Person Email <span className="text-[red]">*</span>
                                    </label>
                                    <input
                                        className={errors?.accounts_person?.email ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                                        type="text"
                                        {...register("accounts_person.email", {
                                            required: "Accounts Person email is required",
                                            pattern: {
                                                value: EMAIL_ONLY,
                                                message:
                                                    "Invalid email address",
                                            },
                                        })}
                                    />
                                    </div>
                                    {errors?.accounts_person?.email && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors?.accounts_person?.email?.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <h4 className="text-[20px] text-left border-b-2 border-b-[#007AC8] mb-8">
                                Finance Person Details
                            </h4>
                            <div className="grid grid-cols-2 gap-8 text-left">
                                <div>
                                    <div>
                                    <label
                                        className="text-[#2B384A] text-[12px] block mb-2"
                                        htmlFor=""
                                    >
                                        Finance Person Name <span className="text-[red]">*</span>
                                    </label>
                                    <input
                                        className={errors?.finance_person?.name ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                                        type="text"
                                        {...register("finance_person.name", {
                                            required: "Finance Person name is required",
                                        })}
                                    />
                                    </div>
                                    {errors?.finance_person?.name && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors?.finance_person?.name?.message}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <div>
                                    <label
                                        className="text-[#2B384A] text-[12px] block mb-2"
                                        htmlFor=""
                                    >
                                        Finance Person Phone <span className="text-[red]">*</span>
                                    </label>
                                    <input
                                        className={errors?.finance_person?.phone ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                                        type="text"
                                        {...register("finance_person.phone", {
                                            required: "Finance Person phone is required",
                                                pattern: {
                                                    value: PHONE_NO_ONLY,
                                                    message:
                                                        "Please Enter valid Phone no.",
                                                },
                                        })}
                                    />
                                    </div>
                                    {errors?.finance_person?.phone && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors?.finance_person?.phone?.message}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <div>
                                    <label
                                        className="text-[#2B384A] text-[12px] block mb-2"
                                        htmlFor=""
                                    >
                                        Finance Person Email <span className="text-[red]">*</span>
                                    </label>
                                    <input
                                        className={errors?.finance_person?.email ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                                        type="text"
                                        {...register("finance_person.email", {
                                            required: "Finance Person email is required",
                                            pattern: {
                                                value: EMAIL_ONLY,
                                                message:
                                                    "Invalid email address",
                                            },
                                        })}
                                    />
                                    </div>
                                    {errors?.finance_person?.email && (
                                        <p
                                            className="text-xs text-[red] mt-1"
                                            role="alert"
                                        >
                                            {errors?.finance_person?.email?.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                         
                        </div>
                        {!isView && (
                            <p className="text-right mt-8 flex items-center justify-end">
                                <button
                                    onClick={closingModel}
                                    className="bg-transparent h-[40px] w-[100px] border text-[#007AC8] border-[#007AC8] rounded text-[14px] font-bold mr-3"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="Submit"
                                    className="bg-[#007AC8] h-[40px] w-[100px] border border-[#007AC8] rounded text-white text-[14px] font-bold uppercase"
                                    disabled={isLoading}
                                >
                                    {isLoading ? (
                                        <BtnLoader />
                                    ) : isEdit ? (
                                        "Update"
                                    ) : (
                                        "Create"
                                    )}
                                </button>
                            </p>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
}
