import React, { useState } from 'react';
import { Slider } from 'antd';

const StatusRenderer = ({ schedule, text, changeSchedule }) => {
  const [showSlider, setShowSlider] = useState(false);
  const [sliderValue, setSliderValue] = useState(text);
  const showProgress = (val) => {
    return(
      <div class="w-full bg-[#E9ECF1] rounded-full">
        <div class="bg-[#87DEB9] text-xs font-medium text-gray-600 justify-center leading-none rounded-full flex" style={{width: `${val}%`}}> {val}%</div>
      </div>
    )
  }
  const onChangeComplete = (val) => {
    setSliderValue(val);
  }

  const handleContainerClick = (event) => {
    event.stopPropagation();
  };
  
  const saveStatus = () => {
    const tempObj = {
      schedule_id: schedule._id,
      state_value: sliderValue
    }
    changeSchedule(tempObj);
    setShowSlider(false);
  }

  return (
    <div>
      {showSlider ? (
        <div onClick={handleContainerClick}>
        
          <Slider
            min={text}
            max={100}
            defaultValue={text}
            onChangeComplete={onChangeComplete}
          />
         
          <div className='flex'>
            <button onClick={() => setShowSlider(false)} className='bg-[#EBEDF2] text-[12px] px-3 rounded-md mr-2'>Cancel</button>
            <button onClick={() => saveStatus()} className='bg-[#007AC8] text-white text-[12px] px-3 rounded-md'>Save</button>
          </div>
      </div>) : <div onClick={handleContainerClick}>
      <p>{showProgress(text)}</p>
      {text < 100 && (
        <span
          className="text-[11px] text-end block text-[#007AC8] cursor-pointer"
          onClick={() => setShowSlider(true)}
        >
          Change Status
        </span>
      )}
      </div>}
    </div>
  );
};

export default StatusRenderer;