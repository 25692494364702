import React from 'react';
import OrganizationList from './OrganizationList';

export default function Organization() {
  return (
    <div className='bg-white p-8'>
        <OrganizationList />
    </div>
  )
}
