import {
  endPoints,
  getAuthorizationHeader,
  axiosInstance,
} from "../components/api";

export const getOrganizationList = async () => {
  const { data: response } = await axiosInstance.get(`${endPoints.organization}`, {
    headers: { Authorization: getAuthorizationHeader() },
  });
  return response;
};

export const editOrganization = async (orgObj) => {
  const { data: response } = await axiosInstance.put(
    `${endPoints.organization}/${orgObj.id}`,
    orgObj.body,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};

