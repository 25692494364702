import React from "react";
import closeIcon from "../../assets/image/blue-cross.svg";
import { useForm } from "react-hook-form";
import { BtnLoader } from "../../components/utils/Loader";

export default function AddDepartment(props) {
  const {isEdit, isView, adDepartment, closeModel, isLoading, department, edDepartment } = props;
  const closingModel = () => {
    closeModel();
  };
  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm(
    isEdit || isView ? { 
      defaultValues: {
        name: department.name,
        status: department.status
      } 
    } : 
    { 
      defaultValues: {
        name: "",
        status: true
      } 
    }
  );

  const onSubmit = (data) => {
    if(isEdit){
      const payload = {
        id: department._id,
        body: {
          name: data.name,
          status: data.status !== 'true' ? Boolean(false) : Boolean(true)
        }
      }
      edDepartment(payload);
    }else{
      const tempObj = {
        name: data.name,
        status: data.status !== 'true' ? Boolean(false) : Boolean(true)
      }
      adDepartment(tempObj);
    }
    closingModel();
  };



  return (
    <div className="bg-[#EBEDF2] p-12 fixed top-0 left-0 w-full h-full z-10">
      <p className="mb-8 text-right">
        <button onClick={closingModel}>
          <img src={closeIcon} alt="" />
        </button>
      </p>

      <div className="flex">
        <div className="w-[40%] text-left">
          <h3 className="text-[24px] text-[#2D3846]">
              {isEdit ? "Edit Department" : isView ?  "View Department" : "Add New Department"}
          </h3>
          <p className="text-[14px] text-[#656F7D]">
              Enter Department details and add to list
              {isEdit ? " or update department details" : isView ? " or view department details" : ""}
          </p>

        </div>
        <div className="w-[60%]">
          <form onSubmit={handleSubmit(onSubmit)} className={isView ? "view-page" : ''}>
            <div className="bg-white p-5 rounded-md max-h-full overflow-y-auto">
              <h4 className="text-[20px] text-left border-b-2 border-b-[#007AC8] mb-8">
                Department Details
              </h4>

              <div className="grid grid-cols-2 gap-8 text-left">
                <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      Department Name <span className="text-[red]">*</span>
                    </label>
                    <input
                      className={errors.name ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                      type="text"
                      {...register("name", {
                        required: "Department Name is required",
                      })}
                    />
                  </div>
                  {errors.name && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      {errors.name?.message}
                    </p>
                  )}
                </div>

                <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      Status
                    </label>
                    <div className="flex">
                    <p className="flex mr-3">
                        
                        <input
                        {...register("status", {
                            required: "Status is required",
                        })}
                        type="radio"
                        defaultChecked
                        value={true}
                        />
                        <span className="text-[#2B384A] text-[12px] pl-2">Active</span>
                    </p>
                    <p className="flex">
                        
                        <input
                      {...register("status", {
                        required: "Status is required",
                      })}
                      type="radio"
                      value={false}
                    />
                    <span className="text-[#2B384A] text-[12px] pl-2">Disable</span>
                    </p> 
                    </div>                   
                  </div>
                  {errors.status && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      {errors.status?.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            {!isView && (
            <p className="text-right mt-8 flex items-center justify-end">
              <button
                onClick={closingModel}
                className="bg-transparent h-[40px] w-[100px] border text-[#007AC8] border-[#007AC8] rounded text-[14px] font-bold mr-3"
              >
                Cancel
              </button>
              <button
                type="Submit"
                className="bg-[#007AC8] h-[40px] w-[100px] border border-[#007AC8] rounded text-white text-[14px] font-bold uppercase"
                disabled={isLoading}
              >
                {isLoading ? (
                                        <BtnLoader />
                                    ) : isEdit ? (
                                        "Update"
                                    ) : (
                                        "Create"
                                    )}
              </button>
            </p>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}
