import React from "react";
import closeIcon from "../../assets/image/blue-cross.svg";
import { useForm } from "react-hook-form";
import { BtnLoader } from "../../components/utils/Loader";
import { getCategoryList } from '../../queries/Categories';
import { useQuery } from "@tanstack/react-query";

export default function AddMaterial(props) {
  const userData = JSON.parse(localStorage.getItem("loggedUser"));
  const { material, isEdit, isView, isLoading, closeModel, edMaterial, adMaterial } = props;
  const closingModel = () => {
    closeModel();
  };
  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm(
    isEdit || isView ? { 
      defaultValues: {
        name: material.name,
        category_id: material.category_id,
        sku: material.sku,
        hsn_code: material.hsn_code,
        brand: material.brand,
        model: material.model,
        cost: material.cost,
        unit: material.unit,
        description: material.description,
        status: material.status,
      }
    } : { 
      defaultValues: {
        name: "",
        category_id: "",
        sku: "",
        hsn_code: "",
        brand: "",
        model: "",
        cost: "",
        unit: "",
        description: "",
        status: "",
      } 
    }
  );

  const { data } = useQuery({
    queryKey: ["categories"],
    queryFn: getCategoryList,
  });

  const onSubmit = (data) => {
    if(isEdit) {
      const payload = {
        id: material._id,
        body: {
          ...data,
          org_id: userData.org_id,
        }
      }
      edMaterial(payload);
      closeModel();
    }else{
      const payload = {
        ...data,
        org_id: userData.org_id,
      }
      adMaterial(payload);
    }
    closeModel();
  };


  return (
    <div className="bg-[#EBEDF2] p-12 fixed top-0 left-0 w-full h-full z-10">
      <p className="mb-8 text-right">
        <button onClick={closingModel}>
          <img src={closeIcon} alt="" />
        </button>
      </p>

      <div className="flex">
        <div className="w-[40%] text-left">
          <h3 className="text-[24px] text-[#2D3846]">
              {isEdit ? "Edit Material" : isView ?  "View Material" : "Add New Material"}
          </h3>
          <p className="text-[14px] text-[#656F7D]">
              Enter Material details and add to list
              {isEdit ? " or update material details" : isView ? " or view material details" : ""}
          </p>

        </div>
        <div className="w-[60%]">
          <form onSubmit={handleSubmit(onSubmit)} className={isView ? 'view-page' : ''}>
            <div className="bg-white p-5 rounded-md max-h-[70vh] overflow-y-auto">
              <h4 className="text-[20px] text-left border-b-2 border-b-[#007AC8] mb-8">
                Material Details
              </h4>

              <div className="grid grid-cols-2 gap-8 text-left">
                <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      Material Name <span className="text-[red]">*</span>
                    </label>
                    <input
                      className={errors.name ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                      type="text"
                      {...register("name", {
                        required: "Material Name is required",
                      })}
                    />
                  </div>
                  {errors.name && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      {errors.name?.message}
                    </p>
                  )}
                </div>
                <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      Category <span className="text-[red]">*</span>
                    </label>
                    <select
                      className={errors.category_id ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                      {...register("category_id", {
                        required: "Category is required",
                      })}
                    >
                      <option value="">Select Category</option>
                      {data?.result.map((c) => (
                        <option key={c._id} value={c._id}>
                          {c.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {errors?.category_id && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      {errors?.category_id?.message}
                    </p>
                  )}
                </div>
                <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      SKU <span className="text-[red]">*</span>
                    </label>
                    <input
                      className={errors.sku ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                      type="text"
                      {...register("sku", {
                        required: "SKU is required",
                      })}
                    />
                  </div>
                  {errors.sku && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      {errors.sku?.message}
                    </p>
                  )}
                </div>
                <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      HSN Code <span className="text-[red]">*</span>
                    </label>
                    <input
                      className={errors.hsn_code ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                      type="text"
                      {...register("hsn_code", {
                        required: "HSN is required",
                      })}
                    />
                  </div>
                  {errors.hsn_code && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      {errors.hsn_code?.message}
                    </p>
                  )}
                </div>
                <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      Brand Name <span className="text-[red]">*</span>
                    </label>
                    <input
                      className={errors.brand ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                      type="text"
                      {...register("brand", {required: "Brand is required",})}
                    />
                  </div>
                  {errors.brand && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      {errors.brand?.message}
                    </p>
                  )}
                </div>
                <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      Model <span className="text-[red]">*</span>
                    </label>
                    <input
                      className={errors.model ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                      type="text"
                      {...register("model", {required: "Model is required",})}
                    />
                  </div>
                  {errors.model && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      {errors.model?.message}
                    </p>
                  )}
                </div>
                
                <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      Cost <span className="text-[red]">*</span>
                    </label>
                    <input
                      className={errors.cost ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                      type="number"
                      {...register("cost", {
                        required: "Selling Price is required",
                      })}
                    />
                  </div>
                  {errors.cost && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      {errors.cost?.message}
                    </p>
                  )}
                </div>
                <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      Unit <span className="text-[red]">*</span>
                    </label>
                    <select
                      className={errors.unit ? 'text-[16px] border-solid border border-[red] font-semibold w-full rounded p-3 text-[#2D3846]' : 'text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]'}
                      {...register("unit", {
                        required: "Unit is required",
                      })}
                    >
                      <option value="">Select Unit</option>
                      <option value="Pcs">Pcs</option>
                      <option value="Mts">Mts</option>
                      <option value="Kgs">Kgs</option>
                    </select>
                  </div>
                  {errors.unit && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      {errors.unit?.message}
                    </p>
                  )}
                </div>
                <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      Description
                    </label>
                    <input
                      className="text-[16px] border-solid border border-[#C0C3CC] font-semibold w-full rounded p-3 text-[#2D3846]"
                      type="text"
                      {...register("description")}
                    />
                  </div>
                </div>

                <div>
                  <div>
                    <label
                      className="text-[#2B384A] text-[12px] block mb-2"
                      htmlFor=""
                    >
                      Status
                    </label>
                    <div className="flex">
                      <p className="flex mr-3">
                        <input
                          {...register("status", {
                            required: "Status is required",
                          })}
                          type="radio"
                          defaultChecked
                          value={true}
                        />
                        <span className="text-[#2B384A] text-[12px] pl-2">
                          Active
                        </span>
                      </p>
                      <p className="flex">
                        <input
                          {...register("status", {
                            required: "Status is required",
                          })}
                          type="radio"
                          value={false}
                        />
                        <span className="text-[#2B384A] text-[12px] pl-2">
                          Disable
                        </span>
                      </p>
                    </div>
                  </div>
                  {errors.status && (
                    <p className="text-xs text-[red] mt-1" role="alert">
                      {errors.status?.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            {!isView && (
              <p className="text-right mt-8 flex items-center justify-end">
              <button
                onClick={closingModel}
                className="bg-transparent h-[40px] w-[100px] border text-[#007AC8] border-[#007AC8] rounded text-[14px] font-bold mr-3"
              >
                Cancel
              </button>
              <button
                type="Submit"
                className="bg-[#007AC8] h-[40px] w-[100px] border border-[#007AC8] rounded text-white text-[14px] font-bold uppercase"
                disabled={props.isLoading}
              >
                {isLoading ? (
                                        <BtnLoader />
                                    ) : isEdit ? (
                                        "Update"
                                    ) : (
                                        "Create"
                                    )}
              </button>
            </p>
            )}
            
          </form>
        </div>
      </div>
    </div>
  );
}
