import React from 'react';
import ProfileList from './ProfileList';

export default function Profile() {
  return (
    <div className='bg-white p-8'>
        <ProfileList />
    </div>
  )
}
