import React, { Fragment, useState } from "react";
import infoIcon from "../../assets/image/blue-info.svg";
import whitePlus from "../../assets/image/white-plus.svg";
import { useQuery } from "@tanstack/react-query";
import {
    getCustomerList,
    addCustomer,
    editCustomer,
    deleteCustomer,
} from "../../queries/Customers";
import AddCustomer from "./AddCustomer";
import { Loader } from "../../components/utils/Loader";
import { useQueryClient } from "@tanstack/react-query";
import { useQueryMutation } from "../../hooks/useMutation";
import { Table } from "antd";
import { useFilteredList } from "../../hooks/useFilteredList";
import SearchList from "../../components/SearchList";
import { CustomerColumns } from "../../utils/colConfig";
import useModulePermissions from '../../hooks/useModulePermissions';

const filterByName = (list, searchValue) => {
    const trimmedSearchVal = searchValue.trim().toLowerCase();
    return list.filter((item) => {
        return item.name.toLowerCase().includes(trimmedSearchVal);
    });
};

export default function CustomerList() {
    const userData = JSON.parse(localStorage.getItem("loggedUser"));
    const permissions = useModulePermissions(userData);
    const queryClient = useQueryClient();
    const [isView, setIsView] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [customerData, setCustomerData] = useState({});
    const [showModel, setShowModel] = useState(false);
    const { isLoading, data } = useQuery({
        queryKey: ["customers"],
        queryFn: getCustomerList,
        keepPreviousData: false,
    });

    const {
        filteredList: customerList,
        searchVal,
        handleSubmit,
        handleChange,
    } = useFilteredList(data?.result, filterByName);

    const deleteCustomerList = useQueryMutation(
        deleteCustomer,
        queryClient,
        "customers"
    );
    const editCustomerList = useQueryMutation(
        editCustomer,
        queryClient,
        "customers"
    );
    const addCustomerList = useQueryMutation(
        addCustomer,
        queryClient,
        "customers"
    );

    const closeModel = () => {
        setShowModel(false);
        setIsView(false);
        setIsEdit(false);
    };

    const deleCustomer = (id) => {
        deleteCustomerList.mutate(id);
    };

    const edCustomer = (obj) => {
        editCustomerList.mutate(obj);
    };

    const adCustomer = (obj) => {
        addCustomerList.mutate(obj);
    };

    const settingIsEdit = (editVisible, customer) => {
        setShowModel(true);
        setCustomerData(customer);
        setIsEdit(editVisible);
    };

    const columns = CustomerColumns(
        deleCustomer,
        edCustomer,
        addCustomerList.isLoading,
        settingIsEdit
    );
    


    if (isLoading) return <Loader />;

    return (
        <div>
            {data.result && data.result.length > 0 ? (
                <Fragment>
                    <div className="flex justify-between mb-5">
                        <SearchList
                            searchVal={searchVal}
                            handleChange={handleChange}
                            handleSubmit={handleSubmit}
                            placeHolder={"Client Name..."}
                        />
                        {permissions.showCustomerMenu !== undefined && permissions.showCustomerMenu.write && (
                            <button
                            onClick={() => setShowModel(true)}
                            className="primary btn flex items-center justify-center text-[#fff] text-[14px] font-bold"
                        >
                            <img src={whitePlus} alt="" />
                            <span className="ml-3">Add New Client</span>
                        </button>
                        )}
                        
                    </div>
                    <Table
                        pagination={{ pageSize: 6 }}
                        className="w-full text-left cursor-pointer"
                        dataSource={customerList}
                        onRow={(record, rowIndex) => {
                            return {
                              onClick: () => {setCustomerData(record); setShowModel(true); setIsView(true)},
                            };
                        }}
                        columns={columns}
                    />
                </Fragment>
            ) : (
                <div className="text-center">
                    <img className="mx-auto" src={infoIcon} alt="Info Icon" />
                    <h4 className="text-[20px] text-[#2D3846] font-bold">
                        No data available
                    </h4>
                    <p className="text-[14px] text-[#656F7D] mb-12">
                        You have not added/created any Client here
                    </p>
                    {permissions.showCustomerMenu !== undefined && permissions.showCustomerMenu.write && (
                        <p className="flex justify-center">
                        <button
                            onClick={() => setShowModel(true)}
                            className="primary btn flex items-center justify-center text-[#fff] text-[14px] font-bold"
                        >
                            <img src={whitePlus} alt="" />
                            <span className="ml-3">Add New Client</span>
                        </button>
                    </p>
                    )}
                    
                </div>
            )}

            {showModel && permissions.showCustomerMenu !== undefined && permissions.showCustomerMenu.write && (
                <AddCustomer
                    closeModel={closeModel}
                    adCustomer={adCustomer}
                    isLoading={addCustomerList.isLoading}
                    customer={customerData}
                    isView={isView}
                    isEdit={isEdit}
                    settingIsEdit={settingIsEdit}
                    editingCustomr={edCustomer}
                />
            )}
        </div>
    );
}
