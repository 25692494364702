import React, { Fragment, useEffect, useState } from "react";
import infoIcon from "../../assets/image/blue-info.svg";
import whitePlus from "../../assets/image/white-plus.svg";
import { useQuery } from "@tanstack/react-query";
import {
    getRoleList,
    addRole,
    editRole,
    deleteRole,
} from "../../queries/Roles";
import AddRole from "./AddRole";
import { Loader } from "../../components/utils/Loader";
import { useQueryClient } from "@tanstack/react-query";
import { useQueryMutation } from "../../hooks/useMutation";
import { Tree } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Trash2, Pencil } from "lucide-react";
import useModulePermissions from "../../hooks/useModulePermissions";

export default function RoleList() {
    const userData = JSON.parse(localStorage.getItem("loggedUser"));
    const permissions = useModulePermissions(userData);
    const queryClient = useQueryClient();
    const [showModel, setShowModel] = useState(false);
    const [treeData, setTreeData] = useState([]);
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [item, setItem] = useState(null);

    const { isLoading, data:roleData } = useQuery({
        queryKey: ["roles"],
        queryFn: getRoleList,
    });


    const deleteRoleList = useQueryMutation(deleteRole, queryClient, "roles");
    const addRoleList = useQueryMutation(addRole, queryClient, "roles");
    const editRoleList = useQueryMutation(editRole, queryClient, "roles");

    const closeModel = () => {
        setShowModel(false);
    };

    const deleRole = (id) => {
        deleteRoleList.mutate(id);
    };

    const edRole = (obj) => {
        editRoleList.mutate(obj);
    };

    const adRole = (obj) => {
        addRoleList.mutate(obj);
    };

    const clickEditRole = (item) => {
        setIsEdit(true);
        setShowModel(true);
        setItem(item);
    }

    const clickAddRole = () => {
        setShowModel(true);
        setIsEdit(false);
        setItem(null);
    }

    const formatData = (data) => {
        let tree = {};
        let map = {};

        data.forEach((item) => {
            map[item._id] = item;
            item.children = [];
        });
        let topParent = null;

        data.forEach((item) => {
            if (item.parentRole == null) {
                topParent = item._id;
            }
            if (item.parentRole !== null) {
                const parent = map[item.parentRole];
                if (parent) {
                    let newItem = {
                        title: (
                            <div>
                                {item.name}
                                
                                    <Pencil
                                        size={16}
                                        style={{ display: "none" }}
                                        className="edit-icon mx-2"
                                        onClick={() => clickEditRole(item)}
                                    />
                                {(topParent !== item.parentRole && permissions.showRolesMenu.delete) && (
                                    <Trash2
                                        size={16}
                                        onClick={() => deleRole(item._id)}
                                        style={{ display: "none" }}
                                        className="delete-icon"
                                    />
                                )
                                }
                                
                            </div>
                        ),
                        key: item._id,
                        children: item.children,
                    };
                    parent.children.push(newItem);
                }
            } else {
                let newItem = {
                    title: (
                        <div>
                            {item.name}
                            {item.parentRole !== null && (permissions.showRolesMenu !== undefined && (permissions.showRolesMenu.update || permissions.showRolesMenu.update)) (
                                <>
                                {permissions.showRolesMenu.update && (
                                    <Pencil
                                    size={16}
                                    style={{ display: "none" }}
                                    className="edit-icon mx-2"
                                    onClick={() => clickEditRole(item)}
                                />
                                )}
                                {permissions.showRolesMenu.update && (
                                    <Trash2
                                    size={16}
                                    onClick={() => deleRole(item._id)}
                                    style={{ display: "none" }}
                                    className="delete-icon"
                                    />
                                )}
                                    
                                    
                                </>
                            )}
                        </div>
                    ),
                    key: item._id,
                    children: item.children,
                };
                tree = newItem;
            }
        });

        return tree;
    };

    const getAllKeys = (treeData) => {
        let allKeys = [];
        const traverse = (nodes) => {
            for (let node of nodes) {
                allKeys.push(node.key);
                if (node.children) {
                    traverse(node.children);
                }
            }
        };
        traverse(treeData);
        return allKeys;
    };

    const expandAll = () => {
        const allKeys = getAllKeys(treeData);
        setExpandedKeys(allKeys);
    };

    const collapseAll = () => {
        setExpandedKeys([]);
    };

    const onExpand = (expandedKeys) => {
        setExpandedKeys(expandedKeys);
    };

    const onSelect = (selectedKeys, info) => {
        console.log("selected", selectedKeys, info);
    };

    useEffect(() => {
        if (roleData?.result === undefined){
            return;
        }else{
            if (roleData?.result?.length > 0){
                const formatRoleData = roleData?.result?.map((r) => ({ ...r, children: [] }));
                const formattedData = formatData(
                    formatRoleData
                );
                setTreeData([formattedData]);
                console.log('roleData', formatRoleData);
            }
        }
        
    }, [roleData]);

    if (isLoading) return <Loader />;

    return (
        <div>
            {roleData?.result && roleData?.result?.length > 0 ? (
                <Fragment>
                    <div className="flex justify-between mb-5 items-center">
                        <div className="flex">
                            <button
                                className="border border-1 text-[12px] font-bold px-3 py-2 rounded-l-md text-[#007ac8]"
                                onClick={expandAll}
                            >
                                Expand All
                            </button>
                            <button
                                className="border border-1 text-[12px] font-bold px-3 py-2 rounded-r-md text-[#007ac8]"
                                onClick={collapseAll}
                            >
                                Collapse All
                            </button>
                        </div>
                        {permissions.showRolesMenu !== undefined && permissions.showRolesMenu.write && (
                            <button
                            onClick={() => clickAddRole()}
                            className="primary btn flex items-center justify-center text-[#fff] text-[14px] font-bold"
                        >
                            <img src={whitePlus} alt="" />
                            <span className="ml-3">Add New Role</span>
                        </button>
                        )}
                        
                    </div>

                    <Tree
                        showLine
                        switcherIcon={<DownOutlined />}
                        onSelect={onSelect}
                        treeData={treeData}
                        expandedKeys={expandedKeys}
                        onExpand={onExpand}
                    />
                </Fragment>
            ) : (
                <div className="text-center">
                    <img className="mx-auto" src={infoIcon} alt="Info Icon" />
                    <h4 className="text-[20px] text-[#2D3846] font-bold">
                        No data available
                    </h4>
                    <p className="text-[14px] text-[#656F7D] mb-12">
                        You have not added/created any Role here
                    </p>
                    {permissions.showRolesMenu !== undefined && permissions.showRolesMenu.write && (
                        <p className="flex justify-center">
                        <button
                            onClick={() => setShowModel(true)}
                            className="primary btn flex items-center justify-center text-[#fff] text-[14px] font-bold"
                        >
                            <img src={whitePlus} alt="" />
                            <span className="ml-3">Add New Role</span>
                        </button>
                    </p>
                    )}
                    
                </div>
            )}

            {showModel && permissions.showRolesMenu !== undefined && permissions.showRolesMenu.write && (
                <AddRole
                    closeModel={closeModel}
                    adRole={adRole}
                    isLoading={isLoading}
                    isEdit={isEdit}
                    edRole={edRole}
                    item={item}
                />
            )}
        </div>
    );
}
