import React, { useEffect } from "react";
import loginBg from "../assets/image/login-bg.jpg";
import logo from "../assets/image/logo.svg";
import { useForm } from "react-hook-form";
import { loginUser } from "../queries/Auth";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  
  const { mutate, isLoading, isError, error, isSuccess, data } = useMutation({mutationKey: ['login'], mutationFn:loginUser});
  const onSubmit = (data) => {
    let loginObj = {
      emp_code: data.userId,
      password: data.password,
      user_type: "Admin",
    };
    mutate(loginObj);
  };
  useEffect(() => {
    const entities = Cookies.get("authToken");
    if (entities) {
      navigate("/dashboard");
    }
    if (isError) {
      toast.error(error?.response?.data?.message);
    }
    if (isSuccess) {
      localStorage.setItem('loggedUser', JSON.stringify(data?.result));
      const expirationTime = 24 * 60 * 60;
      Cookies.set("authToken", data?.token, { 
        expires: expirationTime
      });
      navigate("/dashboard");
    }
  }, [error, data]);
  return (
    <div className="flex h-screen w-screen">
      <div className="w-1/2 hidden lg:block">
        <img className="object-cover w-full h-full" src={loginBg} alt="" />
      </div>
      <div className="lg:w-1/2 w-full flex justify-center items-center">
        <div className="lg:w-[500px] w-full px-10 lg:px-0">
          <p className="flex justify-center mb-6">
            <img src={logo} alt="" />
          </p>
          <div className="mb-8">
            <h1 className="text-[34px] text-[#2D3846] font-bold">
              Login Here{" "}
            </h1>
            <p className="text-[12px] text-[#2B384A]">
              Portal lorumipsum dolarsditamit lorumiolsum
            </p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="text-left mb-5">
              <label
                htmlFor="email"
                className="mb-2 block text-[14px] font-medium text-[#2B384A]"
              >
                Emp Id
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-5 pointer-events-none">
                  <svg
                    width="18"
                    height="15"
                    viewBox="0 0 18 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 14.4C6.86842 14.4 4.76316 14.4 2.63158 14.4C1.02632 14.4 0 13.3696 0 11.7982C0 8.7585 0 5.69302 0 2.62755C0 1.00465 1.02632 0 2.68421 0C6.89474 0 11.1053 0 15.3158 0C16.9737 0 18 1.00465 18 2.62755C18 5.66726 18 8.70698 18 11.7467C18 13.3696 16.9737 14.3742 15.3158 14.3742C13.2105 14.4 11.1053 14.4 9 14.4ZM1.65789 2.62755C1.65789 2.91091 1.65789 3.06547 1.65789 3.22004C1.65789 6.05367 1.65789 8.91306 1.65789 11.7467C1.65789 12.5195 1.94737 12.8286 2.73684 12.8286C6.92105 12.8286 11.1053 12.8286 15.3158 12.8286C16.0526 12.8286 16.3684 12.5195 16.3684 11.824C16.3684 8.93882 16.3684 6.07943 16.3684 3.19428C16.3684 3.03971 16.3421 2.91091 16.3421 2.70483C16.1842 2.80787 16.0526 2.88515 15.9474 2.96243C13.8947 4.53381 11.8158 6.10519 9.76316 7.67656C9.13158 8.16601 8.86842 8.16601 8.26316 7.67656C6.73684 6.49159 5.18421 5.33238 3.65789 4.17317C3 3.65796 2.36842 3.16851 1.65789 2.62755ZM3.21053 1.6229C3.18421 1.67442 3.15789 1.70018 3.10526 1.7517C3.18421 1.80322 3.26316 1.80322 3.31579 1.85474C5.13158 3.2458 6.94737 4.61109 8.76316 6.00215C9 6.18247 9.13158 6.07943 9.31579 5.95063C11.0789 4.61109 12.8421 3.2458 14.6053 1.90626C14.6842 1.82898 14.7632 1.7517 14.9211 1.6229C10.9474 1.6229 7.07895 1.6229 3.21053 1.6229Z"
                      fill="#185D9E"
                    />
                  </svg>
                </div>
                <input
                  type="text"
                  id="userId"
                  className="text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-14 p-5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Emp Id"
                  {...register("userId", {
                    required: "User Id is required",
                  })}
                />
              </div>
              {errors.userId && (
                <p className="text-xs text-[red] mt-1" role="alert">
                  {errors.userId?.message}
                </p>
              )}
            </div>
            <div className="text-left">
              <label
                htmlFor="password"
                className="mb-2 block text-[14px] font-medium text-[#2B384A]"
              >
                Password
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-5 pointer-events-none">
                  <svg
                    width="16"
                    height="18"
                    viewBox="0 0 16 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.75"
                      y="6.75"
                      width="14.5"
                      height="10.5"
                      rx="1.25"
                      stroke="#185D9E"
                      strokeWidth="1.5"
                    />
                    <path
                      d="M11 7V3C11 1.89543 10.1046 1 9 1H7C5.89543 1 5 1.89543 5 3V7"
                      stroke="#185D9E"
                      strokeWidth="1.5"
                    />
                  </svg>
                </div>
                <input
                  type="password"
                  id="password"
                  className="text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-14 p-5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter Password"
                  {...register("password", {
                    required: "Password is required",
                  })}
                />
              </div>
              {errors.password && (
                <p className="text-xs text-[red] mt-1" role="alert">
                  {errors.password?.message}
                </p>
              )}
            </div>
            <button
              disabled={isLoading}
              type="Submit"
              className="font-bold disabled:opacity-25 text-[14px] bg-[#007AC8] w-full py-4 mt-10 rounded-lg text-white"
            >
              Continue
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
