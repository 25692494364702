import React, { Fragment, useState } from "react";
import infoIcon from "../../assets/image/blue-info.svg";
import whitePlus from "../../assets/image/white-plus.svg";
import { useQuery } from "@tanstack/react-query";
import {
    getGrnList,
    addingGrn,
    editGrn,
    deleteGrn,
} from "../../queries/GRN";
import { Loader } from "../../components/utils/Loader";
import { useQueryClient } from "@tanstack/react-query";
import { useQueryMutation } from "../../hooks/useMutation";
import { Table } from "antd";
import { useFilteredList } from "../../hooks/useFilteredList";
import SearchList from "../../components/SearchList";
import useModulePermissions from "../../hooks/useModulePermissions";
import AddGrn from "./AddGrn";
import { GrnColumns } from "../../utils/colConfig";

const filterByName = (list, searchValue) => {
    const trimmedSearchVal = searchValue.trim().toLowerCase();
    return list.filter((item) => {
        return (
            item.grn_number.toLowerCase().includes(trimmedSearchVal) ||
            item.indent_number.toLowerCase().includes(trimmedSearchVal) ||
            item.project_code.toLowerCase().includes(trimmedSearchVal) ||
            item.vehicle_number.toLowerCase().includes(trimmedSearchVal)
        );
    });
};

export default function GrnList() {
    const userData = JSON.parse(localStorage.getItem("loggedUser"));
    const permissions = useModulePermissions(userData);
    const queryClient = useQueryClient();
    const [showModel, setShowModel] = useState(false);
    const { isLoading, isError, data } = useQuery({
        queryKey: ["grn"],
        queryFn: getGrnList,
        keepPreviousData: false,
    });
    const {
        filteredList: GRNList,
        searchVal,
        handleSubmit,
        handleChange,
    } = useFilteredList(data?.result, filterByName);

    const deleteGrnList = useQueryMutation(deleteGrn, queryClient, "grn");
    const addGrnList = useQueryMutation(addingGrn, queryClient, 'grn');
    const editGrnList = useQueryMutation(editGrn, queryClient, 'grn');

    

    const closeModel = () => {
        setShowModel(false);
    };

    const deleGrn = (id) => {
        deleteGrnList.mutate(id);
    };

    const edGrn = (obj) => {
        editGrnList.mutate(obj);
    };

    const adGrn = (obj) => {
        addGrnList.mutate(obj);
    };

    const columns = GrnColumns(deleGrn, edGrn, isLoading);

    if (isLoading) return <Loader />;

    if (isError)
        return (
            <p className="text-center my-20 font-bold">Something Went Wrong!</p>
        );

    return (
        <div>
            {data.result && data.result.length > 0 ? (
                <Fragment>
                    <div className="flex justify-between mb-5">
                        <SearchList
                            searchVal={searchVal}
                            handleChange={handleChange}
                            handleSubmit={handleSubmit}
                            placeHolder={"PO No. or Indent No...."}
                        />
                        {permissions.showGrnMenu !== undefined && permissions.showGrnMenu.write && (
                            <button
                            onClick={() => setShowModel(true)}
                            className="primary btn flex items-center justify-center text-[#fff] text-[14px] font-bold mr-3"
                        >
                            <img src={whitePlus} alt="" />
                            <span className="ml-3">Add New GRN</span>
                        </button>
                        )}
                        
                    </div>

                    <Table
                        pagination={{ pageSize: 6 }}
                        className="w-full text-left"
                        dataSource={GRNList}
                        columns={columns}
                    />
                </Fragment>
            ) : (
                <div className="text-center">
                    <img className="mx-auto" src={infoIcon} alt="Info Icon" />
                    <h4 className="text-[20px] text-[#2D3846] font-bold">
                        No data available
                    </h4>
                    <p className="text-[14px] text-[#656F7D] mb-12">
                        You have not added/created any GRN here
                    </p>
                    {permissions.showGrnMenu !== undefined && permissions.showGrnMenu.write && (
                        <p className="flex justify-center">
                        <button
                            onClick={() => setShowModel(true)}
                            className="primary btn flex items-center justify-center text-[#fff] text-[14px] font-bold"
                        >
                            <img src={whitePlus} alt="" />
                            <span className="ml-3">Add New GRN</span>
                        </button>
                    </p>
                    )}
                    
                </div>
            )}

            {showModel && permissions.showPurchaseMenu !== undefined && permissions.showPurchaseMenu.write && (
                <AddGrn
                    closeModel={closeModel}
                    adGrn={adGrn}
                    isLoading={isLoading}
                />
            )}
            {/* {showImportModel && permissions.showPurchaseMenu !== undefined && permissions.showPurchaseMenu.write && (
                <ImportPurchase
                    closeImportPurchaseModel={closeImportPurchaseModel}
                    isLoading={isLoading}
                />
            )} */}
        </div>
    );
}
