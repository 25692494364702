import React, { Fragment, useState } from "react";
import infoIcon from "../../assets/image/blue-info.svg";
import whitePlus from "../../assets/image/white-plus.svg";
import { useQuery } from "@tanstack/react-query";
import { getOrganizationList, editOrganization } from "../../queries/Organization";
import { Loader } from "../../components/utils/Loader";
import { useQueryClient } from "@tanstack/react-query";
import { Table } from "antd";
import {useQueryMutation} from '../../hooks/useMutation';
import { OrganizationColumns } from "../../utils/colConfig";


export default function OrganizationList() {
    const queryClient = useQueryClient();
    const [showModel, setShowModel] = useState(false);

    const { isLoading, isError, data } = useQuery({
        queryKey: ["organization"],
        queryFn: getOrganizationList,
    });


    const user = JSON.parse(localStorage.getItem("loggedUser"));

    const editOrganizationList = useQueryMutation(editOrganization, queryClient, 'organization');

    const closeModel = () => {
        setShowModel(false);
    };

    const edOrganization = (obj) => {
        editOrganizationList.mutate(obj);
    };

    const columns = OrganizationColumns(edOrganization, isLoading);

    if (isLoading) return <Loader />;

    if (isError)
        return (
            <p className="text-center my-20 font-bold">Something Went Wrong!</p>
        );

    return (
        <div>
            {data?.result?.length > 0 ? (
                <Fragment>
                    <Table
                        pagination={{ pageSize: 6 }}
                        className="w-full text-left"
                        dataSource={data?.result}
                        columns={columns}
                    />
                </Fragment>
            ) : (
                <div className="text-center">
                    <img className="mx-auto" src={infoIcon} alt="Info Icon" />
                    <h4 className="text-[20px] text-[#2D3846] font-bold">
                        No data available
                    </h4>
                    <p className="text-[14px] text-[#656F7D] mb-12">
                        You have not added/created any Organization here
                    </p>
                </div>
            )}

        </div>
    );
}
