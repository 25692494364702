import React from 'react';
import UserList from './UserList';

export default function Users() {
  return (
    <div className='bg-white p-8'>
        <UserList />
        {/* sdasdsad */}
    </div>
  )
}
