import React, { useState } from "react";
import closeIcon from "../../assets/image/blue-cross.svg";
import { useForm, Controller } from "react-hook-form";
import { BtnLoader } from "../../components/utils/Loader";
import deleteIcon from '../../assets/image/delete-white.svg';
import attachIcon from '../../assets/image/attachment-white.svg';
import MaterialSample from '../../assets/docs/materials.csv';

export default function AssignMaterialToProject(props) {
    
    
    const [uploadedFile, setUploadedFile] = useState('');
    
    const closingModel = () => {
        props.closeAssignModel();
    };
    
    const {
        handleSubmit,
        control,
    } = useForm();

    const onSubmit = (data) => {
        console.log(data);
        const formData = new FormData();
        formData.append("file", data.material);
        data = { ...data, material: data.material.name };
        console.log(data);
        formData.append("recipe", JSON.stringify(data));
        props.assignMaterial(formData);
        setUploadedFile('');
    };

    const deleteUpload = () => {
        setUploadedFile('');
    }

    const downloadMaterialSample = () => {
        window.open(MaterialSample, '_blank');
    }


    return (
        <div className="bg-[#EBEDF2] p-12 fixed top-0 left-0 w-full h-full z-10">
            <p className="mb-8 text-right">
                <button onClick={closingModel}>
                    <img src={closeIcon} alt="" />
                </button>
            </p>

            <div className="flex">
                <div className="w-[40%] text-left">
                    <h3 className="text-[24px] text-[#2D3846]">
                    Add Material to project
                    </h3>
                    <p className="text-[14px] text-[#656F7D]">
                    Add or Import materials
                    </p>
                </div>
                <div className="w-[60%]">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="bg-white p-5 rounded-md max-h-[70vh] overflow-y-auto">
                            <div className="text-left border-b-2 border-b-[#007AC8] mb-8 flex justify-between">
                                <h4 className="text-[20px]">Assign Material</h4>
                                <p onClick={() => downloadMaterialSample()} className="text-[#007ac8] font-bold cursor-pointer">Sample File download</p>
                            </div>
                           

                            <div className="bg-[#EBEDF2] p-12 mx-auto w-96">
                                <h4 className="text-[#2D3846] text-[20px] font-bold">Import Materials</h4>
                                <p className="text-[#656F7D] text-[12px] mb-8">Add material from material master</p>
                            <div class="flex flex-col items-center justify-center w-full">
    <label for="material" class="flex flex-col items-center justify-center w-full h-40 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
        
        <Controller
            control={control}
            name={"material"}
            rules={{ required: "CSV file upload is required" }}
            render={({ field: { value, onChange, ...field } }) => {
              return (
                <div>
                <div class="flex flex-col items-center justify-center pt-5 pb-6">
            <svg class="w-8 h-8 mb-4 text-[#007AC8] dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
            </svg>
            <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span></p>
            <p class="text-xs text-gray-500 dark:text-gray-400">Allow only CSV file</p>
        </div>
                <input
                  {...field}
                  value={value?.fileName}
                  onChange={(event) => {
                    onChange(event.target.files[0]);
                    setUploadedFile(event.target.files[0].name);
                  }}
                  onClick={(event)=> { 
                    event.target.value = null
               }}
                  type="file"
                  id="material"
                  className="hidden"
                />
                </div>
              );
            }}
          />
        {/* <input id="dropzone-file" type="file" class="hidden" /> */}
    </label>
    {uploadedFile !== '' && (
    <div className="mt-3 items-center bg-[#374151] w-full rounded-md px-4 py-2 flex justify-between">
        <p className="flex items-center text-[13px] text-gray-300">
            <span className="pr-2"><img src={attachIcon} alt="" /></span>
            {uploadedFile}
        </p>
        <span className="cursor-pointer" onClick={deleteUpload}><img src={deleteIcon} alt="" /></span>
    </div>
    )}
</div>
                            </div>
                        </div>
                        <p className="text-right mt-8 flex items-center justify-end">
                            <button
                                onClick={closingModel}
                                className="bg-transparent h-[40px] w-[100px] border text-[#007AC8] border-[#007AC8] rounded text-[14px] font-bold mr-3"
                            >
                                Cancel
                            </button>
                            <button
                                type="Submit"
                                className="bg-[#007AC8] h-[40px] w-[100px] border border-[#007AC8] disabled:opacity-30 rounded text-white text-[14px] font-bold uppercase"
                                disabled={uploadedFile === '' || props.isLoading}
                            >
                                {props.isLoading ? <BtnLoader /> : "Assign"}
                            </button>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    );
}
