import React from 'react';
import GrnList from './GrnList';

export default function Grn() {
  return (
    <div className='bg-white p-8'>
        <GrnList />
    </div>
  )
}
