import React from 'react';
import ScheduleList from './ScheduleList';

export default function Schedules() {
  return (
    <div className='bg-white p-8'>
        <ScheduleList />
    </div>
  )
}
