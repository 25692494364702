import React, { Fragment, useState, useEffect } from 'react';
import infoIcon from '../../assets/image/blue-info.svg';
import whitePlus from '../../assets/image/white-plus.svg';
import { useQuery } from "@tanstack/react-query";
import { getScheduleList, addSchedule, editSchedule, deleteSchedule, importSchedules, addStatus } from '../../queries/Schedules';
import AddSchedule from './AddSchedule';
import {Loader} from '../../components/utils/Loader';
import { getCategoryList } from '../../queries/Categories';
import { getProjectList } from '../../queries/Projects';
import { useMutation, useQueryClient } from "@tanstack/react-query";
import ImportScheduleComp from './ImportScheduleComp';
import useModulePermissions from "../../hooks/useModulePermissions";
import { ScheduleColumns } from "../../utils/colConfig";
import { useQueryMutation } from "../../hooks/useMutation";
import { Table } from 'antd';

export default function ScheduleList() {
  const userData = JSON.parse(localStorage.getItem("loggedUser"));
    const permissions = useModulePermissions(userData);
  const queryClient = useQueryClient();
    const [showModel, setShowModel] = useState(false);
    const [isView, setIsView] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [scheduleData, setScheduleData] = useState({});
    const [showImportModel, setImportModel] = useState(false);
  const { isLoading, isError, error, data } = useQuery({
    queryKey: ['schedules'], 
    queryFn: getScheduleList
  });
  const { data: catData } = useQuery({
    queryKey: ["categories"],
    queryFn: getCategoryList,
  });

  const { data:ProjData } = useQuery({
    queryKey: ["project"],
    queryFn: getProjectList,
  });

  const { mutate:importMutate } = useMutation({
    mutationKey: ['import-schedule'], 
    mutationFn: importSchedules
  });

  const { mutate:changeMutate } = useMutation({
    mutationKey: ['change-schedule'], 
    mutationFn: addStatus,
    onSuccess: () => queryClient.invalidateQueries("schedules")
  });

  const deleteScheduleList = useQueryMutation(deleteSchedule, queryClient, "schedules");
  const addScheduleList = useQueryMutation(addSchedule, queryClient, "schedules");
  const editScheduleList = useQueryMutation(editSchedule, queryClient, "schedules");

  const closeImportScheduleModel = () => {
    setImportModel(false);
  }

  const importSchedule = (obj) => {
    importMutate(obj);
  }

  const changeSchedule = (obj) => {
    changeMutate(obj)
  }

  const closeModel = () => {
    setShowModel(false);
    setIsView(false);
    setIsEdit(false);
  }

  const deleSchedule = (id) => {
    deleteScheduleList.mutate(id)
  }

  const edSchedule = (obj) => {
    editScheduleList.mutate(obj)
  }

  const adSchedule = (obj) => {
    addScheduleList.mutate(obj)
  }

  const settingIsEdit = (editVisible, schedule) => {
    setShowModel(true);
    setScheduleData(schedule);
    setIsEdit(editVisible);
  };

  


  const columns = ScheduleColumns(deleSchedule, edSchedule, isLoading, catData, ProjData, changeSchedule, settingIsEdit);

  if (isLoading) return <Loader />


  return (
    <div>
      {data.result && data.result.length > 0 ? (
        <Fragment>
          {permissions.showScheduleMenu !== undefined && permissions.showScheduleMenu.write && (
            <div className='flex justify-end mb-5'>
            <button onClick={() => setShowModel(true)} className='primary btn flex items-center justify-center text-[#fff] text-[14px] font-bold mr-3'>
            <img src={whitePlus} alt="" />
            <span className='ml-3'>Add New Schedule</span>
          </button>
          <button onClick={() => setImportModel(true)} className='primary btn flex items-center justify-center text-[#fff] text-[14px] font-bold'>
            <img src={whitePlus} alt="" />
            <span className='ml-3'>Import Schedules</span>
          </button>
            </div>
          )}
          <Table
                pagination={{ pageSize: 6 }}
                className="w-full text-left"
                dataSource={data?.result}
                columns={columns}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: () => {setScheduleData(record); setShowModel(true); setIsView(true)},
                  };
              }}
            />
        </Fragment>
      ) : (
        <div className='text-center'>
        <img className='mx-auto' src={infoIcon} alt="Info Icon" />
        <h4 className='text-[20px] text-[#2D3846] font-bold'>No data available</h4>
        <p className='text-[14px] text-[#656F7D] mb-12'>You have not added/created any Schedule here</p>
        {permissions.showScheduleMenu !== undefined && permissions.showScheduleMenu.write && (
          <p className='flex justify-center'>
          <button onClick={() => setShowModel(true)} className='primary btn flex items-center justify-center text-[#fff] text-[14px] font-bold'>
            <img src={whitePlus} alt="" />
            <span className='ml-3'>Add New Schedule</span>
          </button>
          </p>
        )}
        
    </div>
      )}
        
        {showModel && permissions.showScheduleMenu !== undefined && permissions.showScheduleMenu.write && (
          <AddSchedule 
            closeModel={closeModel} 
            adSchedule={adSchedule} 
            isLoading={addScheduleList.isLoading}
            schedule={scheduleData}
            isView={isView}
            isEdit={isEdit}
            settingIsEdit={settingIsEdit}
            edSchedule={edSchedule}
          />)}
        {showImportModel && permissions.showScheduleMenu !== undefined && permissions.showScheduleMenu.write && (<ImportScheduleComp closeImportScheduleModel={closeImportScheduleModel} importSchedule={importSchedule} />)}
        
    </div>
  )
}
