import {
  endPoints,
  getAuthorizationHeader,
  axiosInstance,
} from "../components/api";

export const getScheduleList = async () => {
  const { data: response } = await axiosInstance.get(`${endPoints.schedules}`, {
    headers: { Authorization: getAuthorizationHeader() },
  });
  return response;
};

export const addSchedule = async (reqBody) => {
  const { data: response } = await axiosInstance.post(
    `${endPoints.schedules}`,
    reqBody,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};

export const editSchedule = async (schObj) => {
  const { data: response } = await axiosInstance.put(
    `${endPoints.schedules}/${schObj.id}`,
    schObj.body,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};

export const deleteSchedule = async (schId) => {
  const { data: response } = await axiosInstance.delete(
    `${endPoints.schedules}/${schId}`,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};

export const importSchedules = async (reqBody) => {
  const { data: response } = await axiosInstance.post(
    `${endPoints.schedules}/sub/upload/`,
    reqBody,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};

export const addStatus = async (reqBody) => {
  const { data: response } = await axiosInstance.post(
    `${endPoints.schedules}/sub/addstatus`,
    reqBody,
    {
      headers: { Authorization: getAuthorizationHeader() },
    }
  );
  return response;
};
