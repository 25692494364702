import React, {useState} from 'react';
import Organization from '../container/organization/Organization';
import useModulePermissions from '../hooks/useModulePermissions';
import TabComponent from '../components/TabComponent';

const OrganizationMain = () => {
  const userData = JSON.parse(localStorage.getItem("loggedUser"));
  const permissions = useModulePermissions(userData);
  const [activeTab, setActiveTab] = useState(permissions.showOrganizationMenu !== undefined ? '1' : '');
  const tabData = [
    { permission: 'showOrganizationMenu', id: '1', tabTitle: 'Organization', title: 'Organization' },
  ];
  
  return (
    <>
    <h3 className="font-bold text-[24px] text-[#2D3846] line-clamp-[20px] mb-5">Organization Overview</h3>
    <TabComponent
      tabData={tabData}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      permissions={permissions}
    />
    {activeTab === '1' && permissions.showOrganizationMenu !== undefined && <Organization />}
    </>
    
  )
}

export default OrganizationMain;